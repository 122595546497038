/*============================
[Table of CSS]

1. General
2. Bootstrap Classes
3. Helper Classes
4. Header
5. Mobile Menu
6. Page Header
7. Home
8. Footer



/*-----------------
1. General
-----------------------*/

html {
  height: 100%;
}

.search-sec .form-control::-webkit-input-placeholder {
  color: #fff !important;
}

.search-sec .form-control:-ms-input-placeholder {
  color: #fff !important;
}

.search-sec .form-control::placeholder {
  color: #fff !important;
}

p > span {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
}

body {
  background-color: #ffffff;
  color: #555;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  height: 100%;
  line-height: 1.5;
  overflow-x: hidden;
}

.discussion-form {
  box-shadow: 0 0 6px #ccc;
  padding: 12px;
  margin-bottom: 16px;
}

.discussion_btn {
  min-height: 0 !important;
  padding: 10px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  line-height: 1.2;
  margin: 0;
  color: #333333;
}

.pointer {
  cursor: pointer;
}

a,
a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
  color: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

input,
button,
a {
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

input,
input:focus,
button,
button:focus {
  outline: none;
  border-color: inherit;
}

p {
  margin-bottom: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  cursor: pointer;
}

/*-----------------
    2. Bootstrap Classes
    -----------------------*/

.sitename {
  padding-top: 25px;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
  color: #de1818;
}

.sitename {
  animation: blinkMe 2s linear infinite;
}

@keyframes blinkMe {
  0% {
    opacity: 0.9;
    color: #de1818;
  }

  33% {
    opacity: 1;
    color: #182cde;
    text-decoration: underline;
  }

  60% {
    opacity: 1;
    color: #deba18;
    text-decoration: underline;
  }

  100% {
    opacity: 0.9;
    color: #de1818;
  }
}

.form-control {
  border: 1px solid #ddd;
  box-shadow: none;
  color: #000000;
  height: 40px;
}

.form-control:focus {
  border-color: #e5e1e6;
  box-shadow: none;
  outline: 0 none;
}

.form-control.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}

.form-control.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.btn {
  border-radius: 0;
}

.btn-primary {
  background-color: #de185a;
  border-color: #de185a;
}

.border-primary {
  border: 1px solid #de185a !important;
  color: #de185a;
}

.border-primary:hover {
  background-color: #de185a;
  color: #fff;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #c00f4a;
  border-color: #de185a;
  outline: none;
  box-shadow: none;
}

.border-secondary {
  border: 1px solid #353862 !important;
  color: #353862;
}

.border-secondary:hover {
  background-color: #353962;
  color: #fff;
}

.border-voi {
  border: 1px solid #8800d6 !important;
  color: #8800d6;
}

.border-voi:hover {
  background-color: #8800d6;
  color: #fff;
}

.btn-secondary {
  background-color: #353862;
  border-color: #353862;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #353862;
  border-color: #353862;
  outline: none;
  box-shadow: none;
}

.bg-primary {
  background-color: #de185a !important;
}

.bg-secondary {
  background-color: #373a68 !important;
}

.btn-primary:focus,
.btn-primary:focus {
  outline: none;
  box-shadow: none;
}

.primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:not(:disabled):not(.disabled):active:focus {
  background-color: #de185a;
  border-color: #de185a;
  box-shadow: none;
}

.btn-primary-border {
  border: 1px solid #de185a;
  color: #de185a;
}

.btn-primary-border:hover {
  color: #fff;
  background-color: #de185a;
}

.text-primary {
  color: #de185a !important;
}

.btn-save-border {
  border: 1px solid #7012ec;
  color: #7012ec;
}

.btn-save-border:hover {
  background-color: #7012ec;
  color: #fff;
}

.text-secondary {
  color: #353862 !important;
}

/*-----------------
    3. Helper Classes
    -----------------------*/

.content {
  padding: 50px 0;
}

.all-center {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.headingmains h2 {
  font-size: 2rem;
}

.headingmains p {
  font-size: 1.2rem;
  width: 80%;
  margin: 0 auto;
}

.titlewithline {
  margin-bottom: 0.5rem;
  position: relative;
  margin-top: 20px;
}

.titlewithline span {
  color: #de185a;
  display: block;
  padding: 7px;
}

.titlewithline span svg {
  width: 30px;
  padding-top: 7px;
}

.titlewithline:before,
.titlewithline:after {
  position: absolute;
  content: "";
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100px;
  height: 2px;
  background-color: #fbb415;
  bottom: 18px;
}

.titlewithline:before {
  right: 180px;
}

.titlewithline:after {
  left: 180px;
}

.form-group {
  margin-bottom: 1.25rem;
}

.no-border {
  border: none !important;
}

.gray-section {
  background-color: #f9f9f9;
}

.section {
  padding: 25px 0;
}

.sub-section {
  padding: 40px 0;
}

.vio-bg {
  color: #fff;
  background-color: #5d2b7d;
}

.light-vio-bg {
  color: #fff;
  background-color: #a72d89;
}

.blue-bg {
  color: #fff;
  background-color: #1474bb;
}

.light-gree-bg {
  color: #fff;
  background-color: #8fc33e;
}

.org-bg {
  color: #fff;
  background-color: #ff9000;
}

.red-bg {
  color: #fff;
  background-color: #ef4c04;
}

.ds-bg {
  color: #fff;
  background-color: #007684;
}

.dgree-bg {
  color: #fff;
  background-color: #c23802;
}

.dblue-bg {
  color: #fff;
  background-color: #4e3ec3;
}

.pink-bg {
  color: #fff;
  background-color: #ed7f13;
}

/*-----------------
    4. Header
    -----------------------*/

.topbar-section {
  position: fixed;
  background: #01132f;
  padding: 7px 0 0px 0px;
  width: 100%;
  z-index: 11;
  top: 0;
}

.topbar-section::after {
  background-color: #fbb415;
  position: absolute;
  top: 0;
  bottom: -1px;
  right: -63px;
  width: 45%;
  content: "";
  transform: skew(-37deg);
  border-left: 10px solid #39475d;
  z-index: 0;
}

.topbar-section .topbar-inner {
  display: inline-block;
  width: 100%;
  padding: 0;
  vertical-align: middle;
}

.topbar-inner ul li {
  font-size: 14px;
  font-family: "Roboto";
  color: #fff;
  padding: 6px 25px 6px 0px;
  margin-right: 20px;
  margin-bottom: 0;
}

.top-bar-right ul li:last-child {
  padding-right: 0px;
  margin-right: 0px;
}
.view-more-cust {
  width: 100%;
}
.view-more-cust a {
  color: #fff !important;
}
.top-bar-left ul li,
.top-bar-right ul li {
  display: inline-block;
}

.top-bar-right ul li a {
  color: #01132f;
}

.topbar-inner .top-bar-left ul li i {
  font-size: 20px;
  background: #213451;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  padding: 5px;
  text-align: center;
  margin-right: 8px;
}

.top-bar-right {
  position: relative;
  z-index: 1;
}

.top-bar-right ul li {
  color: #01132f;
}

.getstart .dropdown .dropdown-toggle {
  transition: all 0.3s ease 0s;
  background: rgb(14, 40, 70, 1);
  background: -moz-linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#0e2846", endColorstr="#127b7e", GradientType=1);
  border: 0px;
  border-radius: 50px;
  font-size: 14px;
  padding: 8px 30px;
}

.getstart .dropdown .dropdown-toggle:focus {
  box-shadow: none;
}

.getstart .dropdown .dropdown-toggle:after {
  display: none;
}

.getstart .dropdown .dropdown-menu {
  margin: 0;
  padding: 0;
}

.getstart .dropdown .dropdown-menu li {
  margin: 0;
  padding: 0;
  width: 100%;
}

.getstart .dropdown .dropdown-menu li a {
  padding: 10px 10px;
  display: block;
}

.dropdown.show li :hover {
  background-color: #047475;
  color: #fff;
}

.header_lending {
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;
  background: rgb(18, 123, 126);
  background: -moz-linear-gradient(
    90deg,
    rgba(18, 123, 126, 1) 0%,
    rgba(14, 40, 70, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(18, 123, 126, 1) 0%,
    rgba(14, 40, 70, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(18, 123, 126, 1) 0%,
    rgba(14, 40, 70, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#127b7e", endColorstr="#0e2846", GradientType=1);
  transition: all 0.5s ease-in-out;
  z-index: 10;
  position: fixed;
}

.header {
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;
  background: rgb(18, 123, 126);
  background: -moz-linear-gradient(
    90deg,
    rgba(18, 123, 126, 1) 0%,
    rgba(14, 40, 70, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(18, 123, 126, 1) 0%,
    rgba(14, 40, 70, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(18, 123, 126, 1) 0%,
    rgba(14, 40, 70, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#127b7e", endColorstr="#0e2846", GradientType=1);
  transition: all 0.5s ease-in-out;
  z-index: 10;
  position: fixed;
  margin-top: 56px;
}

.header_lending.fixed {
  background-color: #fff;
  position: fixed;
  padding: 10px 0;
  transition: all 0.5s ease-in-out;
}

.header.fixed {
  background-color: #fff;
  position: fixed;
  padding: 10px 0;
  transition: all 0.5s ease-in-out;
}

.main-logo img {
  width: 105%;
}

.menu-toggle {
  position: absolute;
  width: 30px;
  height: 20px;
  padding: 0;
  border: none;
  background-color: transparent;
  right: 20px;
  top: -35px;
}

.menu-toggle span {
  display: block;
  width: 90%;
  height: 2px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background-color: #333;
}

.menu-toggle span::before,
.menu-toggle span::after {
  position: absolute;
  left: 0;
  height: 2px;
  content: "";
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background-color: #333;
}

.menu-toggle span::before {
  top: 0;
  width: 100%;
}

.menu-toggle span::after {
  bottom: 1px;
  width: 80%;
}

.main-menu {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  overflow-x: hidden;
}

.main-menu::before {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent, white, white, white);
  opacity: 0.12;
  content: "";
  left: 0;
}

.header_contact::before {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent !important;
  content: "";
  left: 0;
}

.landing_banner {
  margin-top: 100px;
}

.show_mobile {
  display: none !important;
}

.main-menu > ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  color: #ffffff;
  margin-right: -18px;
}

.main-menu > ul > li {
  margin: 0 2px;
  align-items: center;
  display: flex;
  font-weight: 500;
  /* transform: skewX(-20deg); */
  /* background-color: #101010; */
}

.main-menu > ul > li:first-child {
  padding-left: 15px;
}

.main-menu > ul > li > a {
  position: relative;
  display: block;
  padding: 18px 30px;
  color: #fff;
  font-size: 15px;
  transform: skewX(-20deg);
  transition: all 0.3s ease-in-out;
}

.main-menu > ul > li > a span {
  transform: skewX(20deg);
  display: inline-block;
}

.main-menu > ul > li:last-child > a {
  /* background-color: #101010; */
}

.playslider {
  position: absolute;
  z-index: 7;
  margin: 0 auto;
  margin-top: 0px;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 250px;
  margin-right: 50%;
  margin-top: 7%;
}

.playslider:hover {
  opacity: 0.8;
}

.main-menu > ul > li > a::before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  content: "";
  transition: all 0.3s ease-in-out;
}

.main-menu > ul > li > a i {
  font-size: 16px;
}

.main-menu > ul > li:last-child > a::after {
  position: absolute;
  right: -19px;
  bottom: 0;
  width: 35px;
  height: 100%;
  content: "";
  transition: all 0.3s ease-in-out;
  background: transparent;
  transform: skewX(20deg);
}

.menu-link a:hover,
.menu-link a.active {
  text-decoration: underline;
  color: #de185a;
}

.log-menu {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 35px;
  color: #ffffff;
}

.log-menu a {
  font-weight: 600;
  min-width: 120px;
  text-align: center;
  padding: 4px !important;
  border-radius: 30px;
  color: #fff;
  background-color: #de185a;
}

.header.fixed ul.sub-menu > li a.active {
  color: #de185a;
}

.header.fixed .log-menu a {
  background-color: #de185a;
  color: #fff;
}

.main-menu > ul > li.log-menu > a::before {
  display: none;
}

.login-item,
.login-item:hover {
  color: #fff;
}

.main-menu > ul > li.active > a,
.main-menu > ul > li > a:hover {
  color: #111 !important;
  background-color: #fcbd30;
  transition: all 0.3s ease-in-out;
}

.main-menu > ul > li.active > a,
.main-menu > ul > li:hover > a {
  color: #111;
  transition: all 0.3s ease-in-out;
}

.main-menu > ul > li.active > a::before,
.main-menu > ul > li:hover > a::before {
  right: auto;
  left: 0;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.main-menu > ul > li:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
}

.sub-menu {
  position: absolute;
  top: 100%;
  left: -20px;
  visibility: hidden;
  width: 200px;
  padding: 20px;
  list-style: none;
  transition: all 0.3s ease 0s;
  opacity: 0;
  color: #454545;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  font-size: 14px;
}

.sub-menu li {
  padding: 5px 0;
}

.sub-menu li a {
  display: block;
  color: #888;
}

.sub-menu li a:hover {
  color: #de185a;
}

/*  ########################### go to top button ########################### */

.chatbot {
  position: fixed;
  bottom: 0px;
  right: 10px;
  z-index: 98;
  padding-top: 10px;
}

.pwhatsapp {
  width: 55px;
  height: 55px;
  position: fixed;
  bottom: 235px;
  right: 10px;
  display: none;
  z-index: 98;
  background-color: #25d366;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  border-radius: 10px;
  text-align: center;
  padding-top: 0px;
  font-size: 40px;
  color: #fff;
  line-height: 52px;
}

.cd-top {
  width: 55px;
  height: 55px;
  position: fixed;
  bottom: 145px;
  right: 10px;
  display: none;
  z-index: 98;
  background-color: #aaa;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  border-radius: 10px;
  text-align: center;
  padding-top: 10px;
  font-size: 22px;
  color: #222;
}

.cd-top:hover {
  background-color: #999;
}

/*-----------------
    5. Mobile Menu
    -----------------------*/

.resmenu-section {
  position: fixed;
  z-index: 99;
  top: 0;
  right: -100%;
  left: auto;
  visibility: hidden;
  width: 390px;
  height: 100%;
  padding-left: 60px;
  transition: all 0.3s ease 0s;
  opacity: 0;
}

.resmenu-section.open {
  right: 0;
  visibility: visible;
  opacity: 1;
}

.resmenu-section.open .resmenu-close {
  visibility: visible;
  opacity: 1;
}

body.resmenu-overlay {
  overflow-y: hidden;
}

body.resmenu-overlay:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
}

.resmenu-wrap {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  background-color: #ffffff;
  box-shadow: -5px 0 5px 0 rgba(0, 0, 0, 0.1);
}

.resmenu-wrap .inner {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-height: 100%;
  padding: 40px 30px;
}

.resmenu-close {
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  position: absolute;
  z-index: 99;
  top: 11px;
  left: 11px;
  visibility: hidden;
  width: 50px;
  height: 50px;
  transition: all 0.3s ease 0s;
  opacity: 0;
  color: #1a77a4;
  border: none;
  background-color: #ffffff;
  box-shadow: -5px 0 5px 0 rgba(0, 0, 0, 0.1);
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.res-menu nav ul {
  font-size: 14px;
  position: static;
  top: 0;
  left: 0;
  visibility: visible;
  width: 100%;
  transition: none;
  opacity: 1;
  background-color: transparent;
  box-shadow: none;
}

.res-menu nav ul li {
  position: relative;
}

.res-menu nav ul li a {
  font-weight: 500;
  line-height: 1;
  display: block;
  padding: 15px 0;
  text-transform: uppercase;
  border-bottom: 1px solid #eeeeee;
}

.res-menu nav ul li:last-child > a {
  border-bottom: none;
}

.res-menu nav ul li .menu-expand {
  line-height: 44px;
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  width: 30px;
  height: 44px;
  cursor: pointer;
  text-align: center;
}

.res-menu nav ul li .menu-expand i {
  position: relative;
  display: block;
  width: 10px;
  margin-top: 22px;
  margin-right: auto;
  margin-left: auto;
  -webkit-transition: all 250ms ease-out;
  -o-transition: all 250ms ease-out;
  transition: all 250ms ease-out;
}

.res-submenu.active a {
  color: #de185a;
}

.res-menu .sub-menu {
  display: none;
  padding: 0;
}

.res-menu .sub-menu li {
  padding: 0;
}

.res-menu .sub-menu li a {
  font-size: 13px;
  text-transform: inherit;
  color: #000;
  padding: 15px;
}

.resmenu-user {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.resmenu-user a {
  -webkit-box-flex: 1;
  flex-grow: 1;
  padding: 7px;
  text-align: center;
}

/*-----------------
    6. Page Header
    -----------------------*/

.headslider {
  position: relative;
}

.slidercont .owl-item {
  background-color: #e7dfdd;
}

.headerProfile {
  display: flex;
  align-items: center;
}

.headerProfile img {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  margin-right: 6px;
}

.headerProfile p {
  text-transform: capitalize;
}

.page-heading-section6 {
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 50px;
}

.bg-parallax {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50% 0;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.bg-parallax::before {
  opacity: 0.8;
}

.bg-parallax::before {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-image: linear-gradient(to right, #000000, #000000);
}

.email_text {
  padding-right: 0 !important;
  font-size: 0.8rem;
}

.profile_down_arrow {
  margin-top: 10px;
  position: absolute;
}

.expired-text {
  text-align: center;
  margin-left: 5rem;
}

.page-heading-content .title {
  color: #fff;
  font-size: 42px;
  font-weight: 400;
}

.modal-backdrop.fade.show {
  display: none;
}

.red_cross {
  width: 15%;
}

.comp_url:hover {
  color: #03b4fc !important;
}

.page-heading-content p {
  color: #fff;
  font-size: 28px;
}

.page-heading-content .title span {
  font-weight: 500;
}

.page-heading-content .title span,
.page-heading-content p span {
  color: #fcbd30;
}

.page-heading-sectiona {
  background-color: #de185a;
  padding: 30px 0;
}

.search-sec {
  background-color: #cf9512;
  padding: 0;
  border-radius: 50px;
  border: 0px;
  width: 100%;
  display: inline-flex;
  color: #fff !important;
  width: 280px;
  padding-left: 30px;
  position: relative;
}

.search-sec::before {
  position: absolute;
  content: "";
  background: url(../img/Iconfeather-search.png) no-repeat 50% 50%;
  width: 22px;
  height: 22px;
  left: 15px;
  top: 23%;
  background-size: 15px;
}

.search-sec .form-control {
  width: 100%;
  height: 100%;
  border: none;
  padding: 9px 20px;
  /* text-transform: capitalize; */
  background-color: transparent;
  color: #fff !important;
  font-size: 13px;
}

.search-sec .form-control::placeholder {
  color: #44444a;
}

.search-sec select.form-control {
  border-radius: 40px;
}

.search-sec select.form-control,
select.form-control {
  line-height: 24px;
  display: block;
  width: 100%;
  padding: 8px 15px;
  padding-right: 40px;
  background-color: #ffffff;
  background: url(../img/icons/arrow-down.png) no-repeat right 10px top 50%;
  background-size: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

.hr {
  border: 1px solid #eaeaea;
  margin: 10px;
}

.search-sec button {
  padding: 10px;
  border-radius: 0;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  min-height: 70px;
  border: 6px solid #fff;
}

.page-heading-content .small-text {
  margin-top: 20px;
  font-size: 18px;
}

/*IntelliSearch*/

.chosen-select {
  position: relative;
  line-height: 26px;
  height: 27px;
  width: inherit;
  font-size: 11px;
  padding: 0px 0px 0px 8px;
  border: 2px solid #e8e8e8;
  border-radius: 7px;
}

.search-slider {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

.intelliSearch {
  position: relative;
  margin-top: 30px;
}

.intelliSearch .frm_dvhome {
  position: absolute;
  top: 20px;
  z-index: 8;
  border-radius: 20px;
  right: 0;
  background: #fff;
  padding: 20px 20px 20px 20px;
}

.intelliSearch h5 {
  font-size: 0.75rem;
  font-weight: 700;
}

.intelliSearch::before {
  border-radius: 20px;
  background: #fbb415;
  z-index: 7;
  top: 7px;
  position: absolute;
  height: 315px;
  width: 350px !important;
  content: "";
  right: 62px;
}

.new-intelli {
  width: 400px !important;
}

.form-control-feedback {
  right: initial;
  left: 0;
  top: 4px;
  color: #ccc;
  position: relative;
}

.form-control-feedback::before {
  position: absolute;
  content: "";
  background: url(../img/feather-search.png) no-repeat 50% 50%;
  width: 22px;
  height: 22px;
  left: 8px;
  top: 100%;
}

.frm_spn {
  font-weight: 600;
  display: block;
  border-bottom: 1px solid #ccc;
  padding: 0 0 5px 0;
  margin-bottom: 10px;
  font-size: 14px;
}

.radiocustom input[type="radio"] {
  display: none;
}

.radiocustom input[type="radio"]:checked + label {
  background: #fcbd30;
  border-color: #fcbd30;
  color: #000;
}

.radiocustom1 label {
  text-align: center;
}

.radiocustom1 input[type="radio"] {
  display: none;
}

.radiocustom1 input[type="radio"]:checked + label {
  background: #fcbd30;
  border-color: #fcbd30;
  color: #000;
}

.new-intelli .form-group {
  margin-bottom: 10px;
}

.new-intelli h6 {
  font-size: 0.75rem;
  padding: 12px 0 5px 0;
  font-weight: 700;
}

.chosen-select:after {
  border-bottom: 1px solid #666;
  border-bottom-color: rgb(102, 102, 102);
  border-right: 1px solid #666;
  border-right-color: rgb(102, 102, 102);
  content: "";
  display: block;
  height: 8px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
}

.is-active .chosen-select:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.chosen-container-single .chosen-single {
  background: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  clear: both;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 0.75rem;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.srch_btn {
  font-weight: 500;
  bottom: 2px;
  top: auto;
  right: 0;
  left: 0;
  position: relative;
  height: 36px;
  border-radius: 25px;
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: #0f0f0f;
  background: #fcbd30;
  border: 1px solid #fcbd30;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

.has-search .form-control {
  padding-right: 12px;
  padding-left: 45px;
  font-size: 0.8rem;
}

.study_level_btn {
  width: 100%;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #b1b1b1;
  cursor: pointer;
}

/*-----------------
    7. Home
    -----------------------*/

h2 {
  font-weight: 600;
  width: 100%;
}

.greybg {
  background-color: #f6f7f9;
}

.featured-item {
  overflow: hidden;
  display: inline-block;
  width: 100%;
  margin: 0px;
  padding: 0px 10px;
}

.featured-item .feat-img {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.feat-img .th-name {
  position: relative;
  padding: 15px;
  z-index: 1;
  display: flex;
  align-items: center;
  background-color: #f3f0e7;
  min-height: 100px;
}

.feat-img .th-name img {
  margin-right: 10px;
  height: 36px !important;
  width: auto !important;
}

.feat-img .th-name h5 {
  color: #333;
  margin-bottom: 0;
  font-size: 1rem;
}

button.wishlist {
  width: 34px;
  height: 34px;
  border: none;
  color: rgb(238, 238, 238);
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  transition: none;
  vertical-align: top;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  background: none;
  font-size: 28px;
}

.landing-logo {
  margin-left: 50px;
}

.landing-contact {
  margin-right: 83px;
}

button.wishlist:hover,
button.wishlist.active {
  color: #fcc160;
}

.featured-item .overlayPort {
  position: absolute;
  top: 2px;
  width: 100%;
}

.featured-item .overlayPort ul {
  display: flex;
  justify-content: space-between;
}

.featured-item .overlayPort ul li {
  padding: 0 10px;
  display: inline-block;
}

/* .featured-item .overlayPort ul li:before {
    background-color: #047475;
    transform: skewY(-45deg);
    content: '';
    position: absolute;
    width: 85px;
    height: 87px;
    z-index: 0;
    top: -65px;
    left: 0px;
} */

.app_max_err {
  color: orange;
}

.cus_close {
  position: absolute;
  right: 0px;
  margin-top: 9px;
  border: 1px solid #da7878;
  text-align: center;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  padding-top: 3px;
  padding-left: 3px;
  background: #ff5e5e;
  color: #fff;
  cursor: pointer;
}

.is_admi_verified:before {
  background-color: #047475;
  transform: skewY(-45deg);
  content: "";
  position: absolute;
  width: 85px;
  height: 87px;
  z-index: 0;
  top: -65px;
  left: 0px;
}

.featured-item .overlayPort ul li svg {
  position: relative;
  z-index: 1;
  width: 24px;
}

/* Tranding  Start */

.tranding-item {
  border: 1px solid #0b264b;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  position: relative;
  margin: 0 10px;
}

.tranding-item h6 {
  text-transform: uppercase;
  color: #0b264b;
  font-weight: 600;
}

.tranding-item .feat-text {
  padding: 40px 30px 15px 30px;
}

.tranding-item span.time {
  border-bottom: 1px solid #ccc;
  display: inline-block;
  padding: 0 25px 8px;
}

.tranding-item span.time i {
  font-size: 20px;
  padding-right: 10px;
  color: #999;
}

.tranding-item span.time.acive i {
  color: #111;
}

.tranding-item span.time.active i {
  color: #101010;
}

.mt_btn_yellow {
  position: relative;
  padding: 8px 36px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  color: #333;
  text-decoration: none;
  transition: all 0.5s ease;
  z-index: 0;
}

.mt_btn_yellow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.mt_btn_yellow:hover {
  color: #fcbd30;
}

.mt_btn_yellow::before {
  background-color: rgb(255, 172, 0);
}

.mt_btn_yellow:hover::before {
  opacity: 0;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
}

.mt_btn_yellow::after {
  border-color: rgb(255, 172, 0);
}

.mt_btn_yellow::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 5px;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 2px solid;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  -webkit-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}

.mt_btn_yellow:hover::after {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.iconscomments {
  text-align: left !important;
  padding: 10px;
}

.iconscomments li {
  display: inline-block;
  padding: 0 5px;
}

.iconscomments img {
  width: auto !important;
  display: inline !important;
}

.tranding-item .overlayPort {
  position: absolute;
  top: 10px;
  width: 100%;
}

.tranding-item .overlayPort ul {
  display: flex;
  justify-content: space-between;
}

.tranding-item .overlayPort ul li {
  padding: 0 20px;
  display: inline-block;
}

.tranding-item .overlayPort ul li.tdanding {
  background-color: #0b264b;
  transform: skew(0deg, 25deg);
  width: 170px;
  height: 80px;
  margin-top: -54px;
  color: #fff;
  padding-top: 48px;
  text-align: right;
  font-size: 18px;
  margin-right: -10px;
  font-weight: 500;
  font-family: "Roboto";
}

.tranding-item .overlayPort ul li.tdanding p {
  transform: skew(0deg, -25deg);
  display: block;
}

.tranding-item:nth-child(even) {
  border-color: #fbb415;
}

.tranding-item:nth-child(even) .overlayPort ul li.tdanding {
  background-color: #fbb415;
}

.tranding-item:nth-child(even) h6 {
  color: #fbb415;
}

.tranding_slide .tranding-item .feat-text p:nth-child(2) {
  min-height: 96px;
}

.slick-slide:nth-child(even) {
  border-color: #fbb415;
}

.slick-slide:nth-child(even) .overlayPort ul li.tdanding {
  background-color: #fbb415;
}

.slick-slide:nth-child(even) h6 {
  color: #fbb415;
}

/* Tranding End */

.view-more {
  position: relative;
  padding: 8px 40px;
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  border-radius: 50px;
  transition: all 0.5s ease;
  z-index: 0;
  transition: all 0.3s ease 0s;
  background: rgb(14, 40, 70, 1);
  background: -moz-linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#0e2846", endColorstr="#127b7e", GradientType=1);
}

.view-more1 {
  position: relative;
  padding: 8px 40px;
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  border-radius: 50px;
  border: 1px solid #fff;
  transition: all 0.5s ease;
  z-index: 0;
  transition: all 0.3s ease 0s;
  background: transparent;
}

.view-more1:hover,
.view-more1:active,
.view-more1:focus {
  color: #9bf7d1 !important;
}

.view-more::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  display: none;
}

.view-more:hover,
.view-more:active,
.view-more:focus {
  color: #9bf7d1 !important;
}

.view-more::before {
  background-color: transparent;
  opacity: 0;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
}

.view-more:hover::before {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  background-color: rgb(255, 172, 0);
}

.view-more::after {
  display: none;
}

.view-more::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 5px;
  opacity: 1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 2px solid #fcbd30;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.view-more:hover::after {
  opacity: 0;
  -webkit-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}

/* Offers Start */

.offers-item {
  border-radius: 10px 10px 0 0;
  /* overflow: hidden; */
  text-align: center;
  position: relative;
  margin: 35px 16px;
  background-color: #047475;
}

.offers-item:before {
  width: 78px;
  height: 105px;
  position: absolute;
  content: "";
  z-index: -1;
  right: -40px;
  top: -53px;
  background-image: url(../img/dot_offer-blue.png);
}

.offers-item h3 {
  color: #f0f0f0;
  font-weight: 400;
  width: 55%;
  text-align: right;
}

.offers-item h3 span {
  display: block;
  font-size: 48px;
  font-weight: 800;
  line-height: 40px;
}

.offers-item .feat-text {
  padding: 0px;
}

.offers-item .content-box {
  width: 100%;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-top: 0;
}

.offers-item .content-box:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 62px;
  background-color: #047475;
  transform: skew(0deg, -7deg);
  top: -47px;
  left: 0;
}

.offers-item .mt_btn_yellow:before,
.offers-item .mt_btn_yellow:after {
  border-radius: 0px !important;
}

.offers-item .mt_btn_yellow {
  position: relative;
  padding: 10px 26px;
  font-size: 18px;
}

.offers-item:nth-child(even),
.offers-item:nth-child(even) .content-box:before {
  background-color: #0b264b;
}

/* Profile Offer */

.offers-item_ {
  border-radius: 10px 10px 0 0;
  /* overflow: hidden; */
  text-align: center;
  position: relative;
  background-color: #047475;
}

.offers-item_:before {
  width: 78px;
  height: 105px;
  position: absolute;
  content: "";
  z-index: -1;
  right: -40px;
  top: -53px;
  background-image: url(../img/dot_offer-blue.png);
}

.offers-item_ h5 {
  color: #f0f0f0;
}

.offers-item_ .feat-text {
  padding: 0px;
}

.offers-item_ .content-box {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-top: 0;
}

.offers-item_ .content-box:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 36px;
  background-color: #047475;
  transform: skew(0deg, -7deg);
  top: -19px;
  left: 0;
}

.offers-item_ .mt_btn_yellow:before,
.offers-item_ .mt_btn_yellow:after {
  border-radius: 0px !important;
}

.offers-item_ .mt_btn_yellow {
  position: relative;
  padding: 10px 26px;
  font-size: 18px;
}

.offers-item_:nth-child(even),
.offers-item_:nth-child(even) .content-box:before {
  background-color: #0b264b;
}

.offers-item_ h5 span {
  display: block !important;
  font-size: 16px;
  border-bottom: 0 !important;
}

.margin_auto {
  margin: 0px auto;
}

/* .offers-item:nth-child(odd):before {
    background-image: url(../img/dot_offer-yellow.png);
    top: auto;
    bottom: -53px;
    } */

.slick-slide:nth-child(odd) .offers-item:before {
  background-image: url(../img/dot_offer-yellow.png);
  top: auto;
  bottom: -53px;
}

slick-slide
/* Offers End */


/* Couch Stat */

.bg-caoch {
  transition: all 0.3s ease 0s;
  background: rgb(14, 40, 70, 1);
  background: -moz-linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#0e2846", endColorstr="#127b7e", GradientType=1);
  margin-bottom: 35px;
}

.couch-item {
  border-radius: 20px;
  /* overflow: hidden; */
  text-align: center;
  position: relative;
  margin: 20px;
  background-color: #fff;
  padding: 10px 20px;
}

.couch-item:before {
  width: 360px;
  height: 360px;
  position: absolute;
  content: "";
  z-index: -1;
  right: -12px;
  top: -12px;
  background-color: #fbb415;
  border-radius: 20px;
}

.couch-item h3 {
  color: #fbb415;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 0 0;
}

.couch-item h3 span {
  display: block;
  font-size: 48px;
  font-weight: 800;
  line-height: 40px;
}

.feat-text {
  padding: 5px 15px;
  /* box-shadow: 0 0 5px #ccc; */
  /* margin: 20px 0 0 0; */
}

.feat-inn span {
  font-size: 11px;
  padding: 0 5px;
  float: right;
  line-height: 26px;
}

.feat-inn.discussiondetails span {
  font-size: 11px;
  padding: 0 5px;
  float: left !important;
  line-height: 26px;
}

.feat-inn span p {
  color: #fcbd30;
  font-weight: bold;
  display: inline;
}

.feat-inn p {
  display: block;
}

.feat-inn > ul {
  display: inline-block;
  margin-right: 10px;
}

.feat-inn ul li {
  display: inline-block;
  padding: 0;
  margin: 2px 0;
}

.feat-inn ul li > i {
  font-size: 13px;
  color: #fcbd30;
  margin-right: 3px;
}

.couch-item .one-arrow {
  font-size: 28px;
  font-weight: 400;
  color: #fff;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 15px 10px 5px 10px;
  font-family: "Roboto";
}

.couch-item .one-arrow span {
  background-color: #0b264b !important;
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  box-shadow: 3px 0 3px #ccc;
}

.couch-item .one-arrow a {
  background-color: #fcbd30 !important;
  border: 1px solid #fcbd30;
  display: inline-block;
  font-size: 14px;
  padding: 10px 30px;
  color: #000;
  box-shadow: 3px 0 3px #ccc;
}

.coach-view {
  margin-top: 10px;
}

.c-select {
  border-color: #fbb415 !important;
}

.couch-item .one-arrow a:hover {
  background-color: transparent !important;
  border: 1px solid #fcbd30;
  color: #fcbd30;
}

.couch-item .one-arrow a i {
  padding: 9px;
  font-size: 22px;
  padding-left: 12px;
}

.couch-item .content-box {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  padding-top: 0;
  text-align: left;
  text-align: justify;
  color: #01132f !important;
}

.couch-item .content-box p {
  font-weight: 500;
  padding: 5px 0;
  min-height: 50px;
}

.couch-item .content-box h4 span {
  float: right;
}

.couch-item:nth-child(even) h3 {
  color: #01132f;
}

/* .couch-item:nth-child(even):before {
    right: -12px;
    top: auto;
    bottom: -12px;
    } */

.coachslider .slick-slide:nth-child(even) .couch-item:before {
  right: -12px;
  top: auto;
  bottom: -12px;
}

.couch-item .feat-text > img {
  width: 50% !important;
  margin: 20px auto;
  border-radius: 25px;
}

.couch-item .feat-text {
  padding: 5px 15px;
  box-shadow: 0 0 5px #ccc;
  margin: 20px 0 0 0;
}

/* Couch  End */

/* App Start */

.appbg {
  background: url(../img/appright.png) right no-repeat;
}

.appbg .sub-section {
  margin: 40px 45px 40px 0;
  box-shadow: -6px 0px 13px -8px #b8e2fe;
  padding: 20px;
  padding-right: 48px;
}

ul.cate-box {
  width: 100%;
}

.cate-box li.job-category {
  float: left;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  transition: all 0.5s ease;
  width: 24%;
  margin: 5px;
  position: relative;
  z-index: 1;
}

.cate-box li.job-category:hover {
  box-shadow: 0 0 10px #b8e2fe;
  transform: scale(1.02);
  z-index: 2;
}

/* .cate-box li.job-category p{font-size: 12px;} */

.job-category h6 {
  color: inherit;
  /* font-size: 15px; */
  margin-bottom: 0.3rem;
}

.cate-box li.job-category h6,
.cate-box li.job-category p {
  font-size: 22px;
  line-height: 22px;
}

.cate-box li.job-categorynew::before,
.cate-box li.job-categorynew::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border: 1px solid #ccc;
  transition: 0.25s ease-out;
  will-change: transform;
  contain: paint;
}

.cate-box li.job-categorynew::before {
  transform: scale3d(0, 1, 1);
  border-width: 1px 0 1px 0;
}

.content-wrap {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cate-box li.job-categorynew::after {
  transform: scale3d(1, 0, 1);
  border-width: 0 1px 0 1px;
}

.cate-box li.job-categorynew:hover {
  /* border-color: rgba(214, 214, 214, 0);
    background: rgba(0, 0, 0, 0.01); */
}

.cate-box li.job-categorynew:hover::before,
.cate-box li.job-categorynew:hover::after {
  transform: scale3d(0.9999, 0.9999, 0.9999);
}

.appbg .headingmains h2 {
  font-size: 3.5rem;
  font-weight: 400;
}

.appdown {
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;
  padding: 20px 0;
}

.appdown h3 {
  width: 340px;
  font-size: 35px;
  line-height: 60px;
  color: #444;
}

.appdown ul li {
  display: inline-block;
  margin: 0;
}

ul.cate-box {
  width: 100%;
  margin: 0 auto;
}

.cate-box li.job-categorynew {
  float: left;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  transition: all 0.5s ease;
  width: 17.5%;
  margin: 0 20px 0 0;
  padding: 10px;
  position: relative;
  z-index: 1;
  background: #fff;
  box-shadow: 0 5px 15px 0 #b8e2fe;
  -webkit-box-shadow: 0 5px 10px 0 #b8e2fe;
  box-sizing: border-box;
}

.cate-box li.job-categorynew:hover {
  /* box-shadow: 0 0 10px #333; */
}

.cate-box li.job-categorynew p {
  font-size: 10px;
}

.job-categorynew h6 {
  color: inherit;
  font-size: 15px;
  margin-bottom: 0.3rem;
}

.cate-box li.job-categorynew .icon-circle img {
  width: auto;
  margin-bottom: 0.75rem;
  /* height: 62px; */
}

/* App End */

/* Services */

.service-category {
  background: #fff;
  box-shadow: 0 5px 15px 0 #ccc;
  -webkit-box-shadow: 0 5px 10px 0 #ccc;
  box-sizing: border-box;
  text-align: center;
  border-radius: 5px;
  padding: 20px;
  /* width: ; */
  margin: 12px 20px;
}

/* .service-category:hover {
    box-shadow: 0 0 10px #B8E2FE;
    } */

.service-category img {
  height: 70px;
  width: auto !important;
  margin: 0 auto;
}

.service-category h4 {
  color: inherit;
  margin-bottom: 0.3rem;
  padding: 15px;
  font-size: 1.25rem;
}

.ser-box {
  justify-content: space-between;
  margin-top: 40px;
}

/* Services */

.tesi-wrap {
  background: #f6f7f9 url(../img/bg-esti.png) bottom left no-repeat;
  /* background-size: cover; */
  padding: 125px 0;
  background-size: 100%;
}

.tesi-wrap.no-bg {
  background: unset;
  padding: 0px;
}

.tesi-wrap.no-bg .team_member {
  height: unset;
  padding: 20px;
}

.blog_url_button {
  background: linear-gradient(90deg, #0e2846, #127b7e);
  width: 165px;
  border-radius: 5px;
  display: block;
  margin: 8px 2px;
  color: #fff !important;
  text-align: center;
  height: 30px;
  box-sizing: border-box;
  line-height: 30px;
  padding: 0;
}

.tesi-wrap .inner-heading {
  padding-left: 110px;
  padding-top: 226px;
}

.tesi-wrap .headingmains p,
.tesi-wrap .headingmains h2 {
  color: #fff;
  margin: 0px;
}

.singleslider .slick-next,
.singleslider .slick-prev {
  background: #047475;
  border-radius: 50%;
  margin: 0 -48px;
  width: 30px;
  height: 30px;
}

.topbar-inner .top-bar-left ul li img {
  text-align: center;
  margin-right: 8px;
}

.tesi-wrap .team_member {
  background: #fff;
  padding: 40px 20px 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 12px #e6e6e6;
  height: 490px;
}

.tesi-wrap .team_member h5 {
  margin-top: 12px;
}

.tesi-wrap .team_member h5 span {
  width: 55%;
  margin: 0 auto;
  height: 2px;
  background-color: #fcbd30;
  margin-bottom: 15px;
  display: block;
}

.tesi-wrap .team_member .effect-julia img {
  width: 108px !important;
  height: 108px;
  margin: 0 auto;
  border-radius: 50%;
}

.tesi-wrap .team_member p {
  min-height: 238px;
  max-height: 238px;
  overflow: hidden;
  display: block;
  padding-top: 20px;
}

.tesi-wrap .team_member p.auto_scroll {
  overflow-y: auto !important;
}

h4.title:hover {
  color: #fbb415;
}

.slick__btn:before {
  font-size: 28px !important;
}

.videobx img {
  height: 150px;
  width: auto !important;
  margin: 0 auto;
}

li.__course:hover {
  color: #fcbd30;
}

.member_name iframe {
  width: 100% !important;
}

.why_slide {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.resolve-btn {
  background: linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  border: 0;
  font-size: 13px;
  padding: 0.2rem 0.75rem;
  border-radius: 5px;
}

.unresolve-btn {
  color: #f64e75;
  text-decoration: none;
  background-color: #ffe2e5;
  border: 0;
  font-size: 13px;
  padding: 0.2rem 0.75rem;
  border-radius: 5px;
}

.why-item {
  position: relative;
  margin: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 5px #ccc;
  max-height: 100vh;
}

.why-choose-ad {
  position: relative;
  margin: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 5px #ccc;
  width: 32%;
}

.why-choose-ad .content-box {
  padding: 0 10px 20px 10px;
  text-align: left !important;
}

.why-choose-ad .content-box p {
  padding-right: 60px;
  font-size: 0.8rem;
}

.why-item .content-box {
  padding: 0 10px 20px 10px;
  text-align: left !important;
}

.why-item .content-box p {
  padding-right: 60px;
  font-size: 0.8rem;
}

.why-item img {
  width: 100%;
}

h5.homeunire:hover {
  border-bottom: 1px solid;
}

.lazyloaded {
  vertical-align: middle;
  border-style: none;
  width: auto;
  max-height: 160px;
  height: inherit;
  text-align: center;
  margin: 0px auto;
}

a.uni_name:hover {
  border-bottom: 1px solid;
}

.why-item .content-box a {
  box-shadow: 3px 0 3px #ccc;
  margin: 0 auto;
  padding: 10px 12px;
  width: 130px;
  display: block;
  margin-top: 15px;
  font-size: 14px;
  text-align: center;
}

.choosetxt p {
  font-size: 1.1rem;
}

.studybg {
  background: #f6f7f9 url(../img/studybg.png) top no-repeat;
  background-size: 101% auto;
}

.studybg .headingmains h2:after,
.studybg .headingmains h2:before {
  display: none;
}

.studybg .headingmains h2 {
  color: #fbb415;
}

.studybg .headingmains p {
  color: #fff;
}

.studybg .why-item .content-box a {
  border-radius: 5px;
}

/*-----------------
    8. Footer
    -----------------------*/

.footer {
  background: rgb(4, 116, 117);
  background: -moz-linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  background: -webkit-linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  background: linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#047475", endColorstr="#01132f", GradientType=1);
}

.footer .footer-top {
  padding: 60px 0 20px 0;
}

.footer .footer-top .countrybx {
  padding: 12px 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border: 1px solid #fcbd30;
  border-left: 0px;
  border-right: 0px;
}

.footer .footer-top .countrybx h6 {
  color: #fcbd30;
  padding: 0px 0;
}

.footer .footer-top .countrybx ul {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
}

.footer .footer-top .countrybx ul li a {
  color: #fff;
  padding: 0px 20px;
  position: relative;
}

.footer .footer-top .countrybx ul li a:after {
  width: 1px;
  height: 100%;
  background-color: #fff;
  right: 0px;
  top: 0;
  position: absolute;
  content: "";
}

.footer .footer-top .countrybx ul li a:hover {
  text-decoration: underline;
}

.footer .footer-top .countrybx ul li:last-child a:after {
  display: none;
}

.footer-title {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 50px;
  position: relative;
}

.footer-title:after {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  background: #fcbd30;
  left: 0;
  bottom: -15px;
}

.footer .footer-widget.footer-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  outline: none;
}

.footer .footer-widget .footer-logo {
  margin-bottom: 30px;
}

.footer .footer-widget .footer-about-content p {
  color: #fff;
  width: 79%;
  font-size: 12px;
  margin-bottom: 10px;
}

.footer .footer-widget .footer-about-content p span {
  font-weight: bold;
  padding-right: 5px;
  display: inline-block;
}

.footer .footer-widget .footer-about-content p a {
  background-color: #fcbd30;
}

.footer .footer-widget .footer-about-content hr {
  border-color: #fcbd30;
  margin: 30px 0 15px;
  border-width: 2px;
}

.footer .footer-widget .footer-about-content p a:hover {
  text-decoration: underline;
}

.footer .footer-widget .footer-about-content a.read {
  color: #fcbd30;
  background-color: transparent !important;
}

.footer .footer-menu ul li {
  margin-bottom: 10px;
  position: relative;
  display: block;
}

.footer .footer-menu ul li:last-child {
  margin-bottom: 0;
}

.footer .footer-menu ul li a {
  color: #fbfbfb;
  font-size: 12px;
  transition: all 0.4s ease 0s;
  padding-left: 0px;
}

.footer .footer-menu ul li a::before {
  position: absolute;
  left: 0;
  top: 10px;
  width: 0px;
  height: 1px;
  content: "";
  background: #d9d5da;
  transition: all 0.5s ease-in-out;
}

.footer .footer-menu ul li a:hover {
  color: #fff !important;
  padding-left: 20px;
}

.footer .footer-menu ul li a:hover::before {
  background: #ffffff;
  width: 12px;
  transition: all 0.5s ease-in-out;
}

.footer .footer-menu ul li a i {
  margin-right: 5px;
}

.footer .footer-widget.footer-menu ul li a:hover {
  color: #fff;
  letter-spacing: 0.5px;
  padding-left: 20px;
}

.footer .footer-bottom {
  background-color: #26251e;
  padding: 15px 0;
}

.footer .footer-bottom .copyright-text p {
  color: #fff;
  font-size: 0.7rem;
}

ul.social {
  display: flex;
  padding: 10px 0px 0 0 !important;
  align-items: center;
}

ul.social li {
  margin: 0px !important;
}

ul.social li a {
  background-color: #ccc;
  display: inline-block;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  text-align: center;
  margin: 0 15px 0 0px;
  padding: 7px 0 0 4px !important;
  color: #333 !important;
  font-size: 15px !important;
}

ul.social li a::before {
  display: none;
}

ul.social li a:hover {
  background-color: #fcbd30;
  padding: 7px 0 0 4px !important;
}

.offie a {
  background: #fff;
  color: #222;
  padding: 4px 15px;
  border-radius: 5px;
  margin: 0 5px 10px 0;
  display: inline-block;
  font-size: 0.7rem;
}

.offie a:hover {
  background: #fcbd30;
}

.show767 {
  display: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > ul > li + li {
    margin-left: 10px;
  }

  .page-heading-section6 {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 991.98px) {
  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  .header {
  }

  .page-heading-section.general {
    margin-top: 70px;
  }

  .page-heading-content .title {
    font-size: 46px;
  }

  .page-heading-content p {
    font-size: 20px;
  }

  .main-menu {
    display: none;
    width: 100%;
  }

  .contact-form .w-25 {
    white-space: nowrap;
    width: 100px !important;
  }

  .search-sec .hr {
    display: none;
  }

  .log-menu,
  .login-item {
    display: none;
  }

  .logo-wrap {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .categories-group {
    padding: 30px 0;
    display: block;
  }

  .columns {
    float: left;
  }

  .form-view {
    padding: 30px;
  }

  .footer-title {
    margin-top: 20px;
  }

  .simple-checkbox .custom-control-label,
  .search_widget .field_search input {
    font-size: 0.85rem;
  }

  .cate-box li.job-categorynew {
    width: 22%;
  }

  .cate-box li.job-category {
    width: 31%;
  }

  .main-logo img {
    width: auto;
  }

  .page-heading-section6 {
    padding-top: 0px;
  }

  .page-heading-content .title {
    font-size: 36px;
  }

  .page-heading-content p {
    font-size: inherit;
  }

  .jp_newsletter_text {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767.98px) {
  h2 {
    font-size: 1.5rem;
  }

  .tesi-wrap .team_member p,
  .videobx {
    min-height: 298px !important;
  }

  .top-bar-left.pull-left,
  .top-bar-right.pull-right.righttop {
    margin-top: 0px !important;
  }

  .search-sec {
    margin: 12px 30px 5px 7px !important;
  }

  .tesi-wrap .team_member {
    height: 540px;
  }

  h4 {
    font-size: 1.25rem;
  }

  ul.social li a {
    margin: 0 8px 0 0px;
  }

  ul.social {
    padding: 10px 27px 0 0 !important;
  }

  .page-heading-content .title {
    font-size: 36px;
  }

  .page-heading-content p {
    font-size: 18px;
  }

  .main-menu {
    display: none;
    width: 100%;
  }

  .offer_model {
    margin-top: 150px !important;
  }

  /* .course__ {
        position: absolute;
        left: 0;
        padding: 30px 20px 20px 14px;
    } */
  /* .course__font {
        font-size: 11px;
    } */
  .close_btn_ {
    text-align: end;
    padding: 4px 12px 0px 10px;
    font-size: 15px;
  }

  /* .examscore {
        padding-top: 60px !important;
    } */
  .social__mob {
    position: absolute;
    bottom: -21px;
    right: 95%;
  }

  .__mob {
    width: 50%;
  }

  .main-menu > ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 20px;
  }

  .main-menu > ul > li {
    padding: 0;
  }

  .main-menu > ul > li + li {
    margin-top: 20px;
    margin-left: 0;
  }

  .main-menu > ul > li > a::before {
    display: none;
  }

  .main-menu > ul > li > a.active {
    padding-left: 12px;
    border-left: 4px solid #ffffff;
  }

  .log-menu,
  .login-item {
    display: none;
  }

  .single-logo {
    text-align: center;
  }

  .cate-box {
    text-align: center;
    justify-content: center;
  }

  .cate-box li.job-category:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  .footer-col {
    margin-bottom: 0.5rem;
  }

  .job-data .company-logo {
    padding: 0;
    width: 100%;
    padding-bottom: 0.5rem;
  }

  .job-data .list-content {
    padding-bottom: 0.5rem;
  }

  .job-data .list-content,
  .job-data .apply-sec {
    width: 100%;
  }

  .blog-post .blog-posthumb {
    width: 100%;
    display: block;
    vertical-align: unset;
    margin-bottom: 0.5rem;
  }

  .blog-post .post-detail {
    padding: 0;
    display: block;
  }

  .post-metas > li {
    margin-bottom: 0.5rem;
  }

  .blog-single > h2,
  .post-data > h2 {
    margin: 10px 0;
  }

  .blog-post {
    padding: 0;
  }

  .blog-post .post-metas > li {
    margin-top: 1rem;
  }

  .logo-wrap {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .count-wrap {
    border: none;
    margin-bottom: 2rem;
  }

  .blog-list {
    margin-bottom: 1rem;
  }

  .job-data .list-content.col {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
  }

  .search-sec .hr {
    display: none;
  }

  .job-des > ul li {
    margin-bottom: 10px !important;
  }

  .widget.border {
    margin-bottom: 30px !important;
  }

  .right-bar .widget.border.mb-0 {
    margin-bottom: 0 !important;
  }

  .save-btn {
    margin-bottom: 1rem;
  }

  .work-list.last {
    margin-bottom: 24px !important;
  }

  .slidercont .owl-item:nth-child(2),
  .slidercont .owl-item:nth-child(5) {
    background-color: #babfc3;
  }

  .page-heading-content p {
    font-size: 15px;
  }

  .page-heading-content .title {
    font-size: 23px;
  }

  .slidercont .owl-item img {
    padding-top: 90px;
  }

  .main-logo img {
    width: 145px;
  }

  .cate-box li.job-categorynew {
    width: 43%;
  }

  .job-post {
    padding: 12px 34px;
  }

  .job-post h2 {
    text-align: center;
  }

  .job-post h2 br {
    display: none;
  }

  .page-heading-section.general {
    margin-top: 60px;
  }

  .show767 {
    display: block;
  }

  .hide767 {
    display: none;
  }

  .order-sm-1 {
    order: -1;
  }

  .uninam {
    padding: 10px;
  }

  .mt-25 {
    margin-top: 0px;
  }

  .postcard__text01 {
    margin: 10px 20px;
  }

  .landing-tag h1 {
    font-size: 14px;
  }

  .landing-tag {
    top: 24%;
  }

  .landing-tag button {
    font-size: 11px;
    padding: 7px;
  }

  .uniimg img {
    max-width: 160px;
    height: 160px;
  }

  .topuni h4 {
    font-size: 20px !important;
  }

  .landing_icon_ {
    width: 50% !important;
  }

  .uniimg {
    max-width: 174px !important;
  }

  .txt_align {
    text-align: left !important;
  }

  .uninam strong {
    font-size: 13px !important;
  }

  .banner__ {
    height: 120px;
  }

  .react-responsive-modal-modal {
    max-width: 343px !important;
  }
}

.banner__ {
  opacity: 0.7;
  filter: brightness(0.5);
}

@media only screen and (max-width: 479px) {
  .header-logo a {
    width: 130px;
  }

  .resmenu-close {
    width: 40px;
    height: 40px;
  }

  .resmenu-section {
    width: 100%;
    padding-left: 50px;
  }

  .forgot {
    float: left !important;
    width: 100%;
    text-align: left;
    padding: 10px 0;
    padding-bottom: 0;
  }

  .cate-box li.job-category {
    width: 100%;
    margin: 3px;
    height: 60px;
  }

  .cate-box li.job-category h6,
  .cate-box li.job-category p {
    font-size: 18px;
    line-height: 16px;
  }

  .jp_newsletter_field input,
  .jp_newsletter_field button {
    width: 100%;
  }
}

@media only screen and (max-width: 320px) {
  .cate-box li.job-category {
    width: 250px;
    height: 150px;
    margin: 3px;
  }
}

.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

/*.slick-track:before,
    .slick-track:after {
    display: table;
    content: ''
    }
    
    .slick-track:after {
    clear: both
    }
    */

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

/* Slider */

/* Arrows */

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  line-height: 40px;
  z-index: 1;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: #fff;
  outline: none;
  background: #0b264b;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0;
}

.slick-prev:before,
.slick-next:before {
  font-family: "fontawesome";
  font-size: 52px;
  line-height: 1;
  opacity: 0.8;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slider-section .slick-prev,
.slider-section .slick-next {
  left: 44%;
  background: #fff;
  height: 50px;
  width: 50px;
  z-index: 10;
  top: 15%;
  padding: 5px 5px 5px 0;
  box-shadow: 0 0 10px #ccc;
  z-index: 1;
}

.slider-section .slick-next {
  right: 44%;
  left: inherit;
  padding: 5px 0px 5px 5px;
}

.slider-section .slick-prev:before,
.slider-section .slick-next:before {
  font-size: 36px;
}

.slick-prev {
  left: 29px;
}

[dir="rtl"] .slick-prev {
  right: 15px;
  left: auto;
}

.slick-prev:before {
  content: "\f104";
}

[dir="rtl"] .slick-prev:before {
  content: "\f105";
}

.slick-next {
  right: 32px;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: 15px;
}

.slick-next:before {
  content: "\f105";
}

[dir="rtl"] .slick-next:before {
  content: "\f104";
}

@media (max-width: 1100px) {
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
  .country_uni_ {
    overflow: auto;
    max-height: 100px;
  }

  .side-top-bg {
    background-color: #11656f;
    height: 100px;
    width: 100%;
  }

  .side-top-bg img {
    width: 85%;
    padding: 22px 65px 18px 5px;
  }

  .close_btn {
    z-index: 999;
    color: #faa831;
    position: fixed;
    right: 5px;
    top: 2px;
    font-size: 26px;
    z-index: 999;
  }

  .zeynep ul > li:not(:last-child) > a {
    border-bottom: 1px dotted #11656f !important;
  }

  .zeynep ul > li > a {
    color: inherit;
    display: block;
    font-size: 17px;
    font-weight: 700;
    padding: 20px 20px;
    text-decoration: none;
    transition: all 0.15s;
  }
}

@media (max-width: 639px) {
}

/* Dots */

.slick-slider {
  margin: 0;
}

.slick-dots {
  position: absolute;
  bottom: 30px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 16px;
  height: 16px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  content: "";
  /* opacity: .25; */
  background: #047475;
  border-radius: 50%;
}

.slick-dots li.slick-active button:before {
  opacity: 0.9;
  background: #f7941e;
}

.slick-slide .image {
  padding: 2px;
}

.slick-slide img {
  display: block;
  width: 100%;
}

.slick-slide img.slick-loading {
  border: 0;
}

.variable-width .slick-slide p {
  height: 100px;
  color: #fff;
  margin: 5px;
  line-height: 100px;
}

.variable-width .image {
  height: 100%;
}

.variable-width .image img {
  display: block;
  height: 100%;
  width: 100%;
}

.slick-center-mode .slick-center h3 {
  -moz-transform: scale(1.08);
  -ms-transform: scale(1.08);
  -o-transform: scale(1.08);
  -webkit-transform: scale(1.08);
  color: #e67e22;
  opacity: 1;
  transform: scale(1.08);
}

.slick-center h3 {
  opacity: 0.8;
  transition: all 300ms ease;
}

.slick-content {
  margin: auto;
  padding: 20px;
  width: 600px;
}

.slick-content:after,
.buttons::after {
  clear: both;
  content: "";
  display: table;
}

.slick-center-mode .slick-center .image {
  -moz-transform: scale(1.08);
  -ms-transform: scale(1.08);
  -o-transform: scale(1.08);
  -webkit-transform: scale(1.08);
  color: #e67e22;
  opacity: 1;
  transform: scale(1.08);
}

.slick-center-mode .image {
  opacity: 0.3;
  transition: all 300ms ease;
  padding: 10px;
}

.slick-center-mode .image:hover {
  cursor: pointer;
}

.slick-content {
  margin: auto;
  padding: 20px;
  width: 600px;
}

.slick-content:after,
.buttons::after {
  clear: both;
  content: "";
  display: table;
}

.slick-center-mode img {
  border: 2px solid #fff;
  display: block;
  width: 100%;
}

/* Slick Override */

.slick-slide .image {
  padding: 0;
}

/* Slick Carousel */

.slick-carousel.gap-2 {
  margin-left: -1px;
  margin-right: -1px;
}

.slick-carousel.gap-2 .slick-carousel-inner {
  padding-left: 1px;
  padding-right: 1px;
}

.slick-carousel.gap-5 {
  margin-left: -2px;
  margin-right: -3px;
}

.slick-carousel.gap-5 .slick-carousel-inner {
  padding-left: 2px;
  padding-right: 3px;
}

.slick-carousel.gap-10 {
  margin-left: -5px;
  margin-right: -5px;
}

.slick-carousel.gap-10 .slick-carousel-inner {
  padding-left: 5px;
  padding-right: 5px;
}

.slick-carousel.gap-15 {
  margin-left: -7px;
  margin-right: -8px;
}

.slick-carousel.gap-15 .slick-carousel-inner {
  padding-left: 7px;
  padding-right: 8px;
}

.slick-carousel.gap-20 {
  margin-left: -10px;
  margin-right: -10px;
}

.slick-carousel.gap-20 .slick-carousel-inner {
  padding-left: 10px;
  padding-right: 10px;
}

.slick-carousel.gap-25 {
  margin-left: -12px;
  margin-right: -13px;
}

.slick-carousel.gap-25 .slick-carousel-inner {
  padding-left: 12px;
  padding-right: 13px;
}

.slick-carousel.gap-30 {
  margin-left: -15px;
  margin-right: -15px;
}

.slick-carousel.gap-30 .slick-carousel-inner {
  padding-left: 15px;
  padding-right: 15px;
}

.slick-carousel.gap-40 {
  margin-left: -20px;
  margin-right: -20px;
}

.slick-carousel.gap-40 .slick-carousel-inner {
  padding-left: 20px;
  padding-right: 20px;
}

.slick-carousel.gap-50 {
  margin-left: -25px;
  margin-right: -25px;
}

.slick-carousel.gap-50 .slick-carousel-inner {
  padding-left: 25px;
  padding-right: 25px;
}

.slick-carousel-center-mode {
  margin-left: -40px;
  margin-right: -40px;
}

.slick-carousel-center-mode .slick-carousel-inner {
  margin: 40px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.slick-carousel-center-mode .slick-center .slick-carousel-inner {
  margin: 0;
  margin-left: -10px;
  margin-right: -10px;
}

.slick-carousel-center-mode .slick-dots {
  bottom: -50px;
}

.slick-gallery-slideshow .slick-prev {
  left: 0;
}

.slick-gallery-slideshow .slick-next {
  right: 0;
}

.slick-gallery-slideshow .slick-prev:before,
.slick-gallery-slideshow .slick-next:before {
  color: #fff;
  opacity: 0.7;
}

.slick-gallery-slideshow .slick-prev:hover:before,
.slick-gallery-slideshow .slick-prev:focus:before,
.slick-gallery-slideshow .slick-next:hover:before,
.slick-gallery-slideshow .slick-next:focus:before {
  opacity: 1;
}

.gallery-slideshow {
  margin-bottom: 1px;
}

.gallery-slideshow .image {
  padding: 0;
}

.gallery-nav .image {
  padding: 0;
  border: 1px solid transparent;
  opacity: 1;
  transition: all 300ms ease;
  position: relative;
}

.gallery-nav .image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.gallery-nav .slick-center .image {
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  -webkit-transform: none;
}

.gallery-nav .slick-center .image:after {
  background: rgba(0, 0, 0, 0);
}

.gallery-nav .image:hover {
  cursor: pointer;
}

.slick-slider-center-mode .slick-item {
  position: relative;
}

.slick-slider-center-mode .slick-item .image {
  position: relative;
  padding: 0;
}

.slick-slider-center-mode .slick-item .image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.slick-slider-center-mode .slick-center.slick-item .image:after {
  background: rgba(0, 0, 0, 0);
}

.slick-slider-center-mode .slick-item .image:hover {
  cursor: pointer;
}

.slick-slider-center-mode.slick-slider-full .slick-item .image:after {
  display: none;
}

.slick-slider-center-mode.gap-2 .slick-item {
  padding: 0 1px;
}

.slick-slider-center-mode .slick-prev {
  left: 30px;
}

.slick-slider-center-mode .slick-next {
  right: 30px;
}

.slick-slider-center-mode .slick-prev:before,
.slick-slider-center-mode .slick-next:before {
  color: #fff;
  opacity: 0.7;
}

.slick-slider-center-mode .slick-prev:hover:before,
.slick-slider-center-mode .slick-prev:focus:before,
.slick-slider-center-mode .slick-next:hover:before,
.slick-slider-center-mode .slick-next:focus:before {
  opacity: 1;
}

.slick-slider-center-mode.slick-slider-full .slick-prev {
  left: 15px;
}

.slick-slider-center-mode.slick-slider-full .slick-next {
  right: 15px;
}

.slick-slider-center-mode.slick-slider-full .image.image-bg {
  height: 660px;
  background-size: cover !important;
  background-position: center center;
}

.slick-slider-center-mode .slick-caption {
  position: absolute;
  top: 20px;
  left: 20px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.3);
}

.slick-slider-center-mode .slick-center .slick-caption {
  color: rgba(255, 255, 255, 1);
}

.slick-prev,
.slick-next {
  transform: translate(0, -50%);
}

.uni_slide .slick-prev,
.uni_slide .slick-next {
  background: #444;
  border-radius: 50%;
}

.uni_slide .slick-prev:before,
.uni_slide .slick-next:before {
  font-size: 30px;
  padding-left: 3px;
  line-height: 38px;
  color: #e3ad1d;
  vertical-align: middle;
}

.uni_slide .slick-prev:before {
  padding-right: 6px;
}

.tranding_slide .slick-prev,
.tranding_slide .slick-next {
  background: #fcbd30;
  border-radius: 50%;
  margin: 0 -50px;
}

.reply_user {
  width: 40px !important;
  height: 40px !important;
}

.tranding_slide .slick-prev:hover,
.tranding_slide .slick-next:hover {
  background-color: #d39a1a;
}

.tranding_slide .slick-prev:before,
.tranding_slide .slick-next:before {
  font-size: 38px;
  padding-left: 3px;
  color: #fff;
  vertical-align: middle;
}

.tranding_slide .slick-prev:before {
  padding-right: 6px;
}

.offerslider {
  margin-right: -10px;
}

.offerslider .slick-track {
  margin-left: -10px;
}

.offerslider .slick-prev,
.offerslider .slick-next {
  background: #047475;
  border-radius: 50%;
  margin: 0 -51px;
  width: 50px;
  height: 50px;
}

.offerslider .slick-next {
  margin: 0 -33px !important;
}

.offerslider .slick-prev:hover,
.offerslider .slick-next:hover {
  background-color: #0b264b;
}

.offerslider .slick-prev:before,
.offerslider .slick-next:before {
  font-size: 50px;
  padding-left: 3px;
  color: #fff;
}

.offerslider .slick-prev:before {
  padding-right: 6px;
}

.coachslider .slick-prev,
.coachslider .slick-next {
  background: #fff;
  border-radius: 50%;
  margin: 0 -58px;
  width: 50px;
  height: 50px;
}

.coachslider .slick-prev:hover,
.coachslider .slick-next:hover {
  background-color: #0b264b;
}

.coachslider .slick-prev:before,
.coachslider .slick-next:before {
  font-size: 50px;
  padding-left: 3px;
  color: #fcbd30;
}

.coachslider .slick-prev:before {
  padding-right: 6px;
}

.coachslider .slick-next {
  margin-right: -61px;
}

.ser-box .offerslider .slick-track {
  margin-left: 0px;
}

.ser-box .offerslider .slick-prev,
.ser-box .offerslider .slick-next {
  background: #047475;
  border-radius: 50%;
  margin: 0 -25px;
  width: 55px;
  height: 55px;
}

.ser-box .offerslider .slick-prev:hover,
.ser-box .offerslider .slick-next:hover {
  background-color: #0b264b;
}

.ser-box .offerslider .slick-prev:before,
.ser-box .offerslider .slick-next:before {
  font-size: 50px;
  padding-left: 3px;
  color: #fff;
}

.ser-box .offerslider .slick-prev:before {
  padding-right: 6px;
}

.rankingslider .slick-track {
  margin-left: 0px;
}

.rankingslider .slick-prev,
.rankingslider .slick-next {
  background: #047475;
  border-radius: 50%;
  margin: 0 -25px;
  width: 40px;
  height: 40px;
}

.rankingslider .slick-prev:hover,
.rankingslider .slick-next:hover {
  background-color: #0b264b;
}

.rankingslider .slick-prev:before,
.rankingslider .slick-next:before {
  font-size: 38px;
  padding-left: 3px;
  color: #fff;
}

.rankingslider .slick-prev:before {
  padding-right: 6px;
}

.singleslider .slick-track {
  margin-left: 0px;
}

.singleslider .slick-prev,
.singleslider .slick-next {
  background: #047475;
  border-radius: 50%;
  margin: 0 -45px;
  width: 30px;
  height: 30px;
}

.singleslider .slick-prev:hover,
.singleslider .slick-next:hover {
  background-color: #0b264b;
}

.singleslider .slick-prev:before,
.singleslider .slick-next:before {
  font-size: 28px;
  padding-left: 3px;
  color: #fff;
}

.singleslider .slick-prev:before {
  padding-right: 6px;
}

.sayslider .slick-track {
  margin-left: 0px;
}

.sayslider .slick-prev,
.sayslider .slick-next {
  background: #fcbd30;
  border-radius: 50%;
  margin: 0 -45px;
  width: 40px;
  height: 40px;
}

.sayslider .slick-prev:hover,
.sayslider .slick-next:hover {
  background-color: #fff;
}

.sayslider .slick-prev:before,
.sayslider .slick-next:before {
  font-size: 38px;
  padding-left: 3px;
  color: #222;
}

.sayslider .slick-prev:before {
  padding-right: 6px;
}

.testi_slider .slick-prev,
.testi_slider .slick-next {
  background: #fff;
  border-radius: 50%;
  margin: 0 -60px;
  width: 55px;
  height: 55px;
  left: -25%;
  top: 98%;
}

.testi_slider .slick-prev {
  left: -35%;
}

.testi_slider .slick-prev:hover,
.testi_slider .slick-next:hover {
  background-color: #0b264b;
}

.testi_slider .slick-prev:before,
.testi_slider .slick-next:before {
  font-size: 50px;
  padding-left: 3px;
  color: #fcbd30;
}

.testi_slider .slick-prev:before {
  padding-right: 6px;
}

.testi_slider .slick-next {
  margin-right: -75px;
}

.studyslider .slick-prev,
.studyslider .slick-next {
  background: #444;
  border-radius: 50%;
  margin: 0 -49px;
}

.studyslider .slick-prev:hover,
.studyslider .slick-next:hover {
  background-color: #fff;
}

.studyslider .slick-prev:before,
.studyslider .slick-next:before {
  /* font-size: 38px; */
  padding-left: 3px;
  color: #d39a1a;
}

.studyslider .slick-prev:before {
  padding-right: 6px;
}

.scale {
  max-width: 100%;
  height: auto;
  width: auto\9;
}

.mobnav {
  display: none;
}

.tg-nav {
  z-index: 2;
}

.tg-navigation {
  padding: 0;
  line-height: inherit;
}

.tg-navigation ul {
  list-style: none;
  line-height: inherit;
}

.tg-navigation ul li {
  line-height: inherit;
  list-style-type: none;
}

/* li.menu-item-has-children{position: relative;}
    li.menu-item-has-mega-menu{position: static;} */

li.menu-item-has-children > a:before,
li.menu-item-has-mega-menu > a:before {
  top: 0;
  right: 10px;
  content: "\f107";
  position: absolute;
  font-size: inherit;
  line-height: inherit;
  font-family: "FontAwesome";
}

.sub-menu li.menu-item-has-children > a:after {
  top: 0;
  right: 10px;
  content: "\f105";
  position: absolute;
  font-size: inherit;
  line-height: inherit;
  font-family: "FontAwesome";
}

.tg-navigation ul li .sub-menu li.current-menu-item > a {
  color: #333;
}

.tg-navigation ul li .sub-menu li.current-menu-item > a:before {
  height: 100%;
}

.tg-navigation > ul > li {
  float: left;
}

/* .tg-navigation ul li a{
    color: #666;
    display: block;
    padding: 0 25px;
    position: relative;
    line-height: inherit;
    }
    .tg-navigation > ul > li > a{
    color: #333;
    z-index: 2;
    } */

.tg-navigation > ul > li:last-child > .sub-menu {
  right: 0;
  left: auto;
}

.tg-navigation > ul > li:last-child > .sub-menu .sub-menu {
  right: 100%;
  left: auto;
}

/* #tg-magicbox {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    position: absolute;
    }
    a[data-new]:after {
    top: -2px;
    right: 0;
    color: #fff;
    background: #fff;
    font-size: 10px;
    line-height: 12px;
    padding: 0 3px 2px;
    position: absolute;
    border-radius: 2px;
    white-space: nowrap;
    content: attr(data-new);
    text-transform: capitalize;
    border-bottom-left-radius: 0;
    }
    a[data-new]:before{
    top: 12px;
    right: 23px;
    content: '';
    position: absolute;
    border-top: 4px solid;
    border-right: 4px solid transparent !important;
    } */

.tg-navigation > ul > li.current-menu-item a[data-new]:before {
  border-color: #fff;
}

.tg-navigation > ul > li.current-menu-item a[data-new]:after {
  background: #fff;
}

.tg-navigation ul li:hover > a,
.tg-navigation ul li > a:hover,
.tg-navigation ul li.current-menu-item > a,
.tg-navigation ul li:hover a[data-new]:before,
.tg-navigation ul li a:hover[data-new]:before {
  color: #111;
}

.tg-navigation ul li:hover a[data-new]:after,
.tg-navigation ul li a:hover[data-new]:after {
  background: #fff;
}

.tg-navigation ul li:hover a[data-new]:before,
.tg-navigation ul li a:hover[data-new]:before {
  border-color: #fff;
}

.tg-navigation ul li:hover > a {
  color: #fff;
}

.sub-menu,
.mega-menu {
  top: 100%;
  left: 0;
  z-index: 2;
  opacity: 0;
  width: 230px;
  background: #fff;
  position: absolute;
  visibility: hidden;
  -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
}

.sub-menu {
  border-top: 1px solid #ddd;
}

.sub-menu .sub-menu {
  left: 100%;
}

.sub-menu li {
  width: 100%;
  float: left;
}

.sub-menu li + li {
  border-top: 1px solid #ddd;
}

.sub-menu > li > a:before,
.mega-menu-col > ul > li > a:before {
  top: 0;
  left: 0;
  width: 3px;
  height: 0;
  content: "";
  position: absolute;
}

.tg-navigation ul.sub-menu > li:hover > a,
.tg-navigation ul.sub-menu > li > a:hover {
  color: #333;
}

.sub-menu > li:hover > a:before,
.sub-menu > li > a:hover:before,
.mega-menu-col > ul > li:hover > a:before,
.mega-menu-col > ul > li > a:hover:before {
  height: 100%;
}

.mega-menu-row {
  width: 100%;
  float: left;
  display: table;
  table-layout: fixed;
}

.mega-menu-col {
  display: table-cell;
  vertical-align: top;
}

.mega-menu-col + .mega-menu-col {
  background: #f7f7f7;
}

.tg-navigation .mega-menu-col > a {
  float: left;
  color: #333;
  padding: 0;
  margin: 0 25px;
  font-size: 16px;
}

.tg-navigation .mega-menu-col:hover > a,
.tg-navigation .mega-menu-col a:hover {
  color: #333;
}

.mega-menu-col > ul {
  width: 100%;
  float: left;
}

.mega-menu-col > ul > li + li {
  border-top: 1px solid #ddd;
}

/* .mega-menu-col > ul > li > a{position: relative;} */

.mega-menu-col > ul > li.current-menu-item > a {
  color: #333;
}

.mega-menu-col figure {
  margin: 0;
  float: left;
}

.mega-menu-col .tg-textbox {
  color: #fff;
  overflow: hidden;
  padding: 41px 20px;
}

.tg-textbox strong {
  color: #fff;
  display: block;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
}

.tg-textbox .tg-description {
  padding: 10px 0;
}

.tg-textbox .tg-description p {
  margin: 0;
}

.tg-textbox .tg-btn {
  color: #333;
  float: left;
  padding: 0 15px;
  background: #fff;
  line-height: 30px;
}

.mega-menu.tg-fullmegamenu {
  left: 0 !important;
  width: 100% !important;
  /* transform: skewX(20deg); */
}

.tg-headervtwo .mega-menu.tg-fullmegamenu {
  left: -42% !important;
}

.menu-item-has-children:hover > .sub-menu,
.menu-item-has-children .menu-item-has-children:hover > .sub-menu,
.menu-item-has-mega-menu:hover .mega-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.menu-item-has-children .menu-item-has-children:hover > .sub-menu {
  top: 0;
}

.tg-searchbox {
  float: right;
  position: relative;
}

.tg-btnsearch {
  color: #fff;
  width: 40px;
  height: 40px;
  display: block;
  line-height: 40px;
  text-align: center;
}

.tg-btnsearch:hover,
.tg-btnsearch:focus {
  color: #fff;
}

.tg-btnsearch i {
  display: block;
  text-align: center;
  line-height: inherit;
}

.tg-formtheme {
  width: 100%;
  float: left;
}

.tg-formtheme fieldset {
  margin: 0;
  border: 0;
  padding: 0;
  width: 100%;
  float: left;
}

.tg-searchbox .tg-formtheme {
  top: 0;
  right: 100%;
  z-index: 2;
  width: 340px;
  float: none;
  display: none;
  background: #fff;
  position: absolute;
}

.tg-searchbox .tg-formtheme .form-control {
  width: 100%;
  height: 40px;
  line-height: 18px;
}

.tg-dropdowarrow {
  top: 10px;
  z-index: 5;
  color: #fff;
  right: 10px;
  width: 20px;
  height: 20px;
  display: none;
  cursor: pointer;
  font-size: 8px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  background: #333;
}

.tg-dropdowarrow i {
  display: block;
  font-size: 8px;
  line-height: inherit;
}

.tg-open > .tg-dropdowarrow i:before {
  content: "\f107";
}

.tg-megamenuslider {
  width: 100%;
  float: left;
}

.tg-megamenuslider .item {
  width: 100%;
  float: left;
}

.tg-megamenuslider.owl-carousel .owl-nav {
  right: 0;
  top: -35px;
  width: auto;
  position: absolute;
}

.tg-navigationarea {
  position: relative;
}

.navbar-collapse.collapse {
  display: block;
  height: auto !important;
  padding-bottom: 0;
  overflow: visible !important;
}

.tg-themetabnav > li {
  width: 100%;
  display: inline-flex;
}

.tg-themetabnav {
  margin: 0;
  padding: 0;
  width: 200px;
  background: #fff;
  position: static;
  max-height: 320px;
  border-radius: 5px;
  overflow: auto;
}

.tg-themetabnav > li > a {
  position: relative;
  z-index: 1;
  color: #333;
  font-size: 14px;
  padding: 5px 15px;
  display: block;
  width: 100%;
}

.tg-fullmegamenu .tg-themetabnav > li > a:hover {
  background-color: #047475;
  color: #fff !important;
}

.tg-themetabnav > li.active > a,
.tg-themetabnav > li:hover > a {
  background-color: #047475;
  color: #fff !important;
}

.mega-menu .tg-themetabcontent {
  display: table;
  table-layout: fixed;
  float: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 0;
  /* min-width: ; */
  width: 70%;
  left: 30%;
}

/* .mega-menu .tab-content .tab-pane {
    height: 100vh;
    padding: 0.5rem;
} */

.tg-themetabnav > li:hover > a:after {
  right: 10px;
  content: "\f105";
  position: absolute;
  font-size: inherit;
  line-height: inherit;
  font-family: "FontAwesome";
}

.menu_without_img {
  padding: 15px;
}

.menu_without_img h4 {
  color: #fff;
  text-align: center;
  font-size: 16px;
  padding-bottom: 10px;
}

.menu_without_img li a {
  font-size: 13px;
  line-height: 30px;
}

.menu_without_img li a:hover {
  color: #fff !important;
  /* text-decoration: underline; */
}

.mega-menu.tg-fullmegamenu {
  background-color: #047475;
  border-radius: 5px;
}

/*********Universities List Start **********/

.uni_list_wrap {
  background-color: #fff;
  margin: -105px auto 25px auto;
  border-radius: 15px;
  padding: 15px;
  box-shadow: 0px 4px 0px #047475;
  max-width: 90%;
}

.uni_list_wrap h4 {
  color: #fcbd30;
  border-bottom: 1px solid #c1c1c1;
  padding: 0 0 10px 0;
  margin-bottom: 20px;
}

.uni_list_wrap .form-control {
  font-size: 0.8rem;
  margin-bottom: 15px;
}

.uni_list_wrap label {
  line-height: 24px;
}

.orserch {
  padding-left: 55px;
  position: relative;
}

.orserch::before {
  position: absolute;
  content: "";
  left: 2px;
  width: 35px;
  height: 100%;
  background: url(../img/or.png) no-repeat left center;
  background-size: 100%;
}

.unisearchbtn {
  margin: 0 auto;
  margin-bottom: -35px;
  border: 0px;
  display: inherit;
  margin-top: 0px;
  -webkit-border-radius: 5px;
}

.uni_list_wrap .form-group {
  margin-bottom: 0px;
}

.unifilter-page {
  margin-top: 240px;
}

.filter-page__content {
  margin-bottom: 60px;
}

.filter-item-wrapper {
  width: 100%;
  margin-top: 5px;
}

.resultv p {
  font-size: 11px;
  margin: 5px 0 0 0px;
}

.resultv h6 span {
  color: #fcbd30;
}

.page-top select {
  float: right;
  border: none;
  padding: 8px 10px;
  width: 140px;
  background: #fcbd30;
  border-radius: 5px;
}

.uni-item-list {
  background-color: #fff;
  overflow: hidden;
  font-size: 12px;
  box-shadow: 0 0 10px #ccc;
  border-radius: 10px;
  margin: 30px 0;
  display: flex;
  align-items: stretch;
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.uni-item-list:hover {
  box-shadow: 0 0 10px #444;
}

.uni-item-list .item-media {
  position: relative;
  width: 28%;
  padding: 10px;
  overflow: hidden;
}

.uni-item-list .item-body {
  position: relative;
  padding: 10px 15px 10px 5px;
  width: 49%;
  overflow: hidden;
  overflow-x: hidden;
  overflow-x: auto;
}

.uni-item-list .item-title h5 {
  font-weight: 600;
  color: #444;
}

.uni-item-list .item-btn-more {
  position: relative;
  padding: 0 30px;
  width: 25%;
  text-align: right;
  align-self: stretch;
  background: #0b264b;
  display: flex;
  align-items: center;
}

.uni-item-list .item-btn-more a {
  background-color: #047475;
  width: 165px;
  border-radius: 5px;
  padding: 10px;
  display: block;
  margin: 8px 2px;
  color: #fff;
  text-align: center;
  height: 30px;
  box-sizing: border-box;
  line-height: 30px;
  padding: 0px;
}

.uni-item-list .item-btn-more a:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.uni-item-list .item-btn-more a.download {
  background-color: #fbb415;
  width: 165px;
  border-radius: 5px;
  padding: 0;
  display: block;
  /* margin: 8px 3px 8px 0px; */
  color: #111;
  text-align: center;
  line-height: 30px;
}

.uni-item-list .item-btn-more a.download i {
  float: right;
  padding: 0 7px;
  background: rgba(0, 0, 0, 0.2);
  line-height: 30px;
}

.uni-item-list .item-btn-more a.download img {
  width: 10px;
}

.downloadwithicon {
  background-color: #fbb415;
  width: 165px;
  border-radius: 5px;
  padding: 0;
  display: block;
  margin: 8px 0;
  color: #111;
  text-align: center;
  line-height: 30px;
  border: 0px;
}

.downloadwithicon i {
  float: right;
  padding: 0 7px;
  background: rgba(0, 0, 0, 0.2);
  line-height: 30px;
}

.downloadwithicon img {
  width: 10px;
}

.uni-item-list .item-btn-more:before {
  position: absolute;
  content: "";
  left: -60px;
  top: 0;
  bottom: 0;
  border-top: 250px solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 60px solid #0b264b;
}

.uni-item-list .item-body .item-address {
  font-size: 15px;
  color: #333;
  margin-top: 10px;
}

.uni-item-list .item-abouttxt {
  color: #666;
  padding: 10px 0 0 0;
  font-size: 10px;
}

.uni-item-list .item-abouttxt span {
  font-size: 10px;
  color: #555;
  font-weight: 500;
}

.uni-item-list .item-abouttxt span.yellow {
  color: #fcbd30;
}

.myContainer {
  text-transform: lowercase;
}

.uni-item-list .item-abouttxt > table {
  width: 95%;
}

.uni-item-list .item-abouttxt img {
  width: 30px;
  height: auto;
}

.uni-item-list .item-body .item-footer {
  margin-top: 12px;
  overflow: hidden;
}

.uni-item-list .item-body .item-hotel-star {
  font-size: 11px;
  color: #fcbd30;
  display: inline-block;
}

.uni-item-list .item-body .item-hotel-star span {
  font-size: 10px;
  color: #b1b1b1;
  font-weight: bold;
}

.page-sidebar .widget {
  background: #fbb415;
  border-radius: 10px;
}

.page-sidebar .widget h5 {
  padding: 8px 12px;
  font-size: 15px;
  cursor: pointer;
}

.page-sidebar .widget h5 i {
  float: right;
}

.page-sidebar .widget h5 span {
  border-bottom: 2px solid #333;
  display: block;
  padding-bottom: 5px;
}

.page-sidebar .widget ul {
  background: #eaeaea;
  padding: 20px 10px;
  border-radius: 0 0 5px 5px;
  margin-bottom: 20px;
}

.page-sidebar .widget ul.clear-filter li {
  display: inline-block;
  border: 1px solid #40a944;
  border-radius: 4px;
  padding: 3px 6px;
  font-size: 10px;
  margin: 4px;
  color: #40a944;
}

.page-sidebar .widget ul.clear-filter li i {
  color: #fd0707;
  font-size: 12px;
  margin-right: -3px;
}

/* .ad-slider {
  width: 90%;
  margin: 0 auto;
} */

.ad-slider .slick-track {
  margin-left: 0px;
}

.ad-slider .slick-prev,
.ad-slider .slick-next {
  background: #047475;
  border-radius: 50%;
  margin: 0 -44px;
  width: 30px;
  height: 30px;
}

.ad-slider .slick-prev:hover,
.ad-slider .slick-next:hover {
  background-color: #0b264b;
}

.ad-slider .slick-prev:before,
.ad-slider .slick-next:before {
  font-size: 20px;
  padding-left: 3px;
  padding-top: 1px;
  color: #fff;
}

.ad-slider .slick-prev:before {
  padding-right: 6px;
}

/*********Universities List End **********/

/*********Universities Details Start **********/

.uni-details .wrapbx {
  position: relative;
}

.uni-details .content {
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  right: 0;
  color: #fff;
  background: #093f3f87;
  height: 100%;
}

.uni-details .content h2 {
  color: #fcbd30;
}

.uni-details .content .sliderH1 {
  color: #fcbd30;
  font-weight: 600;
  width: 100%;
  font-size: 2rem;
}
.countrySliderH1 {
  color: #fff;
  font-weight: 600;
  width: 100%;
  font-size: 2rem;
}
.uniDetailsH2 {
  font-size: 1.5rem !important;
  font-weight: 500;
}
.similarUniH2 {
  font-size: 1rem !important;
}
.uni_search_title {
  font-size: 1.5rem !important;
  color: #fcbd30;
  border-bottom: 1px solid #c1c1c1;
  padding: 0 0 10px 0;
  margin-bottom: 20px;
}
.uni_count_text {
  font-size: 1rem;
}
.uni_count_text span {
  color: #fcbd30;
}
@media only screen and (max-width: 991.98px) {
  .uni-details .content .sliderH1,
  .countrySliderH1 {
    font-size: 1.75rem;
  }
  .uniDetailsH2 {
    font-size: 1.25rem !important;
  }
  .uni_search_title {
    font-size: 1.25rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .uni-details .content h2,
  .countrySliderH1 {
    font-size: 18px;
  }
  .uniDetailsH2 {
    font-size: 16px !important;
  }
  /* .counsellorBox1 {
    display: unset;
  } */
  .itemslider img {
    height: 300px !important;
  }
  .counsellor-section {
    width: 100% !important;
    /* border: 1px solid red; */
  }
  .banner-section,
  .banner-section1 {
    display: none;
  }

  .tinyEditorClass {
    max-width: "100%";
    overflow-y: hidden;
    overflow-x: scroll;
  }
}

.uni-details .content .textwrp {
  width: 75%;
  margin: 0 auto;
}

.uni-details .content .textwrp.country h2 {
  color: #fff;
}

.uni-details .content .textwrp p {
  font-size: 18px;
  width: 40%;
}

.uni-details .content .textwrp.country p {
  font-size: 20px;
  width: 100%;
}

.uni-details .content .btncwrp {
  margin-top: 60px;
}

.uni-details .content .btncwrp .awe-btnbook {
  border: 3px solid #fcbd30;
  color: #fcbd30;
  margin-right: 10px;
  font-size: 14px;
  padding: 10px 0px;
  width: 160px;
  display: inline-block;
  border-radius: 5px;
  box-sizing: border-box;
}

.uni-details .content .btncwrp .awe-btnbook:hover {
  border: 3px solid #fcbd30;
  background-color: #fcbd30;
  color: #fff;
}

.uni-details .content .btncwrp .awe-btnbook.counselling {
  border: 0px;
  background: rgb(14, 40, 70, 1);
  background: -moz-linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#0e2846", endColorstr="#127b7e", GradientType=1);
  color: #fff;
}

.uni-details .content .btncwrp .awe-btnbook.counselling:hover {
  border: 0px;
  background: rgb(14, 40, 70, 1);
  background: -moz-linear-gradient(
    180deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#0e2846", endColorstr="#127b7e", GradientType=1);
  color: #fff;
}

.wishlisttop {
  position: absolute;
  top: 10px;
  right: 0;
  width: 140px;
  z-index: 1;
}

.wishlisttop ul li {
  display: inline-block;
}

.starbtnsite {
  position: absolute;
  bottom: 10px;
  right: 45px;
  z-index: 1;
  width: 300px;
  text-align: right;
}

.starbtnsite .wishlist {
  font-size: 24px;
  font-weight: 600;
  color: #fcbd30;
}

.starbtnsite a.review {
  color: #fff;
}

.starbtnsite a.review:hover {
  color: #fff;
}

.starbtnsite .site {
  background-color: #fbb415;
  border-radius: 5px;
  font-size: 14px;
  padding: 4px;
  margin: 3px;
  display: inline-block;
  border-radius: 5px;
  box-sizing: border-box;
}

.starbtnsite .site.download {
  background-color: #fbb415;
  border-radius: 5px;
  padding: 0 0 0 10px;
  margin: 8px 0;
  color: #111;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
}

.starbtnsite .site.download i {
  float: right;
  padding: 0 7px;
  background: #047475;
  line-height: 30px;
  margin-left: 5px;
  border-radius: 0 5px 5px 0;
}

.starbtnsite .site.download img {
  width: 10px;
}

.navsecond {
  background: rgb(4, 116, 117);
  background: -moz-linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  background: -webkit-linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  background: linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#047475", endColorstr="#01132f", GradientType=1);
  color: #fff;
  margin-top: -5px;
}

.nav2 li {
  display: inline-block;
}

.nav2 li a {
  color: #fff;
  display: block;
  font-size: 15px;
  padding: 12px 0px;
  margin: 0 10px;
  position: relative;
  font-weight: 600;
}

.nav2 li a:before {
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 0%;
  height: 2px;
  background-color: #fcbd30;
  content: "";
  transition: all 0.3s ease-in-out;
}

.nav2 li a:hover:before {
  width: 70%;
  transition: all 0.3s ease-in-out;
}

.nav2 li.active a:before {
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 70%;
  height: 2px;
  background-color: #fcbd30;
  content: "";
}

.appfees {
  color: #fff;
  display: block;
  font-size: 15px;
  padding: 12px 0px;
  margin: 0 10px;
  position: relative;
  font-weight: bold;
}

.appfees span {
  color: #fcbd30;
}

.shade1 {
  box-shadow: 0 0 6px #ccc;
  margin-bottom: 30px;
}

.about-uni-text {
  padding: 30px 30px 30px 60px;
}

.about-uni-text h5 {
  color: #fcbd30;
  font-weight: bold;
  padding-bottom: 0px;
  position: relative;
}

.about-uni-text h5 i {
  color: #047475;
  font-size: 11px;
}

/* .about-uni-text h5::before{ position: absolute; content: ''; background: url(../img/university-icon.svg) no-repeat; width: 50px; height: 50px; top:-10px; left: -50px; background-size: 50px;} */

.about-uni-text p {
  font-size: 14px;
}

.about-uni-text a {
  color: #fcbd30 !important;
}

.course-offer-uni {
  padding: 30px 15px;
}

.lavelbox {
  padding-bottom: 15px;
}

.lavelbox input[type="radio"] {
  margin-left: 10px;
  vertical-align: middle;
}

.bggreadent {
  cursor: pointer;
}

.studywrp .bgstudy,
.bggreadent {
  margin-right: 0px;
  background: rgb(4, 116, 117);
  background: -moz-linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  background: -webkit-linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  background: linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#047475", endColorstr="#01132f", GradientType=1);
  min-height: 50px;
}
.removeMinHeight {
  min-height: unset !important;
}

.bggreadent-rev {
  background: rgba(1, 19, 47, 1);
  background: -moz-linear-gradient(
    77deg,
    rgba(1, 19, 47, 1) 0%,
    rgba(4, 116, 117, 1) 100%
  );
  background: -webkit-linear-gradient(
    77deg,
    rgba(1, 19, 47, 1) 0%,
    rgba(4, 116, 117, 1) 100%
  );
  background: linear-gradient(
    77deg,
    rgba(1, 19, 47, 1) 0%,
    rgba(4, 116, 117, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#01132f", endColorstr="#047475", GradientType=1);
}

.detailstudy {
  padding: 20px 10px;
  min-height: 200px;
}

.detailstudy table tr td {
  font-size: 11px;
}

.detailstudy table tr td span {
  font-size: 10px;
}

.detailstudy table tr td img {
  padding: 0 4px;
}

.detailstudy .eligibility {
  margin-top: -2px;
}

.examscore,
.option {
  font-size: 10px;
  padding: 15px 0 0;
}

.option span {
  background-color: #fcbd30;
  display: inline-block;
  border-radius: 5px 0 0 5px;
  padding: 2px 10px;
  position: relative;
}

.option span:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  border-top: 10px solid transparent;
  border-right: 6px solid #fff;
  border-bottom: 10px solid transparent;
}

.option p {
  display: inline;
}

.color-green {
  color: #047475;
}

.color-yellow {
  color: #fcbd30;
}

a.checkbtn {
  background-color: #fcbd30;
  display: inline-block;
  border-radius: 5px;
  padding: 2px 10px;
  font-size: 10px;
  color: #000;
}

a.checkbtn:hover {
  background-color: #e2a522;
}

.bgstudy {
  padding: 10px;
}

.bgstudy h6 {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
}

.bgstudy h5 {
  font-size: 14px;
  color: #fff;
  text-align: right;
  font-weight: 600;
}

.bgstudy h5 span,
.yellow {
  color: #fcbd30;
}

.about-uni {
  padding: 20px;
}

.about-unidetail {
  padding: 15px;
}

.about-unidetail .tableformt {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.about-unidetail .tableformt li {
  background-color: #eeeeee;
  margin: 5px;
  padding: 10px;
  font-size: 11px;
}

.about-unidetail .tableformt li:nth-child(2n-1) {
  width: 80%;
  font-weight: 600;
}

.about-unidetail .tableformt li:nth-child(2n) {
  width: calc(100% - 83%);
}

.acception-uni {
  padding: 30px;
}

.acception-uni .accept1 {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.acception-uni .accept1 .secaccept {
  width: 31%;
  display: table;
  padding: 15px;
  box-sizing: border-box;
}

.acception-uni .accept1 .secaccept > * {
  display: table-cell;
  vertical-align: middle;
}

.acception-uni .accept1 .secaccept img {
  height: 65px;
}

.acception-uni .accept1 .secaccept p {
  width: 100%;
  padding-left: 15px;
}

.acception-uni .accept1.accept2 {
  justify-content: space-around;
  padding: 0 5%;
}

.testscore {
  padding: 30px 0 0 0;
}

.nav3 {
  border-bottom: 1px solid #fcbd30;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.nav3 ul li {
  display: inline-block;
}

.nav3 ul li a {
  color: #047475;
  border: 1px solid #047475;
  padding: 2px 12px;
  font-size: 11px;
  border-radius: 4px;
  margin: 0 0 0 5px;
  font-weight: 600;
}

.nav3 ul li a:hover,
.nav3 ul li.active a {
  color: #333;
  border: 1px solid #fcbd30;
  background-color: #fcbd30;
}

.ranking ul {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.rankuni {
  padding: 10px;
}

.ranking ul li {
  text-align: center;
  display: inline-block;
  width: 20%;
  margin: 10px 0;
}

.rankuni .icon-circle {
  width: 85px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}

.rankuni .contnt {
  display: inline-block;
  padding-top: 10px;
  width: calc(100% - 100px);
}

.rankuni .contnt div {
  display: inline-block;
  padding-left: 20px;
}

.studentsay {
  padding: 30px 60px;
  margin-bottom: 30px;
}

.say-category {
  background: #fff;
  box-sizing: border-box;
  text-align: center;
  border-radius: 5px;
  padding: 20px;
  margin: 12px 20px;
}

.say-category img {
  height: 70px;
  width: auto !important;
  margin: 0 auto;
}

.say-category h6 {
  margin-bottom: 5px;
  padding: 15px;
  padding-bottom: 0px;
}

.loactiontops {
  padding: 30px;
}

.loactiontops h6 {
  color: #888;
  padding-bottom: 10px;
}

.loactiontops h5:before {
  display: none;
}

.loactiontops h5 {
  font-size: 16px;
}

.loactiontops h5 img {
  float: left;
  width: 42px;
  padding-right: 10px;
}

.loactiontops h5 font {
  font-size: 9px;
  display: block;
  color: #999 !important;
}

.readbtn {
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 16px;
  color: #fff !important;
}

.location-uni {
  padding: 10px;
  border: 1px solid #888;
}

.location-uni iframe {
  width: 100%;
  height: 300px;
}

.__location iframe {
  height: 390px !important;
}

.__overlay {
  width: 94.5%;
  height: 100%;
  background-color: rgba(128, 125, 125, 0.6588235294117647);
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

._youtube {
  z-index: 100;
  font-size: 50px !important;
  color: #ff0000;
  /* margin-top: 23rem; */
  opacity: 0.6;
}

.location-uni iframe .place-card {
  display: none !important;
}

.college-card .wrap {
  border: 1px solid #ececec;
  position: relative;
  box-shadow: 0 0 3px #ccc;
}

.college-card .wrap:hover {
  box-shadow: 0 0 3px rgb(163, 163, 163);
}

.college-card .wrap .top-section {
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.college-card .wrap .top-section .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  top: 0;
  opacity: 0.4;
}

.college-card .wrap .top-section .rating-section {
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px;
  width: 100%;
}

.college-card .wrap .top-section .rating-section .follow-btn {
  padding: 2px 8px;
  border-radius: 3px;
  border: 1px solid #fcbd30;
  width: 60px;
  height: 20px;
  font-size: 8px;
  color: #fcbd30;
}

.college-card .wrap .top-section .rating-section .follow-btn:hover {
  color: #fff;
  border-color: #fff;
}

.college-card .wrap .top-section .rating-section .follow-btn .icon svg {
  height: 9px;
  width: 9px;
  fill: #fcbd30;
  margin-right: 0px !important;
}

.college-card .wrap .top-section .rating-section .follow-btn:hover .icon svg {
  fill: #fff !important;
}

.college-card .wrap .mid-section {
  position: absolute;
  width: 100%;
  bottom: 70px;
}

.college-card .wrap .mid-section .college-info {
  display: block;
  width: 100%;
}

.college-card .wrap .mid-section .college-info h6 {
  font-size: 13px;
  font-weight: bold;
}

.college-card .wrap .mid-section .college-info .location {
  font-size: 10px;
}

.college-card .wrap .mid-section .college-info .location .icon {
  height: 10px;
  width: 12px;
  font-size: 12px;
  line-height: 20px;
}

.college-card .wrap .mid-section .college-info .location .icon svg {
  width: 100%;
}

.college-card .wrap .bottom-section {
  height: 70px;
  position: relative;
}

.college-card .wrap .bottom-section .info .course-name {
  color: #555;
  font-size: 10px;
  display: block;
}

.college-card .download-btn {
  position: absolute;
  right: 8px;
  top: 10px;
}

.smdbtn {
  font-size: 9px;
  width: 150px;
  font-weight: 500;
}

.smdbtn i {
  background-color: #047475;
  border-radius: 0 5px 5px 0;
}

.shade2 {
  box-shadow: 0 0 5px #ccc;
  margin-bottom: 20px;
}

.radius5 {
  border-radius: 5px;
}

.simple {
  background-color: #fcbd30;
  padding: 5px 20px;
  font-size: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.simple:hover {
  background-color: #e7a517;
}

.headgreenbg {
  border-radius: 5px 5px 0 0;
  background-color: #047475;
  font-size: 14px;
  color: #fff;
  padding: 15px 12px !important;
}

.view_comment {
  color: #105665 !important;
}

.view_comment:hover {
  text-decoration: underline !important;
}

.reply_comment {
  color: #fbb415 !important;
}

.reply_comment:hover {
  text-decoration: underline !important;
}

.comment_user {
  width: 50px !important;
  height: auto !important;
}

.singleslider .offers-item {
  margin: 0px;
}

.singleslider .offers-item:before {
  display: none;
}

.singleslider .offers-item .content-box::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 20px;
  background-color: #047475;
  transform: skew(0deg, -5deg);
  top: -2px;
  left: 0;
}

.singleslider .offers-item .content-box {
  padding: 10px;
  margin-top: -23px;
  min-height: 115px;
}

.singleslider .offers-item .mt_btn_yellow {
  padding: 3px 4px;
  font-size: 10px;
}

.singleslider .offers-item h3 {
  width: 61%;
  font-size: 11px;
}

.singleslider .offers-item h3 span {
  display: block;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;
}

.singleslider .offers-item:nth-child(even),
.singleslider .offers-item:nth-child(even) .content-box:before {
  background-color: #047475;
}

.wfollow {
  font-size: 9px;
}

.wfollow svg {
  stroke: #000 !important;
}

.wshare {
  background: #007684;
  color: #fff;
}

.universitein li {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  column-gap: 10px;
}

.universitein li div:nth-child(1) {
  width: 22%;
}

.universitein li div:nth-child(2) {
  width: 70%;
}

.universitein li div:nth-child(3) {
  width: 20%;
}

.universitein li .imglogo {
  width: 23%;
  text-align: center;
}

.universitein li img {
  width: 100%;
}

.universitein li p {
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.unitextwidth1 {
  width: 55%;
  padding: 0px 6px;
}

.unitextwidth2 {
  width: 22%;
}

.universitein li a {
  background-color: #fcbd30;
  padding: 1px 5px;
  border-radius: 2px;
  text-align: center;
  font-size: 8px;
  font-weight: 600;
  color: #111;
}

/*********Universities Details End **********/

/********** Program *************/

.program-search {
  padding: 15px;
}

.program-search .form-control {
  border: 1px solid #fcbd30;
  font-size: 12px;
  border-radius: 10px 0 0 10px;
}

.program-search .input-group-append .btn {
  background-color: #fcbd30;
  border: none;
}

.counsellor ul li ul {
  padding: 0 !important;
  margin: 0px !important;
  background-color: #fff !important;
}

.counsellor > ul > li {
  display: flex;
  width: 100%;
  padding-bottom: 10px;
}

.counsellor > ul > li img {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.counsellor h5 {
  position: relative;
}

.counsellor h5:after {
  position: absolute;
  content: "";
  width: 30%;
  height: 2px;
  background-color: #fff;
  left: 12px;
  bottom: 10px;
}

.vertical_align {
  vertical-align: middle;
}

.f-30 {
  font-size: 17px !important;
  font-weight: 600 !important;
}

.vertical_align_checkbox {
  vertical-align: middle;
}

.serchsuggest {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
}

.serchsuggest p {
  width: 130px;
  padding-top: 5px;
  min-width: 80px;
}

.serchsuggest ul li {
  margin: 4px;
  display: inline-flex;
}

.serchsuggest ul li a {
  padding: 3px 10px;
  font-size: 10px;
  border: 1px solid #eee;
  border-radius: 5px;
  min-width: 100px;
  text-align: center;
}

.serchsuggest ul li a:hover {
  border: 1px solid #ccc;
  background-color: #eee;
}

/********** Review **************/

.reviewstops {
  padding: 15px;
}

.reviews_list {
  display: inline-flex;
  width: 100%;
  /* justify-content: space-between; */
}

.reviews_list_new {
  display: inline-flex;
  width: 100%;
  /* justify-content: space-between; */
}

.reviews_list > img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
}

.reviews_list_new > img {
  width: 40px !important;
  height: 40px !important;
  margin-right: 10px;
  border-radius: 50%;
}

.reviews_item h6 {
  color: #333;
  /* margin-bottom: 10px; */
  text-transform: capitalize;
}

.reviews_item h6 small {
  color: #333;
  font-size: 10px;
}

.reviews_item h6 font {
  float: right;
  color: #fcbd30;
  font-size: 16px;
}

.reviews_item h6 p {
  float: right;
  color: #fcbd30;
  font-size: 16px;
}

.reviews_item {
  width: 100%;
}

.reviews_item p a {
  padding: 3px 10px;
  font-size: 10px;
  border: 1px solid rgb(226, 226, 226);
  display: inline-block;
  border-radius: 5px;
  min-width: 50px;
  text-align: center;
  margin-right: 10px;
}

.reviews_item p a:hover {
  border: 1px solid #ccc;
  background-color: #eee;
}

.reviews_item p a.active {
  color: #fcbd30;
}

.modal-review img {
  max-width: 250px;
  width: 50%;
  margin-top: -40px;
}

.modal-review .modal-header {
  border: 0px;
}

.modal-review .form-group {
  padding: 15px 40px;
}

.modal-review .form-group .form-control {
  font-size: 12px;
}

/****** Rating Widget *****/

.rating {
  border: none;
  width: 165px;
  margin: 10px auto;
}

.rating > input {
  display: none;
}

.rating > label:before {
  margin: 5px;
  font-size: 1.5rem;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f006";
}

.rating > .half:before {
  position: absolute;
}

.rating > label {
  color: #ddd;
  float: right;
}

.rating > input:checked ~ .half:before {
  content: "\f089";
}

.rating > input:checked ~ label:before {
  content: "\f005";
}

.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #fcbd30;
}

/*********** Blog ************/

.blog-item {
  position: relative;
  margin: 0 0 25px 0;
  background-color: #fff;
  box-shadow: 0 0 5px #ccc;
  width: 100%;
  border-radius: 15px 15px 0 0;
}

.blog-item .content-box {
  padding: 0 10px 10px 10px;
  text-align: left !important;
}

.blog-item hr {
  border-color: #fcbd30;
  margin: 10px 0 8px 0;
}

.blog-item .content-box p {
  padding-right: 0px;
  font-size: 0.8rem;
}

.blog-item img {
  width: 100%;
  margin-bottom: 4px;
  cursor: pointer;
}

.blog-item .iconscomments {
  float: left;
  width: calc(100% - 40px);
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
}

.blog-item .iconscomments.country {
  float: none;
  width: 100%;
}

.blog-item .iconscomments li a {
  font-size: 15px;
}

.blog-content p {
  margin-bottom: 1rem;
  font-size: 15px !important;
}

.blog-content a {
  color: #007bff;
}

.blog-item .content-box a.btnarrow {
  box-shadow: 3px 0 3px #ccc;
  margin: 0 auto;
  padding: 5px 11px;
  width: 31px;
  height: 31px;
  float: right;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  background-color: #fcbd30;
  margin-top: 15px;
}

.blog-item .content-box a.btnarrow:hover {
  background-color: #333;
  color: #fff;
}

.counsellor.blogrecent > ul > li {
  display: flex;
  width: 100%;
  padding-bottom: 10px;
}

.f-15 {
  font-size: 15px;
}

.landing_icon_ {
  width: 12%;
  padding-right: 6px;
}

.landing_icon {
  width: 12%;
  padding-right: 6px;
}

.counsellor.blogrecent > ul > li img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.counsellor.blogrecent h5::after {
  width: 30px;
}

.blogsearch {
  border-radius: 10px;
  border: 1px solid #047475;
  box-shadow: 0 0 10px #ccc;
  font-size: 12px;
}

.blogsearch:focus {
  border: 1px solid #047475;
}

.ads-blog {
  margin-bottom: 25px;
  box-shadow: 0 8px 4px #ccc;
  text-align: center;
  background-color: #fff !important;
}

/********* Blog Details ********/

.blog-details .blog-item {
  border-radius: 0px;
}

.blog-details h6.bloghead2 {
  color: #000;
  font-size: 15px;
}

.blog-details .blog-item figcaption {
  padding: 15px 20px;
}

.blog-details .simplelink,
.blog-details .headblogwrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
}

.blog-details .simplelink a {
  padding: 3px 10px;
  font-size: 10px;
  border: 1px solid #fcbd30;
  display: inline-block;
  border-radius: 5px;
  min-width: 50px;
  text-align: center;
  width: 45%;
  margin: 5px;
}

.blog-details .simplelink a:hover {
  border: 1px solid #ccc;
  background-color: #eee;
}

.shareblog ul li {
  display: inline-block;
  margin: 0 0 0 2px;
}

.shareblog ul li a {
  background-color: #fcbd30;
  color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 25px;
  text-align: center;
  display: block;
}

.shareblog ul li a.fb {
  background-color: #3b5998;
}

.shareblog ul li a.insta {
  background-color: #3f729b;
}

.shareblog ul li a.in {
  background-color: #0a66c2;
}

.shareblog ul li a:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/********* Counsellor List **********/

.simpleslider_head {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
}

.simpleslider_head p {
  width: 45%;
}

.simpleslider_head .textwrp {
  padding-left: 45px;
}

.skew-tabs {
  text-align: center;
  margin-top: -5px;
}

.skew-tabs ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  transform: skew(-35deg);
}

.skew-tabs ul li {
  background: #acacac;
  float: left;
  color: #fff;
  font-weight: bolder;
  transition: all 0.3s linear;
  min-width: 160px;
  position: relative;
  margin: 0 10px;
}

.skew-tabs ul li span {
  position: absolute;
  left: -30px;
  background: #fff;
  height: 46px;
  display: flex;
  top: 0;
  align-items: center;
  padding: 0 12px;
  border-radius: 0 19px;
  box-shadow: inset 0 0 10px #ccc;
}

.skew-tabs ul li:last-child span {
  left: auto;
  right: -30px;
}

.skew-tabs ul li.active,
.skew-tabs ul li:hover {
  background-color: #fcbd30;
  color: #333;
}

.skew-tabs ul li.active span svg path,
.skew-tabs ul li:hover span svg path {
  fill: #fcbd30 !important;
}

.skew-tabs ul li a {
  display: block;
  padding: 1em 2em;
  color: inherit;
  text-decoration: none;
  transform: skew(35deg);
}

.skew-tabs2 {
  text-align: center;
  margin-top: -5px;
}

.skew-tabs2 ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  transform: skew(-35deg);
}

.skew-tabs2 ul li {
  float: left;
  color: #fff;
  font-weight: bolder;
  transition: all 0.3s linear;
  min-width: 160px;
  position: relative;
  margin: 0 10px;
}

.skew-tabs2 ul li a {
  display: block;
  background: #acacac;
  padding: 1em 2em;
  color: inherit;
  text-decoration: none;
  position: relative;
  /* transform: skew(35deg); */
}

.skew-tabs2 ul li a p {
  transform: skew(35deg);
  display: block;
}

.skew-tabs2 ul li span {
  position: absolute;
  left: -57px;
  background: #fff;
  height: 46px;
  display: flex;
  top: -13px;
  align-items: center;
  padding: 0 12px;
  border-radius: 0 19px;
  box-shadow: inset 0 0 10px #ccc;
}

.skew-tabs2 ul li:last-child span {
  left: auto;
  right: -57px;
}

.skew-tabs2 ul li a.active,
.skew-tabs2 ul li a:hover {
  background-color: #fcbd30;
  color: #333;
}

.skew-tabs2 ul li a.active span svg path,
.skew-tabs2 ul li:hover span svg path {
  fill: #fcbd30 !important;
}

.pricedegree {
  float: right;
  font-size: 11px;
}

.pricedegree p {
  font-size: 10px;
}

.pricedegree span {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

.pricedegree span img {
  width: 20px;
  height: auto;
}

.counsellorlist .uni-item-list .item-media {
  width: 17%;
  padding: 0px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.counsellorlist .uni-item-list .item-body {
  width: 59%;
  padding-left: 15px;
}

.counsellorlist .item-abouttxt {
  width: 100%;
}

.counsellorlist .uni-item-list .item-media button {
  border: 0px;
  background-color: transparent;
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.counsellorlist .uni-item-list .item-media button:hover {
  transform: scale(0.9);
}

.online,
.offline {
  font-size: 10px;
  font-weight: normal;
  display: inline-block;
  padding-left: 10px;
}

.online i {
  color: green;
}

.offline i {
  color: red;
}

/*********** Counsellor Details **************/

.counsellor_details .uni-item-list {
  margin-top: 0px;
  box-shadow: none !important;
}

.counsellor_details .shade1 {
  border-radius: 10px;
}

.counsellorlist.counsellor_details .uni-item-list .item-media {
  width: 285px;
}

.counsellorlist.counsellor_details .uni-item-list .item-body {
  width: calc(100% - 25%);
  margin-left: 20px;
  padding: 20px 15px 10px 0;
}

.counsellor_details .uni-item-list .item-body .item-title h3 {
  font-weight: 600;
}

.counsellor_details .uni-item-list {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px;
}

.counsellor_details .item-abouttxt {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0px;
}

.counsellor_details .uni-item-list .item-abouttxt > table {
  width: calc(100% - 200px);
}

.counsellor_details .uni-item-list .item-btn-more {
  width: 170px;
  padding: 0px;
  background: none;
}

.counsellor_details .uni-item-list .item-btn-more::before {
  display: none;
}

.counsellor_details .uni-item-list .item-btn-more a {
  margin: 0px;
  margin-top: 5px;
  font-size: 11px;
}

.counsellor_details .uni-item-list .item-btn-more a:hover {
  background-color: rgba(18, 123, 126, 0.8);
}

.counsellor_details .uni-item-list .item-btn-more a.download:hover {
  background-color: rgba(255, 172, 0, 0.8);
}

.counsellor_details .uni-item-list .item-btn-more a i {
  font-size: 15px;
  margin-right: 5px;
}

.counsellorlist .uni-item-list .item-media {
  border-radius: 10px 10px 10px 0;
}

.counsellor_details.counsellor_details .counsellor > ul > li {
  padding: 7px 0;
}

.counsellor_details .counsellor > ul > li img {
  width: 35px;
  height: 35px;
}

.counsellor_details .feat-inn ul li > i {
  font-size: 10px;
}

.counsellor_details .tabdetails ul {
  padding: 0 20px;
}

.counsellor_details .tabdetails ul li a.nav-link {
  padding: 15px 12px;
  font-size: 13px;
  position: relative;
  background: transparent;
  border: none;
  color: #fff;
  font-weight: 500;
}

.counsellor_details .tabdetails ul li a.nav-link.active:after {
  position: absolute;
  content: "";
  width: 30%;
  height: 1px;
  background-color: #fcbd30;
  left: 12px;
  bottom: 12px;
}

.counsellor_details .tabdetails .tab-content {
  padding: 15px 20px;
}

/******** offer_sholarship ************/

.offer_sholarship .tab-content {
  padding-top: 40px;
}

.offer_sholarship .offers_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.offer_sholarship .offers_list .offers-item {
  width: 30%;
  /* margin: 50px 40px 50px 0px; */
  display: block;
}

.offer_sholarship .offers_list .offers-item::before {
  background-size: 70%;
  right: -50px;
  bottom: -68px;
  top: auto;
}

.offer_sholarship .offers_list .slick-slide::before {
  background-size: 70%;
  right: -50px;
  bottom: -68px;
  top: auto;
}

.offer_sholarship .offers_list .offers-item:nth-child(6n + 1)::before {
  background-image: url(../img/dot_offer-blue.png);
  right: -50px;
  top: -38px;
}

.offer_sholarship .offers_list .offers-item:nth-child(6n + 2)::before {
  background-image: url(../img/dot_offer-yellow.png);
  right: -50px;
  bottom: -68px;
  top: auto;
}

.offer_sholarship .offers_list .offers-item:nth-child(6n + 3)::before {
  background-image: url(../img/dot_offer-bluel.png);
  right: -50px;
  top: -38px;
}

.offer_sholarship .offers_list .offers-item:nth-child(6n + 4)::before {
  background-image: url(../img/dot_offer-bluel.png);
  left: -28px;
  top: -38px;
}

.offer_sholarship .offers_list .offers-item:nth-child(6n + 5)::before {
  background-image: url(../img/dot_offer-yellow.png);
  left: -28px;
  bottom: -68px;
  top: auto;
}

.offer_sholarship .offers_list .offers-item:nth-child(6n + 6)::before {
  background-image: url(../img/dot_offer-blue.png);
  left: -28px;
  top: -38px;
}

.offer_sholarship .offers_list .offers-item:nth-child(2n),
.offer_sholarship .offers_list .offers-item:nth-child(2n) .content-box::before {
  background-color: #047475;
}

.offer_sholarship .offers_list .offers-item:nth-child(3n + 2),
.offer_sholarship
  .offers_list
  .offers-item:nth-child(3n + 2)
  .content-box::before {
  background-color: #0b264b;
}

.offer_sholarship .offers_list .offers-item .content-box {
  align-items: flex-end;
  margin-top: -20px;
}

.offer_sholarship .offers_list .offers-item .content-box::before {
  transform: skew(0deg, 4deg);
  height: 46px;
  top: -24px;
}

.offer_sholarship .offers_list .offers-item h3 {
  order: -1;
  position: relative;
  z-index: 2;
  margin-top: -25px;
  font-size: 18px;
  text-align: left;
  font-size: 18px;
}

.offer_sholarship .offers_list .offers-item h3 span {
  font-size: 32px;
  line-height: 32px;
}

.offer_sholarship .offers_list .offers-item .content-box a.tnc {
  color: #fff;
  display: block;
}

.offer_sholarship .bttns {
  text-align: right;
  padding-top: 5px;
}

.offer_sholarship .offers_list .offers-item .mt_btn_yellow {
  font-size: 13px;
}

.offer_detailpopup .off-detail-item {
  border-radius: 10px 10px 0 0;
  /* overflow: hidden; */
  text-align: center;
  position: relative;
  margin: 0px;
  background-color: #047475;
}

.offer_detailpopup .off-detail-item h3 {
  color: #f0f0f0;
  font-weight: 400;
  text-align: left;
  position: relative;
  font-size: 16px;
}

.service-funding {
  width: 30px;
}

.no-image {
  vertical-align: middle;
  border-style: none;
  width: auto !important;
  max-height: 160px;
  height: inherit !important;
  text-align: center;
  margin: 0px auto;
}

.offer_detailpopup .off-detail-item h3 span {
  display: block;
  font-size: 26px;
  font-weight: 800;
  line-height: 26px;
}

.__hyperlinks {
  background-color: transparent !important;
  text-decoration: none !important;
}

.offer_detailpopup .off-detail-item .bttns {
  position: relative;
}

.offer_detailpopup .off-detail-item .bttns a {
  color: #fff;
}

.offer_detailpopup .off-detail-item .details-box {
  background-color: #fff;
  padding: 15px;
}

.offer_detailpopup .off-detail-item .details-box p {
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
  color: #222;
}

.offer_detailpopup .off-detail-item .feat-text {
  padding: 0px;
}

.offer_detailpopup .off-detail-item .content-box {
  width: 100%;
  display: flex;
  padding: 15px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  padding-top: 0;
  margin-top: -30px;
}

.offer_detailpopup .off-detail-item .content-box:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 45px;
  background-color: #047475;
  transform: skew(0deg, 4deg);
  top: -10px;
  left: 0;
}

.offer_detailpopup .off-detail-item .applybtn {
  padding: 7px 15px;
  border-radius: 5px;
}

.offer_detailpopup .off-detail-item .applybtn:hover {
  background: rgb(4, 116, 117);
  background: -moz-linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 100%,
    rgba(1, 19, 47, 1) 0%
  );
  background: -webkit-linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 100%,
    rgba(1, 19, 47, 1) 0%
  );
  background: linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 100%,
    rgba(1, 19, 47, 1) 0%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#01132f", endColorstr="#047475", GradientType=1);
}

.offer_detailpopup .off-detail-item button.close {
  position: absolute;
  right: 5px;
  text-shadow: #000;
  font-size: 30px;
}

/****** Sholarship ******/

.sholarship-wrp .s-item {
  border-radius: 0px 0px 0 0;
  text-align: center;
  position: relative;
  width: 30%;
  margin: 50px 40px 50px 0px;
  display: block;
  background-color: #047475;
}

.eligibility_content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.__content_ {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.entry_requirement {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-right: 10px;
}

.read-more-new {
  color: #fcbd30 !important;
  font-weight: 600;
}

.sholarship-wrp .s-item:before {
  width: 78px;
  height: 105px;
  position: absolute;
  content: "";
  background-repeat: no-repeat;
  z-index: -1;
  right: -40px;
  top: -53px;
  background-size: 70%;
}

.sholarship-wrp .s-item h3 {
  color: #f0f0f0;
  font-weight: 400;
  width: 45%;
  text-align: left;
  position: relative;
  font-size: 14px;
}

.sholarship-wrp .s-item h3 span {
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 10px;
  padding-bottom: 10px;
}

.sholarship-wrp .s-item .feat-text {
  padding: 0px;
}

.sholarship-wrp .s-item .content-box {
  width: 100%;
  display: flex;
  padding: 15px;
  box-sizing: border-box;
  justify-content: space-between;
  position: relative;
  padding-top: 0;
  margin-top: -15px;
}

.sholarship-wrp .s-item .content-box:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 60px;
  background-color: #047475;
  transform: skew(0deg, -6deg);
  top: -30px;
  left: 0;
}

.sholarship-wrp .s-item:nth-child(2n + 1) .content-box:before {
  transform: skew(0deg, 6deg);
}

.sholarship-wrp .offers_list .s-item:nth-child(6n + 1)::before {
  background-image: url(../img/dot_offer-blue.png);
  right: -50px;
  top: -38px;
}

.sholarship-wrp .offers_list .s-item:nth-child(6n + 2)::before {
  background-image: url(../img/dot_offer-yellow.png);
  right: -50px;
  bottom: -68px;
  top: auto;
}

.sholarship-wrp .offers_list .s-item:nth-child(6n + 3)::before {
  background-image: url(../img/dot_offer-bluel.png);
  right: -50px;
  top: -38px;
}

.sholarship-wrp .offers_list .s-item:nth-child(6n + 4)::before {
  background-image: url(../img/dot_offer-bluel.png);
  left: -28px;
  top: -38px;
}

.sholarship-wrp .offers_list .s-item:nth-child(6n + 5)::before {
  background-image: url(../img/dot_offer-yellow.png);
  left: -28px;
  bottom: -68px;
  top: auto;
}

.sholarship-wrp .offers_list .s-item:nth-child(6n + 6)::before {
  background-image: url(../img/dot_offer-blue.png);
  left: -28px;
  top: -38px;
}

.sholarship-wrp .s-item .mt_btn_yellow:before,
.sholarship-wrp .s-item .mt_btn_yellow:after {
  border-radius: 0px !important;
}

.sholarship-wrp .s-item .mt_btn_yellow {
  position: relative;
  padding: 10px 26px;
  font-size: 13px;
}

.sholarship-wrp .s-item:nth-child(even),
.sholarship-wrp .s-item:nth-child(even) .content-box:before {
  background-color: #0b264b;
}

.sholarship-wrp .content-head {
  padding: 5px 10px;
  text-align: left;
  position: relative;
}

.sholarship-wrp .content-head .hadsec {
  width: 100%;
  display: inline-flex;
  align-items: center;
}

.sholarship-wrp .content-head .hadsec img {
  height: 41px;
  padding-right: 10px;
}

.sholarship-wrp .content-head .hadsec h6 {
  color: #fff;
  font-weight: 500;
  font-size: 12px;
}

.sholarship-wrp .content-head p {
  position: absolute;
  right: 10px;
  bottom: 0px;
}

/******** Sholarship Details **********/

.sholarship_details .shade1 {
  padding: 0px;
  border-radius: 4px;
}

.sholarship_details .details-top .topright {
  padding: 20px 15px 20px 0;
}

.sholarship_details .details-top .topright h5 {
  font-weight: 600;
}

.sholarship_details .details-top .topright .fouricon {
  width: 100%;
  margin-top: 15px;
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0 0 15px #dedddd;
  padding: 5px;
}

.__hover {
  font-size: 14px;
}

.__fouricon {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0 0 15px #dedddd;
}

.__mr-10 {
  margin-top: 9px;
}

.icon_color {
  color: #047475;
}

.f-15 {
  font-size: 15px;
}

.sholarship_details .details-top .topright .fouricon p b {
  display: block;
}

.sholarship_details .details-top .topright .fouricon img {
  width: 55px;
  height: auto;
  margin-right: 10px;
}

.sholarship_details .details-bottom {
  padding: 5px 30px 20px 30px;
}

.sholarship_details .details-bottom h5 {
  position: relative;
  margin: 15px 0 5px 0;
  padding: 10px 0;
}

.sholarship_details .details-bottom h5::before {
  position: absolute;
  content: "";
  width: 75px;
  height: 1px;
  background-color: #fcbd30;
  left: 0px;
  bottom: 5px;
}

.sugg-sholar h6 {
  font-size: 11px;
  line-height: 20px;
}

.sholarship_details .details-bottom p {
  line-height: 26px;
}

.sholarship_details .details-bottom ul li {
  list-style: disc;
  margin-left: 15px;
}

/****** Package ***********/

.packagelist {
  margin-top: 55px;
}

.packagelist .item-package .card {
  border-color: #047475;
  border-radius: 40px 0;
  margin: 0 20px;
  position: relative;
  box-shadow: 0 0 10px #ccc;
}

.packagelist .item-package .card .card-header {
  background-color: #047475;
  padding: 30px 15px;
  border-radius: 40px 0 0 0px;
}

.packagelist .item-package .card .card-header h5 {
  color: #fff;
}

.packagelist .item-package .card .card-footer {
  border-radius: 0 0 40px 0;
}

.packagelist .item-package .card .card-body {
  max-height: 370px;
  overflow-y: auto;
  margin-bottom: 8px;
}

.packagelist .item-package .card .card-body ul li {
  border-bottom: 1px dotted #047475;
  padding: 9px 0;
  font-size: 15px;
}

.packagelist .item-package .card .card-body ul li:last-child {
  border: 0px;
}

.packagelist .item-package:nth-child(2) .card {
  border-color: #fcbd30;
}

.packagelist .item-package:nth-child(2) .card .card-header {
  background-color: #fcbd30;
}

.packagelist .item-package:nth-child(2) .card .card-header h5 {
  color: #000;
}

.packagelist .item-package .card .pricebox {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 1px solid #fcbd30;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -33px;
  background: #fff;
  left: 0;
  right: 0;
  flex-wrap: wrap;
  margin: 0 auto;
}

.packagelist .item-package .card .pricebox p {
  font-weight: 500;
  display: block;
  line-height: 14px;
}

/****** Coaching *****/

.choachwhat {
  padding: 20px;
}

.choachwhat .nav {
  justify-content: space-between;
  margin-bottom: 20px;
  border: 0px;
}

.choachwhat .nav .nav-item {
  width: 18%;
}

.choachwhat .nav .nav-link {
  background-color: #eee;
  border-radius: 5px;
  border: 0px;
  text-align: center;
  padding: 10px;
  font-size: 15px;
}

.choachwhat .nav .nav-link.active {
  background-color: #fcbd30;
  box-shadow: 0 5px 3px #d3d3d3;
  font-weight: 500;
}

.choachwhat .tab-content p {
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
}

.choachwhat .item-btn-more {
  justify-content: center;
}

.coach_slider .slick-list .slick-slide {
  padding: 10px;
}

.coach_slider .slick-list {
  margin: 0 20px;
}

.bluedark {
  background-color: #e5f1f1;
}

.bluelight {
  background-color: #f5fbff;
}

.add-detail-item {
  background: linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  padding: 20px;
  border-radius: 16px;
  min-height: 150px;
  display: grid;
  justify-content: center;
  align-items: center;
}
.add-detail-item ul {
  line-height: 37px;
}
.add-detail-item ul li {
  color: #ffffff;
  font-size: 17px;
}
.add-detail-item img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.add-detail-item .c-info-item p {
  line-height: 30px;
}
.add-detail-item .c-info-item span {
  margin-left: 60px;
}

.contact-deatils-address {
  margin-top: 40px;
}
.contact-deatils-address iframe {
  border-radius: 16px;
}

/* Cards */

.postcard {
  flex-wrap: wrap;
  display: flex;
  border-radius: 10px;
  margin: 0;
  overflow: hidden;
  position: relative;
  align-items: center;
}

.postcard .postcard__img {
  width: 100%;
  /* object-fit: cover; */
  position: relative;
  margin: 30px 0;
}

.postcard .postcard__img img {
  border-radius: 10px;
}

.postcard .postcard__text {
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.postcard .postcard__text h4 {
  padding-left: 35px;
}

.postcard__preview-txt {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  line-height: 26px;
}

.postcard__preview-txt > img {
  float: left;
  margin-right: 15px;
  max-height: 120px;
}

.shareblog {
  text-align: end;
  padding-bottom: 15px;
}
.imp-instruction h1 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 12px;
  color: #fcbd30;
}
.imp-instruction h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  color: #fcbd30;
}
.imp-instruction h3 {
  font-size: 26px;
  font-weight: 700;
  line-height: 50px;
  color: #555;
}
.imp-instruction h4 {
  font-size: 22px;
  font-weight: 700;
  line-height: 50px;
  color: #555;
}
.imp-instruction h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 50px;
  color: #555;
}
.imp-instruction h6 {
  font-size: 18px;
  font-weight: 700;
  line-height: 50px;
  color: #555;
}
.imp-instruction ul {
  margin-top: 10px;
}
.imp-instruction ol {
  margin-top: 10px;
}
.imp-instruction ul li {
  line-height: 22px;
  margin-left: 15px;
  padding-left: 2px;
  list-style: disc;
}
.imp-instruction ol li {
  line-height: 22px;
  margin-left: 15px;
  padding-left: 2px;
}
.imp-instruction ol li::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}
.imp-instruction p:not(:first-child) {
  margin-top: 7px;
  margin-bottom: 15px;
}

.readbtn-dynamic {
  border-radius: 5px;
  padding: 4px 0px;
  font-size: 13px;
  color: #124183 !important;
  margin-top: 12px;
  border: unset;
  background: unset;
  display: flex;
  align-items: center;
}
.readbtn-dynamic:hover,
.readbtn-dynamic:active,
.readbtn-dynamic:focus {
  color: #124183 !important;
}
.readbtn-dynamic i {
  font-size: 17px;
  padding: 0px 3px;
}
.service-dropdown-main {
  position: relative;
}
.service-dropdown-main .tg-fullmegamenu {
  width: 220px !important;
  background-color: #fff !important;
}
.service-dropdown {
  /* padding: 10px; */
  /* display: flex;
    flex-wrap: wrap; */
}
.service-dropdown li {
  line-height: 35px !important;
}
.service-dropdown li:hover {
  background-color: #047475;
  color: #fff !important;
}
.service-dropdown li:hover > a {
  color: #fff !important;
}
.service-dropdown li a {
  margin: 20px !important;
  color: black !important;
  font-size: 14px;
}
.skew-tabs-shortlist ul {
  margin: 0 auto;
  text-align: center;
  width: 400px;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  border-radius: 20px;
  display: flex;
}
.skew-tabs-shortlist ul li.active,
.skew-tabs ul li:hover {
  background: linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  ) !important;
  color: #fff;
  border-radius: 20px;
}
.skew-tabs-shortlist ul li {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  line-height: 42px;
  align-items: center;
  border-radius: 20px;
  font-weight: 700;
  letter-spacing: 0.36px;
  color: #222935;
  background-color: #fff;
  cursor: pointer;
}
.skew-tabs-shortlist ul li a {
  font-size: 13px;
}
.skew-tabs-shortlist ul .suggested {
  width: 45% !important;
}
.box-suggested {
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
  border-radius: 4px;
  display: block;
  padding: 15px 0px;
}
.box-suggested .short-list-uni {
  height: auto;
  margin: auto;
}
.box-suggested .item-address {
  font-size: 14px;
  font-weight: 600;
  color: #9d9d9d;
  margin-right: 5px;
}
.box-suggested .c-time {
  font-size: 14px;
  font-weight: 600;
  color: #9d9d9d;
  margin-left: 5px;
}
.bgstudy-short {
  padding: 10px 0px;
}
.bgstudy-short h6 {
  font-size: 18px;
  color: #187273;
  font-weight: 600;
}
.bgstudy-short span {
  font-size: 14px;
  color: #187273;
}
.bg-color-badge {
  background: linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  ) !important;
  color: #fff;
  border-radius: 10px 0px 0px 10px;
  padding: 7px 0px 7px 18px;
}
.bg-color-badge h5 {
  color: #fff;
  font-size: 15px;
  line-height: 24px;
}
.bg-color-badge h5 span {
  color: #fbb415;
}
.bg-light-badge {
  background: #daf2f2;
  padding: 10px;
  border-radius: 10px 0px 0px 10px;
}
.bg-light-badge b {
  color: #000 !important;
}
.shortdetailstudy .option a {
  font-size: 14px;
}
.bg-light-badge img {
  width: 22px;
}
.bg-light-badge .examscore {
  font-size: 14px;
  padding: 15px 0px 0;
  color: #000 !important;
}
.bg-light-badge .icon-info div {
  color: #000 !important;
}
.suggested-option a {
  display: flex;
}
.bgstudy-short {
  min-height: 100px;
}
.bg-light-badge .icon-info {
  margin-left: 5px;
  display: block;
}
.bt-application {
  border-bottom: 2px solid #ccc;
}
.bg-light-badge .last-icon-info {
  padding-right: 10px;
}

/* phone-start */

@media screen and (min-width: 769px) {
  .postcard {
    flex-wrap: inherit;
  }

  .postcard .postcard__title {
    font-size: 2rem;
  }

  .postcard .postcard__img {
    margin-left: 5%;
    transition: transform 0.3s ease;
  }

  .postcard .postcard__text {
    padding: 3rem;
    width: 100%;
  }

  .postcard:nth-child(2n + 0) {
    flex-direction: row;
  }

  .sec_post .postwrap:nth-child(2n + 1) .postcard {
    flex-direction: row-reverse;
  }

  .sec_post .postwrap:nth-child(2n + 0) .postcard .postcard__img {
    margin-right: 5%;
    margin-left: 0px;
  }
}

/* phone-start */

@media screen and (min-width: 1024px) {
  .postcard__text {
    padding: 2rem 3.5rem;
  }
}

/**********Login Page ***********/

.loginwrap .bg-image > h5 {
  padding-bottom: 20px;
}

.loginwrap .bg-image > h6 {
  padding-top: 15px;
}

.bg-grey {
  background-color: #d6d4d2;
}

.loginwrap {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0 10px 20px #ccc;
}

.bg-image {
  background-image: url("../img/loginimg.jpg");
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px;
  min-height: 500px;
}

.bg-image h3 {
  text-align: center;
  padding-bottom: 30px;
}

.bg-image p {
  font-size: 15px;
}

.loginwrap .rightbx h5 {
  padding-bottom: 20px;
}

.loginwrap .rightbx h6 {
  padding-top: 15px;
}

.fields-wrp form .field {
  height: 50px;
  width: 100%;
  margin-top: 20px;
  position: relative;
}

.fields-wrp form .field span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  font-size: 18px;
}

.fields-wrp form .field input,
.fields-wrp form .field select {
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 15px;
  padding-left: 50px;
  border: 1px solid lightgray;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.fields-wrp form .field input:focus,
.fields-wrp form .field input:valid {
  border-color: #fbb415;
}

.fields-wrp form .field .type_file:focus {
  border-color: transparent !important;
}

.choose_file {
  border: 1px solid #fbb415 !important;
}

._mr-37 {
  margin-top: 24px;
}

.fields-wrp form .field label {
  position: absolute;
  top: 50%;
  left: 50px;
  color: #999;
  font-weight: 400;
  font-size: 16px;
  pointer-events: none;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}

.fields-wrp form .field input:focus ~ span,
.fields-wrp form .field input:valid ~ span {
  color: #fbb415;
}

.fields-wrp form .field input:focus ~ label,
.fields-wrp form .field input:valid ~ label {
  top: 0%;
  font-size: 16px;
  left: 20px;
  font-size: 12px;
  padding: 0 3px;
  color: #fbb415;
  background: #fff;
  transform: translateY(-50%);
}

.fields-wrp form .content-p {
  padding: 30px 0 30px 0;
}

.fields-wrp form .contentc {
  display: flex;
  width: 100%;
  height: 50px;
  font-size: 16px;
  align-items: center;
  justify-content: space-between;
}

.fields-wrp form .contentc .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fields-wrp form .contentc input {
  width: 15px;
  height: 15px;
}

.fields-wrp form .contentc label {
  color: #262626;
  user-select: none;
  padding-left: 5px;
}

.fields-wrp form .contentc .pass-link a {
  color: #c850c0;
}

.fields-wrp form .fieldbtn {
  text-align: center;
}

.fields-wrp form .fieldbtn input:nth-child(2) {
  margin-left: 15px;
}

.ml-15 {
  margin-left: 15px !important;
}

.landing_btn {
  min-height: 20px;
}

.fields-wrp form .fieldbtn input[type="submit"] {
  color: #fff;
  border: none;
  padding: 10px 25px !important;
  transition: all 0.3s ease;
  border-radius: 5px;
}

.fields-wrp form .fieldbtn input[type="submit"]:active {
  transform: scale(0.95);
}

.fields-wrp form .signup-link {
  color: #333;
  margin-top: 20px;
  margin-bottom: 35px;
}

.fields-wrp form .pass-link a {
  color: #047475;
}

.fields-wrp form .signup-link a {
  color: #fbb415;
  text-decoration: none;
}

.fields-wrp form .pass-link a:hover,
.fields-wrp form .signup-link a:hover {
  text-decoration: underline;
}

.loginwrap .rightbx .fields-wrp p {
  font-size: 14px;
}

.loginwrap .rightbx .fields-wrp a {
  color: #fbb415;
  margin-left: 3px;
}

.loginwithotp .imgotp {
  max-width: 150px;
  height: auto;
  margin: 0 auto;
}

.loginwithotp .close {
  position: absolute;
  right: 15px;
  top: 10px;
  font-weight: 600;
  color: #10556b;
}

.modal-md {
  max-width: 600px;
}

/************ Discussion List **********/

.counsellor.discussion > ul > li img {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.counsellor.discussion > ul > li h6 {
  font-size: 12px;
}

.counsellor.discussion > ul > li font {
  display: block;
  margin-top: -5px;
}

.searchreviewtop .form-control {
  border: 1px solid #116972;
  border-radius: 10px !important;
}

.searchreviewtop .form-control:focus {
  z-index: 0;
}

.searchreviewtop .btnedit {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #116b73;
}

.bg-grey-light {
  background-color: #f6f5f4;
}

p.profileeva small {
  color: #fe5b5c !important;
  font-weight: 600;
}

p.profileeva i {
  font-size: 13px;
  color: #333;
}

.review-discussion .iconscomments {
  padding: 10px 0;
}

.review-discussion .iconscomments img {
  width: 15px;
  height: auto;
}

.review-discussion .reviews_list p {
  font-size: 12px;
  line-height: 24px;
}

.review-discussion .subsomment svg {
  width: 12px;
}

.review-discussion .subsomment a:hover svg path {
  stroke: #fcbd30;
}

/********* Apply Now ***************/

.apply_wrap {
  margin: 0px;
}

.form-wizard {
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.form-container {
  clear: both;
  display: none;
  left: 100%;
  background: #fff;
  padding: 30px;
}

.form-container.active {
  display: block;
}

.form-title {
  margin-bottom: 30px;
  padding-bottom: 15px;
  position: relative;
}

.form-title::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 80px;
  height: 2px;
  background: #fb8200;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -otransform: translateX(-50%);
  transform: translateX(-50%);
}

.steps {
  padding: 1em 0;
  width: 100%;
}

.steps ul {
  overflow: hidden;
  margin: 0;
  padding: 1em 0;
  list-style: none;
  line-height: 1.2;
  font-size: 1em;
  counter-reset: li;
  animation: chase 1s 1 forwards;
}

.steps li {
  float: left;
  display: inline;
  position: relative;
  counter-increment: li;
  width: 11%;
  text-align: center;
  color: #ccc;
  transition: all 0.5s ease;
}

.steps li:before {
  content: "\f111";
  font: normal normal normal 22px/1 FontAwesome;
  position: relative;
  background-color: #9c9c9c;
  color: #7a7a7a;
  width: 35px;
  height: 35px;
  line-height: 35px;
  margin: 0 auto 0.5em;
  border-radius: 100%;
  font-size: 22px;
  text-align: center;
  display: block;
  z-index: 2;
}

.steps li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 8px;
  display: block;
  background: #d9d9d9;
  z-index: 1;
  top: 14px;
  left: 50%;
}

.steps li:first-child.active:after {
  left: 50%;
}

.steps li:last-child:after {
  display: none;
}

.steps li.done {
  color: #3a8983;
}

.steps li.done:after {
  width: 100%;
  background-color: #3a8983;
  background-image: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.35) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.35) 50%,
    rgba(0, 0, 0, 0.35) 75%,
    transparent 75%,
    transparent
  );
  background-repeat: repeat-x;
  background-size: 12px 12px;
  animation: progress 0.3s linear 0;
}

.steps li.done:before {
  background-color: #3a8983;
  border-color: #3a8983;
  color: white;
  content: "\f00c";
  font: normal normal normal 14px/1 FontAwesome;
  line-height: 35px;
}

.steps li.active {
  font-weight: bold;
  color: #57aed1;
}

.steps li.active:after {
  width: 100%;
  animation: progress 0.3s linear infinite;
}

@keyframes chase {
  from {
    width: 80%;
  }

  to {
    width: 100%;
  }
}

@keyframes progress {
  to {
    background-position: 20px 0;
  }
}

.steps1 ul.radio-image {
  list-style-type: none;
  margin: 0;
  padding: 0px;
}

.steps1 .radio-image li {
  display: inline-block;
  margin: 20px;
}

.steps1 .radio-image li label p {
  position: relative;
  color: #000;
  font-weight: 600;
}

.steps1 .radio-image [type="radio"]:checked,
.steps1 .radio-image [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.steps1 .radio-image [type="radio"]:checked + label p,
.steps1 .radio-image [type="radio"]:not(:checked) + label p {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.steps1 .radio-image [type="radio"]:checked + label p:before,
.steps1 .radio-image [type="radio"]:not(:checked) + label p:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #1890a0;
  border-radius: 100%;
  background: #fff;
}

.steps1 .radio-image [type="radio"]:checked + label p:after,
.steps1 .radio-image [type="radio"]:not(:checked) + label p:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #e2a522;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.steps1 .radio-image [type="radio"]:not(:checked) + label p:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.steps1 .radio-image [type="radio"]:checked + label p:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.steps1 .radio-image li label span {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
  display: block;
  cursor: pointer;
}

.view-more.btn2 {
  border: 0px;
  margin: 0 10px;
}

/*********** STEP 2***************/

.steps2 ul.radio-image {
  list-style-type: none;
  margin: 0 auto;
  padding: 0px;
  max-width: 1050px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.steps2 .radio-image li {
  display: block;
  margin: 20px 10px;
}

.steps2 .radio-image li label {
  background: #efefef;
  border-radius: 5px;
  align-items: flex-start;
  border: 1px solid #e4e4e4;
  width: 150px;
  height: 120px;
  padding: 10px 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.steps2 .radio-image li label p {
  position: relative;
  color: #000;
  font-weight: 600;
}

.steps2 .radio-image li label span {
  display: block;
  width: 100%;
  padding-top: 10px;
}

.steps2 .radio-image [type="radio"]:checked,
.steps2 .radio-image [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.steps2 .radio-image [type="radio"]:checked + label p,
.steps2 .radio-image [type="radio"]:not(:checked) + label p {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: block;
  color: #666;
  margin: -23px -10px 0 0;
}

.steps2 .radio-image [type="radio"]:checked + label p:before,
.steps2 .radio-image [type="radio"]:not(:checked) + label p:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #1890a0;
  border-radius: 100%;
  background: #fff;
}

.steps2 .radio-image [type="radio"]:checked + label p:after,
.steps2 .radio-image [type="radio"]:not(:checked) + label p:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #e2a522;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.steps2 .radio-image [type="radio"]:checked + label {
  background-color: #fff;
  box-shadow: 0 0 15px #efefef;
}

.steps2 .radio-image [type="radio"]:checked + label svg path {
  fill: #e2a522;
}

.steps2 .radio-image [type="radio"]:not(:checked) + label p:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.steps2 .radio-image [type="radio"]:checked + label p:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*********** STEP 3***************/

.steps3 ul.radio-image {
  list-style-type: none;
  margin: 0 auto;
  padding: 0px;
  max-width: 1050px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.steps3 .radio-image li {
  display: block;
  margin: 10px;
  font-size: 17px;
}

.steps3 .radio-image li label {
  background: #efefef;
  border-radius: 5px;
  align-items: flex-start;
  border: 1px solid #e4e4e4;
  width: 120px;
  height: 120px;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 50%;
  align-items: center;
  cursor: pointer;
}

.steps3 .radio-image li label span {
  display: block;
  width: 100%;
}

.steps3 .radio-image [type="radio"]:checked,
.steps3 .radio-image [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.steps3 .radio-image [type="radio"]:checked + label {
  background: rgb(14, 40, 70, 1);
  background: -moz-linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#0e2846", endColorstr="#127b7e", GradientType=1);
  color: #e2a522;
  font-weight: 700;
}

.steps3 .radio-image li > span {
  display: block;
  width: 100%;
  background-color: #e2a522;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #000;
}

/*********** STEP 4***************/

.steps4 ul.radio-image {
  list-style-type: none;
  margin: 0 auto;
  padding: 0px;
  max-width: 1050px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.steps4 .radio-image li {
  display: block;
  margin: 20px 10px;
}

.steps4 .radio-image li label {
  background: #efefef;
  border-radius: 5px;
  align-items: flex-start;
  border: 1px solid #e4e4e4;
  width: 150px;
  height: 120px;
  padding: 10px 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.steps4 .radio-image li label p {
  position: relative;
  color: #000;
  font-weight: 600;
}

.steps4 .radio-image li label span {
  display: block;
  width: 100%;
  padding-top: 10px;
}

.steps4 .radio-image [type="checkbox"]:checked,
.steps4 .radio-image [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.steps4 .radio-image [type="checkbox"]:checked + label p,
.steps4 .radio-image [type="checkbox"]:not(:checked) + label p {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: block;
  color: #666;
  margin: -23px -10px 0 0;
}

.steps4 .radio-image [type="checkbox"]:checked + label p:before,
.steps4 .radio-image [type="checkbox"]:not(:checked) + label p:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #1890a0;
  background: #fff;
}

.steps4 .radio-image [type="checkbox"]:checked + label p:after,
.steps4 .radio-image [type="checkbox"]:not(:checked) + label p:after {
  content: "\f00c";
  font: normal normal normal 11px/1 FontAwesome;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  left: 5px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: #e2a522;
}

.steps4 .radio-image [type="checkbox"]:checked + label p:before {
  border-color: #e2a522;
}

.steps4 .radio-image [type="checkbox"]:checked + label {
  background-color: #fff;
  box-shadow: 0 0 15px #efefef;
}

.steps4 .radio-image [type="checkbox"]:checked + label svg path {
  fill: #e2a522;
}

.steps4 .radio-image [type="checkbox"]:not(:checked) + label p:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.steps4 .radio-image [type="checkbox"]:checked + label p:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*********** Step 5 ************/

.radio-image.step5label li label {
  width: 230px;
  height: 140px;
}

/*********** STEP 6***************/

.steps6 ul.radio-image {
  list-style-type: none;
  margin: 0 auto;
  padding: 0px;
  max-width: 1050px;
}

.steps6 .radio-image li {
  display: block;
  margin: 20px 10px;
}

.steps6 .radio-image li label {
  background: #efefef;
  border-radius: 5px;
  cursor: pointer;
  align-items: flex-start;
  border: 1px solid #e4e4e4;
  width: 130px;
  height: 90px;
  margin-bottom: 40px;
  padding: 10px 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.steps6 .radio-image li label p {
  position: relative;
  color: #000;
  font-weight: 600;
}

.steps6 .radio-image li label span {
  display: block;
  width: 100%;
  padding-top: 10px;
}

.steps6 .radio-image li label h6 {
  position: relative;
  bottom: -25px;
  font-size: 13px;
}

.steps6 .radio-image [type="checkbox"]:checked,
.steps6 .radio-image [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.steps6 .radio-image [type="checkbox"]:checked + label p,
.steps6 .radio-image [type="checkbox"]:not(:checked) + label p {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: block;
  color: #666;
  margin: -23px -10px 0 0;
}

.steps6 .radio-image [type="checkbox"]:checked + label p:before,
.steps6 .radio-image [type="checkbox"]:not(:checked) + label p:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #1890a0;
  background: #fff;
  border-radius: 3px;
}

.steps6 .radio-image [type="checkbox"]:checked + label p:after,
.steps6 .radio-image [type="checkbox"]:not(:checked) + label p:after {
  content: "\f00c";
  font: normal normal normal 11px/1 FontAwesome;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  left: 5px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: #e2a522;
}

.steps6 .radio-image [type="checkbox"]:checked + label p:before {
  border-color: #e2a522;
}

.steps6 .radio-image [type="checkbox"]:checked + label {
  background-color: #fff;
  box-shadow: 0 0 15px #efefef;
}

.steps6 .radio-image [type="checkbox"]:checked + label svg path {
  fill: #e2a522;
}

.steps6 .radio-image [type="checkbox"]:not(:checked) + label p:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.steps6 .radio-image [type="checkbox"]:checked + label p:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.steps6 .radio-image li label span svg {
  height: 55px;
}

.steps6 .program-search {
  margin: 0 auto;
  max-width: 550px;
}

.steps6 .program-search .form-control {
  border-color: #047475;
}

.steps6 .program-search .input-group-append .btn {
  background-color: #047475;
}

.applymapslider .slick-track {
  margin-left: 0px;
}

.applymapslider .slick-prev,
.applymapslider .slick-next {
  background: #047475;
  border-radius: 50%;
  margin: 0 -45px;
  width: 32px;
  height: 32px;
  top: 40%;
}

.applymapslider .slick-prev:hover,
.applymapslider .slick-next:hover {
  background-color: #0b264b;
}

.applymapslider .slick-prev:before,
.applymapslider .slick-next:before {
  font-size: 24px;
  padding-left: 3px;
  color: #fff;
  line-height: 30px;
}

.applymapslider .slick-prev:before {
  padding-right: 6px;
}

/*********** STEP 7***************/

.steps7 .anyexam {
  display: block;
  font-size: 17px;
  margin-top: 10px;
}

.steps7 .anyexam label {
  background: #fcbd30;
  border-radius: 5px;
  width: auto;
  height: auto;
  padding: 7px;
  text-align: center;
  cursor: pointer;
  display: block;
}

.steps7 .anyexam label span {
  display: block;
  width: 100%;
}

.steps7 .anyexam [type="checkbox"]:checked,
.steps7 .anyexam [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.steps7 .anyexam [type="checkbox"]:checked + label {
  background: rgb(14, 40, 70, 1);
  background: -moz-linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#0e2846", endColorstr="#127b7e", GradientType=1);
  color: #e2a522;
  font-weight: 700;
}

.steps7 .form-group input,
.steps7 .form-group select {
  border: 1px solid #cfcfcf;
  width: 100%;
  padding: 8px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 5px;
}

.steps7 .anyexam > span {
  display: block;
  width: 100%;
  background-color: #e2a522;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #000;
}

.steps7 .form-box {
  max-width: 450px;
  margin: 10px auto;
  padding: 15px 0;
}

/******* Steps 9 ***********/

.steps9 {
  padding: 5% 0 0 0;
  text-align: center;
  max-width: 850px;
  margin: 0 auto;
}

.steps9 h2 {
  padding-bottom: 30px;
}

.steps9 h2 i {
  font-size: 65px;
  color: #047475;
  margin-left: 15px;
}

/*************** FAQ's ***************/

.faqpage {
  margin-top: 175px;
}

.uni-list-page {
  margin-top: 65px;
}

.faqpage .faqleft {
  background: url(../img/faq-bg.png) top left no-repeat;
  background-size: 100%;
  padding: 40px;
}

.faqpage .faqleft .heading h1 span {
  padding-bottom: 5px;
  display: inline-block;
  border-bottom: 2px solid #333;
  margin-bottom: 20px;
}

.faqpage .faqleft .heading p {
  font-size: 19px;
  margin-right: 75px;
  line-height: 30px;
}

.faqtabs {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 80%;
}

.faqtabs.articaltabs {
  display: block !important;
  max-width: 100% !important;
}

.faqtabs.articaltabs .tab-content {
  width: 100% !important;
}

.faqtabs .nav-tabs {
  width: 300px;
  display: block;
}

.faqtabs .tab-content {
  width: calc(100% - 340px);
}

.faqtabs .nav .nav-item {
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
}

.faqtabs .nav .nav-link {
  text-align: left;
  box-shadow: 0 0 8px transparent;
  background-color: #e6e6e6;
  border-radius: 0px;
}

.faqtabs .nav .nav-link.active {
  text-align: left;
  box-shadow: 0 0 12px rgb(165, 165, 165);
}

.faqtabs .tab-content .card {
  margin-bottom: 30px;
  border: 0;
  border-radius: 0px;
}

.faqtabs .tab-content .card .card-header {
  border: 0;
  -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  padding: 0;
}

.faqtabs .tab-content .card .card-header .btn-header-link {
  min-height: 75px;
  color: #fff;
  display: block;
  text-align: left;
  background: rgb(4, 116, 117);
  background: -moz-linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  background: -webkit-linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  background: linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#047475", endColorstr="#01132f", GradientType=1);
  /* color: #222; */
  padding: 10px 20px;
}

.faqtabs .tab-content .card .card-header .btn-header-link:after {
  content: "\f068";
  font-family: "FontAwesome";
  font-weight: 900;
  position: absolute;
  top: 27px;
  right: 7px;
}

.w-97 {
  width: 97%;
}

.faqtabs .tab-content .card .card-header .btn-header-link.collapsed {
  background: rgb(4, 116, 117);
  background: -moz-linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  background: -webkit-linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  background: linear-gradient(
    77deg,
    rgba(4, 116, 117, 1) 0%,
    rgba(1, 19, 47, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#047475", endColorstr="#01132f", GradientType=1);
  color: #fff;
}

.faqtabs .tab-content .card .card-header .btn-header-link.collapsed:after {
  content: "\f067";
}

.faqtabs .tab-content .card .collapsing {
  background: #fff;
  line-height: 30px;
}

.faqtabs .tab-content .card .collapse {
  border: 0;
}

.faqtabs .tab-content .card .collapse.show {
  background: #fff;
  line-height: 30px;
  color: #222;
}

/****** Dashboard Profile page*****/

.user_nav {
  margin-bottom: 0px;
}

.user_nav ul {
  padding: 0 5px;
}

.user_nav ul li {
  margin-bottom: 5px;
}

.user_nav ul li:last-child {
  margin-bottom: 0px;
}

.user_nav ul li a {
  padding: 5px 15px 5px 25px;
  display: block;
  border: 1px solid transparent;
  border-radius: 5px;
}

.user_nav ul li a:hover,
.user_nav ul li a.active {
  border: 1px solid #047475;
  color: #047475;
  font-weight: bold;
  background-color: #e8e8e8;
}

.user_nav ul li a img {
  margin-right: 15px;
  width: 40px;
}

.profile_wrap .myprofile .nav {
  justify-content: space-around;
}

.profile_wrap .myprofile .nav .nav-item {
  width: 23%;
}

.profilehead .userpic {
  display: inline-block;
}

.profilehead .userpic > span {
  position: relative;
  display: block;
}

.profilehead .userpic .cam {
  position: absolute;
  right: 15px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #ccc;
  bottom: 8px;
  width: 20px;
}

._mt-5 {
  margin-top: 5px;
}

#userDOB {
  cursor: pointer;
}

.userName {
  display: inline-block;
  width: 85px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-top: 5px;
}

.profilehead .userpic span > img {
  width: 70px;
  height: 70px;
  margin-right: 20px;
  border-radius: 50px;
  border: 1px solid #ccc;
}

.profilehead .userpic .cam img {
  width: 20px;
  height: 20px;
}

.profilehead p a {
  color: #666;
}

.profilehead p a:hover {
  color: #000;
}

.profile_field.fields-wrp form .field input,
.profile_field.fields-wrp form .field select {
  padding-left: 12px;
  font-size: 13px;
}

.profile_field.fields-wrp form .field label {
  left: 12px;
  font-size: 13px;
}

.profile_field.fields-wrp form .field_gender {
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  position: relative;
}

.profile_field.fields-wrp form .field_gender h6 {
  line-height: 50px;
  /* padding-left: 20px; */
}

.terms__ {
  margin-top: 0 !important;
}

.profile_field.fields-wrp form .field_gender h6 input {
  margin-left: 10px;
}

.profile_field.fields-wrp form .field span {
  left: auto;
  right: 10px;
  pointer-events: none;
}

.profile_field .field_outerlink {
  float: right;
  padding-top: 2px;
  color: #047475;
}

.profile_field .field_outerlink:hover {
  color: #000;
}

.profile_field.fields-wrp form .field input:focus ~ label,
.profile_field.fields-wrp form .field input:valid ~ label {
  left: 12px;
}

.profile_field.fields-wrp .allvalue {
  font-size: 36px;
  font-weight: bold;
  color: #e2a522;
}

.profile_field.fields-wrp .allvalue:hover {
  color: #111;
}

.profile_field form .field label.control-label1 {
  top: 0%;
  font-size: 16px;
  left: 12px;
  font-size: 12px;
  padding: 0 3px;
  color: #fbb415;
  background: #fff;
  transform: translateY(-50%);
}

.profile_field form .field.active label.control-label1 {
  position: absolute;
  top: 50%;
  left: 12px;
  color: #999;
  font-weight: 400;
  font-size: 13px;
  pointer-events: none;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}

.sliderbox {
  position: relative;
  margin-top: 169px;
}

.login_sec {
  margin-top: 165px;
}

@media only screen and (max-width: 1566px) {
  .playslider {
    width: 155px;
  }

  .offers-item .mt_btn_yellow {
    padding: 10px 15px;
    font-size: 15px;
  }

  .offers-item h3 {
    width: 61%;
    font-size: 22px;
  }

  .appbg {
    background-size: 38%;
  }

  .appbg .headingmains h2 {
    font-size: 3rem;
  }

  .why-item .content-box p {
    padding-right: 0px;
    font-size: 0.8rem;
  }

  .offers-item::before {
    background-size: 75px;
    background-repeat: no-repeat;
  }

  .appdown {
    width: 94%;
  }

  .intelliSearch {
    margin-top: 10px;
  }

  .new-intelli .form-group {
    margin-bottom: 0px;
  }

  .chosen-container-single .chosen-single {
    height: 32px;
    line-height: 32px;
  }

  .study_level_btn {
    font-size: 0.75rem;
  }

  .has-search {
    margin-bottom: 10px !important;
  }
}

@media only screen and (max-width: 1440px) {
  .topbar-section::after {
    width: 52%;
  }

  .new-intelli h6 {
    font-size: 12px;
    padding: 5px 0 0 0;
  }

  .study_level_btn {
    font-size: 11px;
  }

  .chosen-container-single .chosen-single {
    height: 26px;
    line-height: 26px;
  }

  .chosen-container-single .chosen-single span {
    font-size: 11px !important;
  }

  .new-intelli {
    width: 400px !important;
  }

  .intelliSearch::before {
    width: 312px;
  }

  .offers-item::before {
    background-size: 60px;
    right: -48px;
    top: -40px;
  }

  .offers-item {
    margin: 44px 16px;
  }

  .offers-item h3 span {
    font-size: 40px;
  }

  .appbg .headingmains h2 {
    font-size: 2.5rem;
  }

  .appdown ul li {
    width: 150px;
  }

  .appdown h3 {
    width: 300px;
    font-size: 28px;
    line-height: 42px;
  }

  .appbg .icon-circle {
    width: 40px;
    display: block;
    margin: 0 auto;
  }

  .cate-box li.job-categorynew {
    width: 17.2%;
  }

  .member_name iframe {
    width: 100% !important;
    height: 160px !important;
  }

  .shareblog {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1140px) {
  .headingmains p {
    font-size: 1.1rem;
  }

  .headingmains h2 {
    font-size: 2rem;
  }

  .main-menu > ul > li > a {
    position: relative;
    display: block;
    padding: 15px 20px;
    color: #fff;
    font-size: 14px;
  }

  .topbar-inner ul li {
    font-size: 12px;
  }

  .search-sec {
    width: 240px;
    margin-top: 6px;
  }

  .search-sec .form-control {
    padding: 6px 15px 7px 10px;
  }

  .top-bar-right {
    padding-right: 2%;
  }

  .topbar-section {
    padding: 0px;
  }

  .main-logo img {
    max-width: 175px;
    width: 175px;
  }

  .main-menu > ul > li + li {
    margin-left: 0px;
  }

  .feat-img .th-name h5 {
    font-size: 0.9rem;
  }

  .feat-img .th-name {
    padding: 10px 15px;
  }

  .slidercont img {
    max-width: 140% !important;
    width: 140%;
  }

  .page-heading-content .title {
    font-size: 36px;
  }

  .playslider {
    width: 125px;
    margin-right: 52%;
    margin-top: 10%;
  }

  .tranding-item .overlayPort ul li.tdanding {
    background-color: #0b264b;
    width: 185px;
    height: 87px;
    margin-top: -65px;
    color: #fff;
    padding-top: 61px;
    text-align: right;
    font-size: 16px;
    margin-right: -14px;
    font-weight: 500;
    font-family: "Roboto";
  }

  .tranding-item p,
  .tranding-item ul li {
    font-size: 0.9rem;
  }

  .mt_btn_yellow {
    font-size: 15px;
  }

  .section {
    padding: 30px 0;
  }

  p {
    font-size: 0.9rem;
  }

  .appbg {
    background-image: none;
  }

  .appbg .sub-section {
    margin: 0;
    box-shadow: 0px 0px 13px #b8e2fe;
    padding: 20px;
    max-width: 100%;
    width: 100%;
  }

  ul.cate-box {
    justify-content: space-between;
  }

  .cate-box li.job-categorynew:last-child {
    margin: 0px;
  }

  .tesi-wrap .inner-heading {
    margin-top: 28%;
    padding-top: 0px;
    padding-left: 70px;
  }

  .testi_slider .slick-prev {
    left: -39%;
  }

  .videobx img {
    height: 110px;
  }

  .videobx {
    min-height: 171px;
    padding-top: 25px;
  }

  .testi_slider .slick-prev,
  .testi_slider .slick-next {
    top: 72%;
  }

  .tesi-wrap {
    background: #f6f7f9 url(../img/bg-esti.png) bottom left no-repeat;
    background-size: auto;
    background-size: 115%;
  }

  .coachslider .slick-next {
    margin-right: -65px;
  }

  .search-sec::before {
    top: 17%;
  }

  .getstart .dropdown .dropdown-toggle {
    font-size: 12px;
    padding: 7px 20px;
  }

  .new-intelli .form-group {
    margin-bottom: 10px;
  }

  /********UNI List*********/
  .uni-item-list .item-btn-more {
    padding: 0 5px 0 0;
  }

  /*******UNI details******/
  .singleslider .slick-prev,
  .singleslider .slick-next {
    margin-top: -40px;
  }

  .singleslider .offers-item .content-box::before {
    top: -5px;
  }

  /**** Review *****/
  .reviews_item p {
    font-size: 13px;
  }

  /******* Counsellor List ************/
  .counsellorlist .uni-item-list .item-btn-more a {
    width: 150px;
    padding: 7px;
    line-height: 15px;
  }

  .counsellorlist .uni-item-list .item-media {
    width: 33%;
  }

  .counsellorlist .uni-item-list .item-body {
    padding-left: 15px;
  }

  .counsellorlist .item-abouttxt {
    width: 90%;
  }

  .uni-item-list .item-abouttxt > table {
    width: 100%;
  }

  /******* Counsellor Details *********/
  .pricedegree {
    float: none;
  }

  .counsellor_details .uni-item-list .item-body .item-title h3 {
    font-size: 24px;
  }

  .counsellor_details .uni-item-list .item-abouttxt > table {
    width: 100%;
  }

  .simpleslider_head p {
    width: 100%;
  }

  /******* Offer List **********/
  .offer_sholarship .offers_list .offers-item {
    width: 47%;
  }

  .offer_sholarship .offers_list .offers-item:nth-child(2n)::before {
    background-image: url(../img/dot_offer-yellow.png);
    right: -50px;
    bottom: -68px;
    top: auto;
  }

  .sholarship-wrp .s-item {
    width: 48%;
  }

  .sholarship-wrp .offers_list .s-item:nth-child(6n + 4)::before {
    right: -50px;
    bottom: -68px;
    top: auto;
    left: auto;
  }

  .sholarship_details .details-top {
    padding: 15px;
    padding-bottom: 0px;
  }

  .sholarship_details .details-bottom {
    padding: 0px 15px 20px 15px;
  }

  /*****Package ********/
  .packagelist .item-package .card {
    margin: 0;
  }

  /******STEPS ******/
  .steps6 ul.radio-image {
    max-width: 960px;
    width: 100%;
  }

  /****** FAqs *******/
  .faqpage .faqleft .heading p {
    font-size: 13px;
    margin-right: 43px;
    line-height: 21px;
  }

  .faqtabs {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1003px) {
  .menu-toggle span,
  .menu-toggle span::before,
  .menu-toggle span::after {
    background-color: #fff;
  }

  .topbar-section::after {
    background-color: #fbb415;
    position: absolute;
    top: 50px;
    bottom: -1px;
    right: 0;
    width: 100%;
    content: "";
    transform: skew(0deg);
    border-top: 4px solid #39475d;
    z-index: 0;
    border-left: 0px;
  }

  .top-bar-left.pull-left,
  .top-bar-right.pull-right.righttop {
    float: none !important;
    margin-top: 0px;
    text-align: center;
  }

  .search-sec {
    width: 157px !important;
    margin: 17px 30px 5px 9px !important;
  }

  .slidercont img {
    max-width: 100% !important;
    width: 100%;
  }

  .search-slider {
    position: relative;
  }

  .intelliSearch .frm_dvhome {
    position: relative;
    top: 20px;
    z-index: 8;
    border-radius: 20px;
    right: 0;
    background: #fcfcfc;
    padding: 20px 20px 40px 20px;
    width: 100% !important;
    box-shadow: 0px 1px 5px #ccc;
    margin-bottom: 45px;
  }

  .playslider {
    display: none;
  }

  .intelliSearch::before {
    width: 70%;
    right: auto;
    left: -12px;
  }

  .offerslider .slick-next {
    margin: 0 -45px;
  }

  .offerslider .slick-prev {
    margin: 0 -35px;
  }

  .ser-box .offerslider .slick-next {
    margin: 0 -45px;
    display: none !important;
  }

  .ser-box .offerslider .slick-prev {
    margin: 0 -62px;
    display: none !important;
  }

  .offers-item .mt_btn_yellow {
    padding: 6px 10px;
    font-size: 12px;
  }

  .offers-item h3 {
    font-size: 18px;
  }

  .offers-item h3 span {
    font-size: 32px;
  }

  .couch-item::before {
    width: 80%;
  }

  .couch-item .content-box h4 {
    font-size: 1rem;
  }

  .couch-item .content-box p {
    font-size: 0.8rem;
  }

  .couch-item .content-box {
    padding: 0px;
  }

  .appbg .headingmains h2 {
    font-size: 2rem;
  }

  .testi_slider .slick-prev {
    left: -35%;
  }

  .testi_slider .slick-next {
    left: -15%;
  }

  .testi_slider .slick-prev,
  .testi_slider .slick-next {
    top: 70%;
  }

  .tesi-wrap .inner-heading {
    margin-top: 20%;
    padding-left: 55px;
  }

  .tesi-wrap .headingmains p {
    font-size: 0.9rem;
  }

  .member_name iframe {
    width: 100% !important;
    height: 123px !important;
  }

  .cd-top {
    display: none !important;
  }

  .chatbot {
    width: 70px;
  }

  .pwhatsapp {
    width: 40px;
    height: 40px;
    bottom: 90px;
    font-size: 30px;
    line-height: 42px;
  }

  .main-menu > ul {
    justify-content: flex-end;
  }

  .main-menu > ul > li:last-child > a {
    padding: 15px 30px 15px;
  }

  .tesi-wrap .team_member p,
  .videobx {
    min-height: 150px;
  }

  .videobx img {
    height: 120px;
  }

  .tesi-wrap {
    background: #f6f7f9 url(../img/bg-esti.png) bottom left no-repeat;
    background-size: auto;
    background-size: auto;
    background-size: 135% 93%;
  }

  /****** UNI LIST ********/
  .uni-item-list {
    flex-wrap: wrap;
  }

  .uni-item-list .item-btn-more {
    width: 100%;
    padding: 0 10px;
  }

  .uni-item-list .item-body {
    width: 60%;
  }

  .uni-item-list .item-media {
    width: 40%;
  }

  .uni-item-list .item-btn-more a {
    display: inline-block !important;
    padding: 0px 10px;
  }

  .uni-item-list .item-title h5 {
    font-size: 18px;
  }

  /********Uni Details ***********/
  .uni-details .content .textwrp p {
    width: 80%;
  }

  .uni-details .content .textwrp.country p {
    width: 100%;
  }

  .uni-details .content .btncwrp {
    margin-top: 0;
    float: left;
  }

  .slidercont.uni-details .slick-dots {
    display: none !important;
  }

  .uni-details .content .textwrp {
    width: 95%;
  }

  .singleslider .offers-item .content-box::before {
    transform: skew(0deg, -1deg);
  }

  .about-unidetail .tableformt li:nth-child(2n-1) {
    width: 76%;
  }

  .ranking ul li {
    width: 30%;
  }

  /******** Counsellor list *************/
  .uni-item-list .item-btn-more {
    justify-content: center;
  }

  .counsellorlist .uni-item-list .item-body {
    width: 67%;
  }

  /******** Counsellor Details **********/
  .counsellorlist.counsellor_details .uni-item-list .item-media {
    margin: 10px auto;
  }

  .counsellorlist.counsellor_details .uni-item-list .item-body {
    width: 100%;
    margin: 0px;
    padding: 15px;
  }

  .counsellor_details .uni-item-list .item-btn-more {
    width: 100%;
  }

  .counsellor_details .uni-item-list .item-btn-more .btnbox {
    display: inherit;
  }

  .counsellor_details .uni-item-list .item-btn-more a {
    height: 32px;
    margin: 10px 10px 0 0;
    line-height: 31px;
    padding: 0px;
  }

  .simpleslider_wrp .slidercont img {
    height: 200px;
  }

  /****** Sholarship ********/
  .sholarship-wrp .s-item {
    width: 46%;
  }

  .sholarship-wrp .s-item .mt_btn_yellow {
    padding: 10px 15px;
  }

  .sholarship-wrp .s-item h3 {
    font-size: 11px;
  }

  /********* Package ********/
  .packagelist {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .packagelist .item-package {
    margin: 30px 0;
  }

  .postcard .postcard__img {
    margin-bottom: 0px;
  }

  /****** Steps ********/
  .form-container {
    padding: 30px 0;
  }

  .steps3 .radio-image li label,
  .steps3 .radio-image li > span {
    width: 102px;
    height: 102px;
  }

  .steps3 .radio-image li {
    font-size: 15px;
  }

  .steps6 .radio-image li label {
    margin: 0 auto;
    margin-bottom: 40px;
  }

  /********* Faqs ************/
  .faqpage .faqleft .heading p {
    font-size: 16px;
    margin-right: 52px;
    line-height: 28px;
  }

  .faqpage .faqleft {
    min-height: 500px;
  }

  .faqtabs .nav-tabs {
    width: 240px;
  }

  .faqtabs .tab-content {
    width: calc(100% - 275px);
  }
}

@media only screen and (max-width: 767px) {
  .navbar-collapse.collapse {
    display: none !important;
  }

  ._mr-37 {
    margin-top: 0px;
  }

  i.fa.fa-check-circle {
    margin-top: 8px;
  }

  .timepicker-container-inner {
    width: 1000px !important;
    max-width: 1000px !important;
    display: flex !important;
    overflow-x: scroll !important;
  }

  .formatted-time {
    width: max-content;
  }

  .timeline-container {
    width: 0 !important;
  }

  .timepicker-container-outer {
    height: 9vh !important;
  }

  .calendar-container {
    width: 90%;
  }

  .gif {
    width: 24% !important;
  }

  .topbar-inner ul li {
    padding: 5px;
    margin: 0px;
  }

  .header {
    padding: 10px 0;
    margin-top: 88px;
  }

  .sliderbox {
    margin-top: 153px;
  }

  .page-heading-content .title {
    font-size: 1.4rem;
  }

  .page-heading-section6 {
    bottom: 20px;
  }

  .headingmains h2 {
    font-size: 1.6rem;
  }

  .headingmains p {
    font-size: 0.8rem;
    width: 100%;
  }

  .view-more {
    font-size: 16px;
  }

  .offerslider .slick-next {
    margin: 0 -25px !important;
  }

  .couch-item {
    padding: 10px 12px;
  }

  .coachslider .slick-prev {
    margin: 0 -47px;
  }

  .coachslider .slick-next {
    margin-right: -55px;
  }

  .cate-box li.job-categorynew {
    width: 48%;
    margin: 0;
    margin-bottom: 0px;
    margin-bottom: 11px;
  }

  .cate-box li.job-categorynew:last-child {
    width: 100%;
    margin: 0;
    margin-bottom: 0px;
    margin-bottom: 11px;
  }

  .appdown h3 {
    width: 300px;
    font-size: 19px;
    line-height: 26px;
  }

  .ser-box {
    margin-left: -5px;
  }

  .service-category {
    width: 100%;
    margin: 12px 10px;
    height: 350px !important;
  }

  button.slick-prev {
    margin: 0 -35px !important;
  }

  .why-choose-ad {
    margin: 15px 0px;
    width: 100%;
  }

  .footer .footer-top .countrybx ul {
    justify-content: left;
    position: absolute;
    top: 30px;
    padding-top: 7px;
    line-height: 30px;
    text-align: center;
    padding-right: 10px;
  }

  .footer .footer-top .countrybx {
    height: 127px;
  }

  .footer .footer-top .countrybx ul li a:after {
    height: 15px;
    margin: 0px 2px;
  }

  .footer .footer-top .countrybx ul li a {
    font-size: 10px;
  }

  button.slick-next {
    margin: 0 -35px !important;
  }

  .tesi-wrap {
    background: #f6f7f9 url(../img/bg-esti.png) bottom center no-repeat;
    background-size: auto;
    background-size: auto;
    background-size: auto;
    background-size: 265%;
  }

  .tesi-wrap .inner-heading {
    margin-top: 0;
    text-align: center;
  }

  .tesi-wrap .headingmains p,
  .tesi-wrap .headingmains h2 {
    color: #333;
    margin: 0px;
  }

  .testi_slider {
    margin-top: 60px;
  }

  .testi_slider .slick-prev,
  .testi_slider .slick-next {
    display: none;
  }

  .why_slide {
    flex-wrap: wrap;
  }

  .why-item {
    width: 100%;
    margin: 10px 0;
  }

  .studybg {
    background-size: 101% 50%;
  }

  /* .footer .footer-widget.footer-menu ul {
        margin-bottom: 30px;
    } */
  .footer .footer-widget .footer-about-content p {
    width: 100%;
  }

  .topbar-section::after {
    top: 40px;
  }

  .search-sec {
    margin-top: 10px !important;
    width: 180px !important;
  }

  .mobile_flex {
    display: flex;
    justify-content: space-around !important;
  }

  .cate-box li.job-categorynew .icon-circle img {
    height: auto;
  }

  .mob_pt-5 {
    padding-top: 5px;
  }

  .tesi-wrap .inner-heading {
    padding: 0px;
  }

  .view-more1,
  .view-more {
    font-size: 14px;
  }

  .mobnav {
    display: block;
  }

  .topbar-inner ul li {
    font-size: 11px;
  }

  .srch_btn {
    font-size: 16px;
    height: 40px;
  }

  .studyslider .slick-next {
    margin: 0 -59px !important;
  }

  .offers-item:before {
    background-size: 52px;
  }

  .offerslider {
    margin-right: -19px;
  }

  .bggreadent,
  .studywrp .bgstudy {
    min-height: 40px;
  }

  .c-que details {
    max-height: 370px;
    overflow: auto;
  }

  /****** UNI LIST ********/
  .uni-item-list .item-btn-more {
    width: 100%;
    padding: 0 10px;
  }

  .uni-item-list .item-body,
  .uni-item-list .item-media {
    width: 100%;
  }

  .uni-item-list .item-body {
    padding: 0 10px 10px;
  }

  .uni-item-list .item-abouttxt > table {
    width: 100%;
  }

  .uni-item-list .item-btn-more a {
    width: auto;
    font-size: 11px;
  }

  .uni-item-list .item-btn-more a.download {
    width: 138px;
  }

  .uni-item-list .item-btn-more {
    justify-content: center;
  }

  .ad-slider .itemslider {
    padding: 2px 0;
  }

  .detailstudy .eligibility {
    padding-top: 12px;
    display: block;
    margin-bottom: -5px;
  }

  .ad-slider .slick-prev,
  .ad-slider .slick-next {
    margin: 0 -46px !important;
    width: 30px;
    height: 30px;
  }

  .ad-slider .slick-prev::before,
  .ad-slider .slick-next::before {
    font-size: 30px;
  }

  /****** UNi Details *****/
  .bgstudy h5 {
    text-align: left;
  }

  .detailstudy table tr td span {
    font-size: 9px;
  }

  a.checkbtn {
    background-color: #fcbd30;
    display: inline-block;
    border-radius: 5px;
    padding: 6px 10px;
    font-size: 11px;
    color: #000;
    margin-top: 10px;
  }

  .unifilter-page {
    margin-top: 35px;
  }

  .unifilter__mob {
    margin-top: 179px !important;
  }

  .terms__ {
    margin-top: 0 !important;
  }

  .shade1 .headingmains h4 {
    font-size: 16px;
  }

  .about-uni-text h5,
  .ranking h5 {
    font-size: 15px;
  }

  .about-uni-text p {
    font-size: 13px;
    margin-top: 13px;
    padding: 0px 10px;
  }

  .singleslider button.slick-prev {
    margin: 0 -37px !important;
  }

  .singleslider button.slick-next {
    margin: 0 -40px !important;
  }

  .featured-item {
    width: 100%;
    margin: auto;
  }

  img.uniLogody {
    width: 130px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: auto;
  }

  .uni_slide .slick-prev {
    margin-left: -30px !important;
  }

  .uni_slide .slick-next {
    margin-right: -30px !important;
  }

  .tranding_slide .slick-next {
    margin-right: -39px !important;
  }

  .course-offer-uni .sub-section {
    padding: 20px 0;
  }

  .about-unidetail .tableformt li {
    margin: 0px;
  }

  .about-unidetail .tableformt li:nth-child(2n-1) {
    margin-bottom: 0px;
    width: 100%;
  }

  .about-unidetail .tableformt li:nth-child(2n) {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 5px;
    padding-top: 0;
  }

  .acception-uni .accept1 .secaccept {
    width: 100%;
    margin-bottom: 10px;
  }

  .acception-uni .accept1.accept2 {
    padding: 0px;
  }

  .acception-uni .accept2 .secaccept {
    width: 30%;
    margin: 0;
    padding: 10px;
    text-align: center;
  }

  .acception-uni .accept2 .secaccept > * {
    display: table-row;
  }

  .acception-uni .accept1 .secaccept p {
    font-size: 12px;
  }

  .acception-uni .accept2 .secaccept p {
    font-size: 11px;
    padding-top: 5px;
    display: block;
    padding-left: 0px;
    text-align: center;
  }

  .acception-uni {
    padding: 15px;
  }

  .testscore .studywrp table tr td h6 {
    font-size: 12px;
  }

  .testscore .studywrp table tr td,
  .ranking ul li p,
  .universitein li p {
    font-size: 11px;
  }

  .ranking ul li {
    width: 49%;
  }

  .studentsay {
    padding: 30px 15px;
  }

  .location-uni iframe {
    height: 200px;
  }

  .__vblog iframe {
    height: 200px;
  }

  .nav2 {
    display: flex;
    overflow: auto;
  }

  .nav2 li a {
    font-size: 12px;
    white-space: nowrap;
  }

  .appfees {
    text-align: center;
    font-size: 13px;
  }

  .uni-details .content .btncwrp {
    margin-top: 25px;
  }

  .starbtnsite {
    position: relative;
    bottom: 90px;
    right: 0;
    z-index: 1;
    width: 100%;
    text-align: left;
    padding: 15px;
  }

  .navsecond {
    margin-top: -101px;
  }

  .uni-list-page-1 {
    margin-top: 30px;
  }

  .slick-next-uni {
    right: 26px;
  }

  .about-uni-text {
    padding: 10px 10px 10px 10px;
  }

  .wishlisttop {
    bottom: 34%;
    right: 15px;
    width: auto;
    z-index: 2;
    top: unset;
  }

  button.wishlist {
    font-size: 18px;
    width: 28px;
  }

  .uni-details .content {
    padding: 10px 0;
  }

  .uni-details .content h2 {
    font-size: 18px;
  }

  .uni-details .content .textwrp p {
    font-size: 12px;
    width: 100%;
  }

  .uni-details .content .textwrp.country p {
    font-size: 14px;
    width: 100%;
  }

  .starbtnsite ul li {
    display: inline-block;
  }

  .starbtnsite ul li:nth-child(3) {
    width: 100%;
  }

  .starbtnsite .wishlist {
    font-size: 13px;
  }

  .starbtnsite a.review {
    color: #fff;
    font-size: 10px;
    padding-left: 10px;
  }

  .uni-details .content .btncwrp .awe-btnbook {
    border: 3px solid #fcbd30;
    color: #fcbd30;
    font-size: 12px;
    padding: 7px 0px;
    width: 135px;
  }

  .slidercont.uni-details .slick-prev,
  .slidercont.uni-details .slick-next {
    display: none !important;
  }

  .slidercont.uni-details .slick-dots {
    display: block !important;
    margin-bottom: -25px;
  }

  .acception-uni .accept2 .secaccept img {
    width: 50%;
    height: auto;
    margin: 0 auto;
    display: inline-block;
  }

  /****** Program ********/
  .serchsuggest {
    padding: 0px;
  }

  .serchsuggest p {
    min-width: 70px;
  }

  .serchsuggest ul li a {
    min-width: auto;
  }

  .serchsuggest ul li {
    margin: 4px 1px;
  }

  /******* Review ********/
  .reviews_item h6 font {
    display: block;
    float: none;
  }

  .reviews_item p {
    font-size: 12px;
  }

  .reviews_item h6 {
    font-size: 15px;
  }

  /******** Counsellor list *************/
  .counsellorlist .uni-item-list .item-media {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    background-color: #d7edea;
  }

  .counsellorAneCoachCover {
    /* position: relative ; */
    border-radius: 4px !important;
    /* margin-right: 5px; */
    margin: 0px auto;
    height: 161px !important;
    width: 193px !important;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }

  .counsellorlist .uni-item-list .item-body {
    width: 100%;
    padding: 15px;
  }

  .counsellorlist .uni-item-list .item-btn-more {
    padding: 0px;
  }

  .counsellorlist .uni-item-list .item-btn-more .btnbox {
    text-align: center;
    padding: 10px 0;
  }

  .counsellorlist .uni-item-list .item-btn-more a,
  .counsellorlist .uni-item-list .item-btn-more a.download {
    width: 100%;
    margin: 2px 0;
  }

  .skew-tabs ul li,
  .skew-tabs2 ul li {
    min-width: 140px;
  }

  .counsellorlist.counsellor_details .uni-item-list .item-media {
    padding: 0px;
  }

  /******** Counsellor Details **********/
  .counsellorlist.counsellor_details .uni-item-list .item-media {
    margin: 0 auto 10px auto;
    border-radius: 8px;
  }

  .counsellor_details .tabdetails ul li a.nav-link {
    padding: 12px;
    white-space: nowrap;
  }

  .counsellor_details .tabdetails ul {
    padding: 0 20px;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .counsellor_details .tabdetails .tab-content p {
    line-height: 22px;
    font-size: 12px;
  }

  /* .uni-item-list .item-abouttxt img {
        max-width: fit-content;
        position: absolute;
        top: 67px;
        margin-left: 10px;
    } */
  .mob-text-left {
    text-align: left !important;
  }

  .uni-item-list .item-body .item-address {
    margin-top: 0px;
    max-height: 0px;
  }

  /* .item-abouttxt {
        margin-top: 18px;
    } */
  .uni-name-mob {
    width: 100%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .uni-item-list .item-abouttxt span {
    font-size: 12px;
    color: #555;
    font-weight: 500;
    /* width: 100%;
        text-overflow: ellipsis;
        display: -webkit-box; */
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .textwrp {
    margin-top: 10px !important;
  }

  .simpleslider_head .textwrp {
    padding: 0px;
  }

  .simpleslider_head p {
    line-height: 20px;
  }

  .simpleslider_head p b {
    font-weight: 500;
    font-size: 13px;
  }

  .counsellor_details .item-abouttxt {
    width: 100%;
  }

  .counsellorlist.counsellor_details .uni-item-list .item-btn-more .btnbox {
    flex-wrap: wrap;
  }

  /******* Offer List **********/
  .offer_sholarship .offers_list .offers-item {
    width: 90%;
    margin-bottom: 20px;
  }

  .radiocustom1 {
    flex-wrap: inherit;
    margin-top: 5px;
  }

  .intelliSearch .frm_dvhome {
    padding: 20px 20px 10px 19px;
  }

  .offer_sholarship .offers_list {
    justify-content: center;
  }

  .skew-tabs2 ul li a p {
    padding-right: 10px;
  }

  .srch_btn {
    bottom: 10px;
  }

  .offer_sholarship .offers_list .offers-item:nth-child(6n + 1)::before {
    background-image: url(../img/dot_offer-blue.png);
    right: -38px;
    top: -38px;
  }

  .offer_sholarship .offers_list .offers-item:nth-child(6n + 2)::before {
    background-image: url(../img/dot_offer-yellow.png);
    right: -50px;
    bottom: auto;
    top: -38px;
  }

  .offer_sholarship .offers_list .offers-item:nth-child(6n + 3)::before {
    background-image: url(../img/dot_offer-bluel.png);
    right: -50px;
    top: -38px;
  }

  .offer_sholarship .offers_list .offers-item:nth-child(6n + 4)::before {
    background-image: url(../img/dot_offer-bluel.png);
    left: -28px;
    top: -38px;
  }

  .offer_sholarship .offers_list .offers-item:nth-child(6n + 5)::before {
    background-image: url(../img/dot_offer-yellow.png);
    left: -28px;
    bottom: auto;
    top: -38px;
  }

  .offer_sholarship .offers_list .offers-item:nth-child(6n + 6)::before {
    background-image: url(../img/dot_offer-blue.png);
    left: -28px;
    top: -38px;
  }

  .sholarship-wrp .s-item {
    width: 90%;
    margin-bottom: 20px;
    margin: 45px auto;
  }

  .sholarship-wrp .offers_list .s-item:nth-child(6n + 2)::before {
    right: -50px;
    top: -38px;
    bottom: auto;
    left: auto;
  }

  .sholarship-wrp .offers_list .s-item:nth-child(6n + 5)::before,
  .sholarship-wrp .offers_list .s-item:nth-child(6n + 4)::before {
    left: -28px;
    top: -38px;
    bottom: auto;
    right: auto;
  }

  .sholarship-wrp .content-head .hadsec h6 {
    font-size: 10px;
  }

  .sholarship_details .details-top .topright h5 {
    font-size: 16px;
  }

  .sholarship_details .details-top .topright .row > div {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .sholarship_details .details-bottom h5 {
    font-size: 16px;
  }

  .sholarship_details .details-bottom p,
  .sholarship_details .details-bottom ul li {
    font-size: 12px;
    line-height: 22px;
  }

  .sholarship_details .details-top .topright .fouricon img {
    width: 45px;
  }

  .sugg-sholar h6,
  .sugg-sholar p {
    font-size: 11px;
  }

  /******** Package ********/
  .packagelist .item-package .card .card-header h5 {
    font-size: 16px;
  }

  .packagelist .item-package .card .card-header {
    padding: 25px 15px;
  }

  .packagelist .item-package .card .card-body ul li {
    font-size: 13px;
  }

  .packagelist .item-package:last-child {
    margin-top: 0px;
  }

  .postcard .postcard__text {
    padding: 15px 0;
  }

  .postcard__preview-txt {
    align-items: inherit;
    line-height: 22px;
  }

  .postcard__preview-txt > img {
    max-height: 55px;
  }

  .postcard .postcard__text h4 {
    font-size: 16px;
  }

  .choachwhat .counsellorlist .uni-item-list .item-media {
    padding: 0px;
  }

  .coach_slider .slick-list {
    margin: 0 5px;
  }

  .choachwhat .counsellorlist .uni-item-list .item-btn-more .btnbox {
    padding: 10px 15px;
  }

  .choachwhat .nav {
    overflow: hidden;
    overflow-x: auto;
    flex-wrap: nowrap;
  }

  .choachwhat .nav .nav-item {
    width: auto;
    margin-right: 10px;
  }

  .choachwhat .tab-content p {
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
  }

  /* .fields-wrp form .fieldbtn input:nth-child(2) {
        margin: 0px 0 0 0;
    } */
  .reviewlistall {
    padding: 0 10px !important;
  }

  /****** Steps ********/
  .form-container {
    padding: 0;
  }

  .form-container .headingmains h3 {
    font-size: 1.2rem;
  }

  .steps li::before {
    width: 25px;
    height: 25px;
    font-size: 14px;
    line-height: 26px;
  }

  .steps li.done::before {
    line-height: 26px;
  }

  .steps li::after {
    top: 11px;
    height: 5px;
  }

  .steps1 .radio-image li,
  .steps2 .radio-image li,
  .steps4 .radio-image li {
    margin: 0 0 20px 0;
    width: 48%;
  }

  .steps3 .radio-image li:first-child,
  .step5 .radio-image li,
  .radio-image.step5label li label {
    width: 100%;
  }

  .steps3 .radio-image li > span {
    width: 100%;
    height: 35px;
    border-radius: 5px;
  }

  .steps3 .radio-image li label {
    width: 80px;
    height: 80px;
    font-size: 11px;
  }

  .steps3 .radio-image li {
    font-size: 15px;
    margin: 6px 2px;
  }

  .steps6 ul.radio-image {
    max-width: 90%;
    width: 90%;
  }

  .form-container .headingmains h3 br {
    display: none;
  }

  .steps7 .radio-image li:first-child {
    width: auto;
  }

  .steps8 .radio-image li:first-child {
    width: 48%;
  }

  .steps9 h2 {
    padding-bottom: 20px;
  }

  .steps9 h5 {
    font-size: 15px;
  }

  /****** FAQs ********/
  /* .{overflow: hidden;
        overflow-x: hidden;
    overflow-x: auto;
    flex-wrap: nowrap;} */
  .faqtabs {
    display: block;
    padding: 0px;
  }

  .faqtabs .nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  .faqtabs .tab-content {
    width: 100%;
  }

  .faqtabs .tab-content .card .card-header .btn-header-link {
    padding: 10px 15px 10px 10px;
  }

  .faqpage .faqleft {
    min-height: auto;
    padding: 15px;
    background-size: 115px;
  }

  .faqpage .faqleft h1 {
    font-size: 22px;
    font-weight: 600;
  }

  .faqpage .faqleft .heading p {
    font-size: 13px;
    margin-right: 0;
    margin-top: 5px;
    line-height: 22px;
  }

  /***********/
  .profile_wrap .myprofile .nav {
    justify-content: normal;
  }

  .profile_wrap .myprofile .nav .nav-item {
    width: auto;
  }

  .profile_wrap .nav .nav-link {
    white-space: nowrap;
    margin: 0 5px;
  }

  .show_mobile {
    display: block !important;
  }

  .hide_desktop {
    display: none !important;
  }

  .uni_list_wrap {
    margin: -175px auto 25px auto;
  }
}

div#root {
  overflow: hidden;
}

.disabledClass {
  pointer-events: none;
  opacity: 0.6;
  color: #fff !important;
  border-radius: 5px;
}

.error {
  color: red;
  font-size: 16px;
}

/* i.awe-icon.awe-icon-check {
        margin: 0px 2px;
    }
     */

input {
  margin-right: 3px;
}

i.fa.fa-angle-down {
  margin-left: 3px;
}

svg {
  overflow: hidden;
  vertical-align: middle;
  margin-right: 3px;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  margin-right: 3px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

i.fa.fa-check-circle {
  margin-left: 3px;
}

.zeynep-opened body {
  overflow: auto;
}

.zeynep {
  right: auto;
  left: 0;
}

.zeynep {
  bottom: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  z-index: 1011;
}

.zeynep:not(.no-transition),
.zeynep .submenu:not(.no-transition) {
  transition: all 250ms;
}

.zeynep-overlay {
  background-color: rgba(0, 0, 0, 0.42);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
}

.zeynep.submenu-opened {
  overflow: hidden;
}

.zeynep .submenu {
  bottom: 0;
  min-height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
}

.zeynep .submenu.opened {
  left: 0;
  pointer-events: auto;
}

.zeynep .submenu.opened:not(.current) {
  overflow: hidden;
}

/* zeynepjs menu styles */

.zeynep {
  background-color: #ffffff;
  color: #404143;
  width: 295px;
}

.zeynep ul {
  list-style-type: none;
  padding: 0;
  padding-left: 0px;
  -webkit-overflow-scrolling: touch;
}

.zeynep ul > li {
  display: block;
}

.zeynep ul > li > a {
  color: inherit;
  display: block;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 20px;
  text-decoration: none;
  transition: all 150ms;
}

.zeynep ul > li > a:hover {
  background-color: #efefef;
  border-radius: 3px;
}

.zeynep ul > li:not(:last-child) > a {
  border-bottom: 1px solid #efefef;
}

.zeynep ul > li.has-submenu > a {
  background-image: url("../img/submenu-arrow.svg");
  background-position: right 20px center;
  background-repeat: no-repeat;
  background-size: 8px 14px;
}

.zeynep ul > li.has-submenu > a:hover {
  background-image: url("../img/submenu-arrow-active.svg");
}

/* zeynepjs submenu styles */

.zeynep .submenu {
  background-color: #ffffff;
  left: 295px;
}

.zeynep .submenu-header {
  background-image: url("../img/menu-back.svg");
  background-position: left 20px center;
  background-repeat: no-repeat;
  background-size: 8px 14px;
  border-bottom: solid 1px #efefef;
  cursor: pointer;
  position: relative;
}

.zeynep .submenu-header > a {
  color: inherit;
  display: block;
  font-size: 14px;
  font-weight: bold;
  padding: 18px 20px;
  padding-left: 40px;
  text-decoration: none;
  color: #fbb415;
}

.zeynep .submenu-header > a:before {
  background-image: url("../img/submenu-arrow.svg");
  background-position: right 20px center;
  background-repeat: no-repeat;
  background-size: 8px 14px;
  content: "";
  height: 100%;
  left: 26px;
  position: absolute;
  top: 0;
  transform: rotate(-180deg);
  width: 20px;
}

.zeynep .submenu > label {
  color: #127479;
  display: block;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 19px;
  opacity: 0.5;
  padding-left: 40px;
  width: 100%;
}

.zeynep-opened .zeynep-overlay {
  display: none;
}

.zeynep.opened {
  pointer-events: auto;
  transform: translateX(-100%);
}

@media only screen and (max-width: 767px) {
  .zeynep-opened .zeynep-overlay {
    display: block;
  }

  .zeynep.opened {
    pointer-events: auto;
    transform: translateX(0px);
  }

  .zeynep-opened body {
    overflow: hidden;
  }

  .application_wrap .left-contnt {
    margin-top: 0px;
    padding: 0;
    flex-wrap: wrap;
  }

  .application_wrap .right-contnt {
    text-align: left !important;
    margin-left: 0px;
  }

  .why-choose-ad .content-box p {
    padding-right: 0px;
    text-align: -webkit-left;
  }

  .counsellor h5:after {
    width: 14%;
  }
}

.uni-item-list .item-body .item-hotel-star .star-container {
  margin-top: -5px;
}

.secaccept svg {
  width: 65px;
}

.categoryWeb {
  position: relative;
  line-height: 26px;
  height: 27px;
  width: inherit;
  font-size: 11px;
  padding: 0px 0px 0px 8px;
  border: 2px solid #e8e8e8;
  border-radius: 7px;
}

.categoryWeb input[type="radio"] {
  display: none;
}

.categoryWeb1:hover {
  background-color: yellow;
  color: red;
  padding: 0px 15px;
}

.categoryWeb1 {
  padding: 0px 15px;
}

.cateresult {
  background-color: white;
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 26px;
  outline: 1px solid black;
}

.bg-caoch {
  transition: all 0.3s ease 0s;
  background: rgb(14, 40, 70, 1);
  background: -moz-linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#0e2846", endColorstr="#127b7e", GradientType=1);
  margin-bottom: 35px;
}

/* application css */

.application_wrap {
  padding-top: 20px;
}

.application-container {
  padding: 20px 20px;
}

.application_wrap .left-contnt {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.application_wrap .left-contnt .content-l h6:last-child {
  padding-top: 20px;
}

.application_wrap .left-contnt .content-l p {
  display: contents;
}

.application_wrap .left-contnt .content-l h6 span {
  font-size: 12px;
  color: #e2a522;
}

.application_wrap .right-contnt {
  text-align: right;
}

.application_wrap .right-contnt h6 {
  padding-top: 25px;
}

.application_wrap .right-contnt p {
  display: contents;
}

.application_wrap p {
  line-height: 26px !important;
}

.counsellorName h6 {
  cursor: pointer;
}

.tg-themetabnav a {
  color: black !important;
}

.menu_without_img ul li a {
  color: white !important;
}

.why-item .content-box p {
  min-height: 61px;
  margin-top: 6px;
}

.slick-disabled {
  background: none !important;
  cursor: unset !important;
}

.stepdisabled {
  pointer-events: none;
  opacity: 0.7;
}

.br-5 {
  border-radius: 5px;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  height: 50px !important;
  width: 100%;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: red !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  /* background-color: red !important; */
  color: #fff;
  background-color: #fde19a !important;
  background-image: -moz-linear-gradient(top, #fdd49a, #fdf59a) !important;
  background-image: -ms-linear-gradient(top, #fdd49a, #fdf59a) !important;
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#fdd49a),
    to(#fdf59a)
  ) !important;
  background-image: -webkit-linear-gradient(top, #fdd49a, #fdf59a) !important;
  background-image: -o-linear-gradient(top, #fdd49a, #fdf59a) !important;
  background-image: linear-gradient(top, #fdd49a, #fdf59a) !important;
  background-repeat: repeat-x !important;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0) !important;
  border-color: #fdf59a #fdf59a #fbed50 !important;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25) !important;
  filter: progid: DXImageTransform.Microsoft.gradient(enabled=false) !important;
  color: #000 !important;
}

.profilehead h5 {
  text-transform: capitalize !important;
}

.fields-wrp form .field .react-datepicker__input-container input:valid {
  border: 1px solid lightgray;
}

.fields-wrp form .field input:focus {
  border-color: #fbb415 !important;
}

/* .fields-wrp form .field input:focus~label, .fields-wrp form .field input:valid~label {
    top: 0% !important;
    font-size: 16px !important;
    left: 20px !important;
    font-size: 12px !important;
    padding: 0 3px !important;
    color: #FBB415 !important;
    background: #fff !important;
    transform: translateY(-50%) !important;
} */

.fields-wrp form .field input:focus ~ label {
  top: 0% !important;
  font-size: 16px !important;
  left: 20px !important;
  font-size: 12px !important;
  padding: 0 3px !important;
  color: #fbb415 !important;
  background: #fff !important;
  transform: translateY(-50%) !important;
}

.searchuniversitiesandcourses {
  position: absolute;
  background-color: white;
  left: 0;
  right: 0;
  top: 34px;
  z-index: 99999;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.searchuniversities {
  position: absolute;
  background-color: white !important;
  left: 15px;
  right: 15px;
  top: 71px;
  border: 1px solid #ddd;
  z-index: 99999;
}

.searchuniversities ul li {
  /* padding-left: 17px; */
  padding: 5px 5px 5px 17px;
  display: block;
  cursor: default;
}

.searchuniversities ul li:hover {
  background: #f2f2f2;
}

.searchuniversitiesandcourses ul li {
  padding: 6px 15px;
  display: block;
  cursor: pointer;
  margin-right: 0px;
}

.searchuniversitiesandcourses ul li:hover {
  /* padding-left: 17px;
    display: block; */
  background: #f2f2f2;
}

.content-box.counsellorName p:nth-child(5) {
  height: 61px;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-box.counsellorName h6 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.widget.counsellor.blogrecent.shade2 .bg-white p {
  width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pl-50 {
  padding-left: 50px !important;
}

figure.feat-text1.mb-0.blog_list {
  height: 210px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

figure.feat-text1.mb-0.blog_details {
  height: 500px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.resent_blog {
  border-radius: 50%;
  margin-right: 5px;
  height: 20px !important;
  width: 20px !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.counsellorAneCoachCover {
  border-radius: 10px;
  /* margin-right: 5px; */
  margin: 0px auto;
  position: relative;
  height: 161px !important;
  width: 193px !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.counsellorAneCoach_details {
  border-radius: 10px;
  height: 290px !important;
  width: 100% !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.relatedCounsellor {
  border-radius: 10px;
  margin-right: 5px;
  border-radius: 50%;
  height: 35px !important;
  width: 35px !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.counsellorBox {
  width: 35px !important;
  margin-right: 5px;
}

.counsellorAndcoachHome {
  border-radius: 10px;
  margin: 20px auto;
  border-radius: 25px;
  background: red;
  height: 150px !important;
  width: 170px !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.stdComment p:nth-child(5) {
  min-height: 120px;
}

.blog-content li {
  margin-bottom: 1rem;
  font-size: 15px !important;
}

.headblog {
  max-width: 815px;
}

.headblog strong {
  font-weight: 700;
  font-size: 17px;
}

.blog-content {
  font-size: 15px;
}

.divroted {
  transform: rotate(4deg);
  width: 335px;
  height: 30px;
  background: white;
  position: absolute;
  left: -2px;
  bottom: -19px;
}

@media (max-width: 767px) {
  figure.feat-text1.mb-0.blog_details {
    height: 400px !important;
  }
}

@media (max-width: 480px) {
  figure.feat-text1.mb-0.blog_details {
    height: 300px !important;
  }
}

@media only screen and (min-device-width: 340px) and (max-device-width: 767px) {
  .divroted {
    transform: rotate(3deg);
    width: 579px;
    height: 30px;
    background: white;
    position: absolute;
    left: -2px;
    bottom: -20px;
  }
}

/* @media (min-width:340px){
        .divroted {
            transform: rotate(
        2deg);
            width: 790px;
            height: 30px;
            background: white;
            position: absolute;
            left: -2px;
            bottom: -23px;
        }
        } */

.reviews_item.disPro h6 p {
  float: none;
  color: #fcbd30;
  font-size: 16px;
}

img.uniLogody {
  width: 130px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: unset;
}

.scale.hide_desktop {
  height: 500px;
}

/* .uni_banner_upr{
        background: #303c44b3;
    } */

.image-cover.uni-listupr img {
  height: 195px !important;
  width: 100% !important;
}

.image-cover-uni-listupr-noimg {
  background: #f7f7f7;
}

.input-group-append {
  margin-left: -3px;
}

i.fa.fa-thumbs-o-up.active {
  color: #fcbd30;
}

button.btn.btn-secondary.discuDiseble {
  pointer-events: none;
  opacity: 0.8;
}

.item-abouttxt.coachAndCounselloer td {
  vertical-align: baseline;
  /* margin-right: 19px; */
  padding: 0px 2px;
  vertical-align: middle;
}

/* @media only screen and (min-device-width: 767px) and (max-device-width: 1140px) {
    .counsellorlist .uni-item-list .item-media button {
        border: 0px;
        background-color: transparent;
        position: absolute;
        right: 18px;
        bottom: 5px;
        top: 108px; 
    }
  } */

@media only screen and (max-width: 540px) {
  .slidercont img {
    max-width: 100% !important;
    width: 100%;
    height: 120px !important;
  }
}

.view__:hover {
  text-decoration: underline;
}

.mobile_intake {
  display: none;
}

.country_flag {
  width: 40px;
}

.country_text {
  font-size: 15px;
  color: red;
}

@media only screen and (max-width: 767px) {
  .slidercont img {
    max-width: 100% !important;
    width: 100%;
    height: 230px !important;
  }

  .uni_intake {
    display: none;
  }

  .mobile_intake {
    display: block !important;
  }

  .t-banner {
    margin-top: 155px !important;
  }

  .mob_mt-40 {
    margin-top: 40px;
  }

  .image-cover.uni-listupr img {
    height: 242px;
    width: 100% !important;
  }

  .uni-item-list .item-body .item-footer {
    margin-top: 3px;
    overflow: hidden;
  }

  .blog-content table {
    font-size: 11px;
  }

  .headblog h1 {
    font-size: 21px;
    font-weight: 700;
  }

  h2.pb-2.bloghead2 {
    font-size: 18px;
  }

  .blog-content h2 {
    font-size: 18px;
  }

  .blog-content p {
    font-size: 13px !important;
  }
}

.blog-content ul {
  list-style: initial;
  padding-left: 40px;
  margin: 0;
}

.blog-content table {
  width: 100%;
}

p.profileeva {
  float: none !important;
}

.searchuniversitiesandcourses ul {
  text-align: start;
}

@media only screen and (max-width: 1140px) {
  .searchuniversitiesandcourses {
    position: absolute;
    background-color: white;
    left: 0;
    right: 0;
    top: 28px;
    z-index: 99999;
    border: 1px solid #ddd;
    border-radius: 10px;
  }
}

.userProfilebtn {
  color: #fff !important;
  border: none;
  padding: 5px 35px;
  transition: all 0.3s ease;
  border-radius: 5px;
  /* width: 100px; */
  cursor: pointer;
}

.faceIcon {
  position: relative;
}

.faceIcon .react-share__ShareButton:hover:after {
  content: "share on facebook";
  display: block;
  position: absolute;
  top: 30px;
  right: -16px;
  width: 100px;
  background: white;
  border: 1px solid black;
}

.faceIcon .react-share__ShareButton:hover {
  opacity: 0.8;
}

.tiwwIcon {
  position: relative;
}

.tiwwIcon .react-share__ShareButton:hover:after {
  content: "share on twitter";
  display: block;
  position: absolute;
  top: 30px;
  right: -16px;
  width: 100px;
  background: white;
  border: 1px solid black;
}

.tiwwIcon .react-share__ShareButton:hover {
  opacity: 0.8;
}

.linkIcon {
  position: relative;
}

.linkIcon .react-share__ShareButton:hover:after {
  content: "share on linkedIn";
  display: block;
  position: absolute;
  top: 30px;
  right: -16px;
  width: 100px;
  background: white;
  border: 1px solid black;
}

.linkIcon .react-share__ShareButton:hover {
  opacity: 0.8;
}

.whatsIcon {
  position: relative;
}

.whatsIcon .react-share__ShareButton:hover {
  opacity: 0.8;
}

.whatsIcon .react-share__ShareButton:hover:after {
  content: "share on whatsapp";
  display: block;
  position: absolute;
  top: 30px;
  right: -7px;
  width: 100px;
  background: white;
  border: 1px solid black;
}

.row.bg-grey-light.px-4.reviewlistall.blogComm {
  text-align: start;
  margin: 0px 0px;
}

.row.bg-grey-light.px-4.reviewlistall.blogComm img {
  width: auto;
  margin-bottom: 4px;
  cursor: pointer;
}

.blog-item .iconscomments li i {
  font-size: 15px;
}

.nodataFound {
  padding: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 30vh; */
}

.nodataFound img {
  width: 100%;
}

/* .collapse.navbar-collapse.tg-navigation.main-menu ul li:last-child a {
 
        transform: skew(-5deg, 0deg, 0deg, -20deg) !important;
          transition: all 0s ease-in-out;
          background: red;
      }


 .collapse.navbar-collapse.tg-navigation.main-menu ul li:last-child a:hover {

  transform: skewX(0deg);
    transition: all 0s ease-in-out;
} */
.loadingPay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: #00000087;
  /* background-color: rgba(255,255,255,0.7); */
}

.loadingPage {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  /* background-color: #00000087; */
  background-color: white;
}

.scale.trandingUniImg {
  height: 260px !important;
  cursor: pointer;
}

/* img.scale.trandingUnilogo {
    border-radius: 50%;
} */

.offerHeight a:hover {
  background: #fff;
}

a.awe-btnbook.download.brochuredis {
  opacity: 0.7;
  cursor: not-allowed;
}

.blog-content img {
  width: 100% !important;
}

.error-class {
  color: red;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}

.left-contnt.profileAplicant img {
  height: 120px;
  margin-right: 10px;
}

.reviews_item.blogComment h6 {
  margin-bottom: 0px;
}

.reviews_item.blogComment p {
  line-height: 13px;
}

.scale.homeOffer {
  height: 235px;
}

.documets_section {
  padding: 30px 30px 30px 60px;
  box-shadow: 0 0 6px #ccc;
}

.documets_section .standard {
  border-bottom: 2px dotted #047475;
  padding: 10px 10px;
  display: flex;
}

.documets_section .standard .sta_name {
  color: #047475;
  font-size: 16px;
  flex: 1;
}

.documets_section .standard ul {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.documets_section .standard ul li {
  margin-left: 10px;
}

.documets_section .standard ul li a {
}

.documets_section .standard ul li a img {
  max-width: 50px;
}

.standard input {
  width: 50px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.docuUpload {
  position: relative;
}

/* .docuUpload img{
    position: absolute;
    left: 0;
    top: 0;

} */

.scale.homeStudyCourses {
  height: 191px;
}

.homeunire {
  cursor: pointer;
}

.content-box.studyDe {
  position: relative;
}

.studyDe h6 {
  position: relative;
}

.content-box.studyDe:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 62px;
  background-color: #fff;
  transform: skew(0deg, -7deg);
  top: -47px;
  left: 0;
}

.studyslider
  .slick-list
  .slick-track
  .slick-slide:nth-child(odd)
  .content-box.studyDe:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 62px;
  background-color: #fff;
  transform: skew(0deg, 7deg);
  top: -47px;
  left: 0;
}

/* .headerulStream{
            overflow: hidden;
        } */

/* .tg-themetabnav{
            overflow: scroll;

        }

        .tg-themetabnav li{
            overflow: scroll;

        } */

.headerulStream {
  max-height: 250px;
  overflow: auto;
}

.clear-filter.filDisNone {
  background: #eaeaea;
  padding: 0px !important;
}

.countryHide {
  padding: 0px !important;
}

img.suggestedSholarship {
  border-radius: 50%;
  border: 1px solid #feb50f;
}

.offers-item.yello.title h3 {
  position: relative;
}

.content-box.offerHeight {
  height: 150px;
}

.content-box.offerHeight_ {
  height: 89px;
}

.ScholarshipAboutimg {
  background: url("../img/noImage.jpeg");
  width: 100%;
  height: 260px;
  background-size: contain !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  padding: 0px 0px 0px 0px;
  /* margin-left: 15px;
                margin-right: -15px; */
}

.a-s-b {
  margin: auto;
  margin-top: 20px;
  width: max-content;
}

.unishareIcon {
  text-align: center;
}

.sharebtncos {
  background: linear-gradient(
    180deg,
    rgba(14, 40, 70, 1) 0%,
    rgba(18, 123, 126, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#0e2846", endColorstr="#127b7e", GradientType=1);
  color: #fff;
  width: max-content;
  padding: 5px 27px;
  border-radius: 5px;
  margin: auto;
  cursor: pointer;
}

.shareLinkInModel {
  padding: 20px;
}

.scale.applyCourse {
  height: 29px;
}

.scale.applycountry {
  height: 52px;
}

.countrySlider .content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.exam-box {
  margin: 0px 0px 30px 0px;
  padding: 30px;
  box-shadow: 0 0 6px #ccc;
  border-radius: 10px;
  /* height: 180px; */
  border: 1px solid #007684;
}

.exam-box:hover {
  border: none;
  box-shadow: 0 0 50px #ccc;
}

button.slick-next {
  background: #047475;
  border-radius: 50%;
  margin: 0 -45px;
  width: 55px;
  height: 55px;
}

button.slick-prev {
  background: #047475;
  border-radius: 50%;
  margin: 0 -42px;
  width: 55px;
  height: 55px;
}

.articals {
  display: flex;
  justify-content: center;
  row-gap: 25px;
  column-gap: 25px;
  flex-wrap: wrap;
  align-items: stretch;
}

.articals .artical-items {
  padding: 10px;
  max-width: 150px;
}

.articals .artical-items .icon {
  max-width: 80px;
  height: auto;
  margin-bottom: 15px;
}

.articals .artical-items .text {
  font-size: 14px;
  font-weight: 500;
}

.articals .artical-items:hover .text {
  font-weight: 700;
}

.c_course_list {
  display: flex;
  justify-content: center;
  align-items: stretch;
  row-gap: 40px;
}

.country_course .course_icon {
  max-width: 100px;
  height: auto;
  margin-bottom: 15px;
}

.country_course h4 {
  font-size: 20px;
  font-weight: 600;
}

.country_course p {
  font-size: 14px;
  margin-top: 8px;
}

.search_slider_box i {
  color: #fff;
  font-size: 2.33em;
}

.search_slider_box .pl-5 {
  padding-left: 5rem !important;
}

.coursefilter-page {
  margin-top: 30px;
}

/* .course_item {
         padding:30px 20px;
     }
     .course_item_box {
         box-shadow: 0px 0px 30px #ccc;
     }
     .course_item_box .bgstudy{
        background: linear-gradient(
        77deg
        , rgba(4, 116, 117, 1) 0%, rgba(1, 19, 47, 1) 100%);
    } */

.t-banner {
  margin-top: 169px;
}

.t-banner-img {
  position: relative;
}

.t-banner-img img {
  width: 100%;
}

.tag-l {
  top: 35%;
  position: absolute;
  bottom: 0px;
  color: #fbb415;
  padding: 0px 25px;
}

.tag-l h1 {
  color: #fbb415;
}

.tag-l p {
  color: #fff;
  font-size: 16px;
}

.tag-term {
  margin-top: 190px;
}

.terms-s p {
  padding: 5px 25px;
}

.terms-s p {
  font-size: 15px;
  line-height: 30px;
  color: #555;
}

.condition-list {
  margin: 10px;
}

.condition-list li::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #127a7d;
  border-radius: 50%;
  left: 0;
  bottom: 0;
  display: inline-block;
  margin: 0px 10px;
}

.terms-r p {
  font-size: 15px;
  line-height: 25px;
  padding: 5px 25px;
  color: #555;
}

.terms-r ul {
  padding: 5px 25px;
}

.terms-r li {
  font-size: 15px;
  line-height: 25px;
  color: #555;
}

.terms-r a {
  color: #127479;
}

.terms-r s {
  color: #127479;
}

@media only screen and (max-width: 600px) {
  .terms-r ul {
    padding: 5px 0px !important;
  }

  .terms-r p {
    padding: 5px 0px !important;
  }

  .tag-l {
    display: none;
  }
}

.privacy-p p {
  font-size: 15px;
  line-height: 25px;
  color: #555;
}

.privacy-p li {
  font-size: 15px;
  line-height: 25px;
  color: #555;
}

.privacy-p p {
  padding: 5px 25px;
}

.privacy-p ul {
  padding: 5px 25px;
}

.order-privacy {
  color: #127a7d;
  padding: 10px;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .privacy-p p {
    padding: 5px 15px;
  }

  .privacy-p ul {
    padding: 5px 15px !important;
  }
}

.ofc_add {
  margin: 20px auto;
  width: 100%;
}

.add-detail {
  padding: 10px 0px;
}

.add-detail h1 {
  font-size: 16px;
  color: #fcbd30;
}

.add-detail p {
  font-size: 15px;
}

.c-tag {
  color: #fcbd30;
  font-size: 16px;
}

.c-info {
  padding-bottom: 10px;
}

.q-msg {
  width: 100%;
  border-color: lightgrey;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 15px;
  font-family: inherit;
  color: #000;
}

.q-msg:focus-visible {
  border: 1px solid #fbb415;
  outline: none;
}

.q-sub-btn {
  color: #fff;
  padding: 10px 25px;
  border-radius: 5px;
  border: none;
  margin-top: 80px;
  margin-bottom: 20px;
}

.get-touch:after {
  content: "";
  border-bottom: 4px solid #fbb415;
  width: 15%;
  margin-top: 5px;
  display: block;
}

.modal-cross {
  margin-right: 10px;
  margin-top: 10px;
}

.w-100 {
  width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

.mt-100 {
  margin-top: 100px;
}

.q-box-bg {
  min-height: 430px !important;
}

@media only screen and (max-width: 600px) {
  .mob-mb-25 {
    margin-bottom: 25px;
  }

  .tag--mob-cn {
    left: 0px !important;
    text-align: center;
    width: 100%;
    top: 15%;
  }

  .tag--mob-cn p {
    display: none;
  }
}

.about-cont {
  box-shadow: 0 0 6px #ccc;
  padding: 20px;
  text-align: center;
}

.about-cont p {
  font-size: 15px;
  line-height: 30px;
}

.aco-bg {
  /* background: url(./assets/img/about-wavebg.png) top center no-repeat; */
  background-size: 100% 100%;
  padding: 80px 0px 100px 0;
}

.aco-c p {
  font-size: 15px;
  text-align: center;
  line-height: 25px;
  padding: 0px 40px;
}

.exc-tab {
  background: #fff;
  box-shadow: 0 5px 15px 0 #b8e2fe;
  -webkit-box-shadow: 0 5px 10px 0 #b8e2fe;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 10px;
}

.exclusives {
  height: 220px !important;
}

.exc-tab img {
  padding-top: 10px;
}

.exc-tab p {
  padding: 15px 20px;
}

.coaching_img {
  margin: 5% 5% 5% auto !important;
  width: 60% !important;
}

.coach-l {
  margin-top: 50px;
}

.coach-l li {
  line-height: 36px;
}

.coach-l li::before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #116871;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.exc-tab-box {
  line-height: 22px;
  padding: 5px 10px;
  height: 220px !important;
}

.exc-tab-box:hover {
  box-shadow: 0 10px 20px 0 #b8e2fe;
}

@media only screen and (max-width: 600px) {
  .aco-bg {
    background-size: cover;
  }

  .aco-c p {
    padding: 0px 15px;
  }

  .tag--mob-cn {
    left: 0px !important;
    text-align: center;
    width: 100%;
    top: 15%;
  }

  .tag--mob-cn p {
    display: none;
  }

  .exc-tab-box {
    height: auto !important;
  }
}

.career-c {
  margin-top: 20px;
}

.career-p {
  font-size: 17px;
  padding: 0px 30px;
  text-align: center;
}

.culture {
  color: #fff;
  margin: 0;
  padding: 0;
  background: transparent
    linear-gradient(90deg, #114a82, #195b88 20%, #43b0a8 46%, #6fe2f4) 0 0
    no-repeat padding-box;
  width: 100%;
  padding-bottom: 50px;
}

.culture-head {
  width: 100%;
  margin: auto;
  padding: 25px;
}

.culture-head h1 {
  font-size: 1.5rem;
  text-align: center;
}

.culture-f-img {
  width: 100%;
  border-radius: 21px;
}

.culture-right-c {
  font-size: 14px;
  line-height: 30px;
  text-transform: lowercase;
}

.opening-sec {
  background-color: #fff;
}

details {
  width: 100%;
  min-height: 5px;
  max-width: 700px;
  padding: 10px 20px 15px 20px;
  position: relative;
  font-size: 22px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  box-sizing: border-box;
  transition: all 0.3s;
}

details + details {
  margin-top: 20px;
}

details[open] {
  min-height: 50px;
  background-color: #f6f7f8;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
}

details p {
  color: #000;
  font-weight: 300;
  font-size: 16px;
}

summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
}

summary:focus {
  outline: none;
}

summary:focus::after {
  content: "";
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

summary::-webkit-details-marker {
  display: none;
}

.control-icon {
  fill: #000;
  transition: 0.3s ease;
  pointer-events: none;
}

.control-icon-close {
  display: none;
}

details[open] .control-icon-close {
  display: initial;
  transition: 0.3s ease;
}

details[open] .control-icon-expand {
  display: none;
}

.c-que {
  background-color: #f8f8fe;
  padding: 20px 30px;
  margin: 20px 0px;
}

.c-que h1 {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  line-height: 50px;
}

.c-que h2 {
  font-size: 17px;
  font-weight: 400;
  color: #000;
  margin-bottom: 20px;
}

.apply-bx {
  box-shadow: 0px 0px 10px 1px #ddd;
  margin: 20px 0px;
  padding: 20px 10px;
}

.choose-f {
  border: 1px solid #ddd;
  border-radius: 5px;
}

input[type="file"] {
  cursor: pointer;
  margin-top: 10px;
  border-color: transparent !important;
}

.border__ {
  border-color: #fbb415 !important;
}

.upload-r {
  font-size: 15px !important;
  padding-left: 40px;
  color: #999;
}

.c-select:hover {
  cursor: pointer;
}

.c-select selected {
  color: red;
}

.note__ {
  color: red;
  font-size: 11px;
}

.b-10 {
  font-size: 10px;
}

.apply-n:after {
  content: "";
  border-bottom: 4px solid #fbb415;
  width: 15%;
  margin-top: 8px;
  display: block;
}

.apply-sub-btn {
  color: #fff;
  padding: 15px 25px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
  .culture-f-img {
    margin: 10px 0px;
  }

  .c-que {
    padding: 20px 18px;
  }

  .tag-l {
    display: non;
  }
}

.r-more {
  box-shadow: none !important;
  display: inline !important;
  color: #fbb415;
  padding: 0px 5px !important;
}

.box-none {
  box-shadow: none !important;
}

.box-zoom {
  padding: 0px 0px 20px 0px;
}

.box-zoom:hover {
  box-shadow: 0px 0px 10px #127a7e;
  transition: all 0.3s ease-in-out;
}

.service-c {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.condition-list-1 {
  margin: 10px;
}

.condition-list-1 li::before {
  font-family: "FontAwesome";
  width: 10px;
  height: 10px;
  color: #127a7d;
  border-radius: 50%;
  left: 0;
  bottom: 0;
  display: inline-block;
  margin: 0px 15px;
  content: "\f0a4";
  font-weight: 500;
}

.trending-img {
  vertical-align: middle;
  border-style: none;
  max-height: 160px;
  text-align: center;
}

.terms-r p {
  padding: 5px 25px;
}

.terms-r ul {
  padding: 5px 25px;
}

.service-funding {
  width: 30px;
}

.forheading-style {
  background-color: #fcbd30;
  padding: 15px 27px 13px 5px;
  border-radius: 0 55px 0 0;
}

.card-head1 {
  padding: 0px !important;
}

.postcard__text1 {
  margin-bottom: auto !important;
}

.postcard-n1 {
  flex-direction: row-reverse;
}

.ft-15 {
  font-size: 15px;
}

.postcard__img-new {
  width: 45% !important;
}

.border-bt:before {
  content: "";
  width: 60px;
  height: 2px;
  background: #fcbd30;
  display: block;
  margin-bottom: 10px;
}

.q-msg {
  width: 100%;
  border-color: lightgrey;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 15px;
  font-family: inherit;
  color: #000;
}

.q-msg:focus-visible {
  border: 1px solid #fbb415;
  outline: none;
}

.q-sub-btn {
  color: #fff;
  padding: 10px 25px;
  border-radius: 5px;
  border: none;
  margin-top: 80px;
  margin-bottom: 20px;
}

.get-touch:after {
  content: "";
  border-bottom: 4px solid #fbb415;
  width: 15%;
  margin-top: 5px;
  display: block;
}

.q-box {
  margin: 0px 5px;
}

.modal-cross {
  margin-right: 10px;
  margin-top: 10px;
}

.w-100 {
  width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

.mt-100 {
  margin-top: 100px;
}

.q-box-bg {
  min-height: 430px !important;
}

@media only screen and (max-width: 600px) {
  .terms-r p {
    padding: 5px 0px !important;
  }

  .terms-r ul {
    padding: 5px 0px !important;
  }

  .tag-l {
    display: none;
  }

  .postcard__img-new {
    width: 100% !important;
  }

  .mob-mb-25 {
    margin-bottom: 25px;
  }

  .tag--mob-cn {
    left: 0px !important;
    text-align: center;
    width: 100%;
    top: 15%;
  }

  .tag--mob-cn p {
    display: none;
  }
}

.pt-50 {
  padding-top: 50px;
}

.pb-10 {
  padding-bottom: 10px;
}

.f-size {
  font-size: 14px;
}

.country_uni_ {
  background-color: #fafafa !important;
  top: 48px !important;
  padding: 10px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99999;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: left;
  line-height: 23px;
}

.country_uni_ ul li:hover {
  background: #e7e2e2;
  cursor: pointer;
}

@media (min-width: 576px) {
  .video-model {
    max-width: 565px !important;
    margin: 1.75rem auto;
  }
}

.course-head {
  color: #fbb415 !important;
  font-weight: 600;
}

.course-n {
  color: #fcbd30;
  padding-bottom: 0px;
  position: relative;
  font-size: 20px;
}

.course-n-pd {
  padding: 15px !important;
}

.course-n-pd p {
  padding: 0;
}

.c-special li {
  font-size: 15px;
}

.c-special li::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #127a7d;
  border-radius: 50%;
  left: 0;
  bottom: 0;
  display: inline-block;
  margin: 0px 10px;
}

.__hover:hover {
  border-bottom: 1px solid #999;
}

.cFaq__ .nav .nav-link.active {
  background-color: #fcbd30 !important;
  box-shadow: 0 5px 3px #d3d3d3;
  font-weight: 500;
  color: #555555;
}

.__vblog {
  padding: 15px;
  box-shadow: 0 0 10px #ccc;
  line-height: 20px;
}

.vblog-text {
  font-size: 14px;
  line-height: 20px;
}

.__vblog iframe .place-card {
  display: none !important;
}

.__vblog iframe {
  width: 100%;
  height: 266px;
}

.apply-now-checkbox {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.apply-now-label {
  font-size: 13px;
  vertical-align: top;
  font-weight: 500;
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: 0;
  color: rgba(4, 116, 117, 1);
}
.applied-label {
  font-size: 13px;
  vertical-align: top;
  font-weight: 500;
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: 0;
  color: rgba(4, 116, 117, 1);
}

.wrap .top-section img {
  width: 100%;
}

.app_max_ {
  color: #d20e00;
}

.app_now_head {
  font-size: 22px;
  font-weight: 500;
}

.app_now_ {
  padding: 25px 40px;
  text-align: center;
}

._submit_btn {
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 400 !important;
  border-radius: 5px;
}

._app_add_m {
  cursor: pointer;
  border: 1px solid #ddd;
  padding: 6px 5px;
  margin-left: 15px;
  border-radius: 5px;
}

.modal {
  background: rgb(0 0 0 / 42%);
}

.more-courses-ap:hover {
  text-decoration: underline;
}

.more-courses-ap {
  cursor: pointer;
}

.appli-uni-name.hide {
  display: none;
}

img.jsx-2737925295.college-img {
  margin: auto;
  width: auto;
  height: inherit;
}

.day_available_img {
  vertical-align: text-top;
  margin-right: 5px;
}

.day_available {
  margin-left: 40px;
  margin-top: -15px;
  display: block;
}

.filter-courses-level {
  padding: 5px 0px 5px 0px;
  background: #eaeaea;
}

.error-failed {
  padding: 20px !important;
}

.error-failed .fa {
  font-size: 65px;
}

.postcard__preview-txt .p {
  font-size: 15px;
}

.text-white b {
  font-weight: 400 !important;
  color: #fff;
  font-size: 16px;
}

.yellow b {
  color: #fbb415;
  font-size: 36px;
  font-weight: 500;
  line-height: 1.5;
}

/***














****/

@media only screen and (max-width: 767px) {
  .c-special li {
    font-size: 11px;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  th,
  td {
    text-align: left;
    font-size: 11px;
    /* padding: 4px; */
  }

  .detailstudy table tr td img {
    padding: 6px 0px;
    position: absolute;
    left: 13px;
  }

  .detailstudy table tr td {
    font-size: 11px;
    text-align: left;
    display: flow-root;
    padding: 3px;
    margin-left: 32px;
  }

  .bgstudy h6 {
    font-size: 12px;
    color: #fff;
    font-weight: 400;
    line-height: 20px;
  }

  .counsellor_details .uni-item-list .item-abouttxt img {
    max-width: fit-content;
    position: absolute;
    display: inline-flex;
    text-align: center;
    padding: 6px 0px;
    left: 13px;
  }

  .counsellor_details .uni-item-list .item-abouttxt table tr td {
    font-size: 11px;
    text-align: left;
    display: flow-root;
    padding: 3px;
    margin-left: 32px;
  }

  img.scale.day_available_img {
    left: 30px !important;
    display: block !important;
  }

  .yellow.day_available {
    margin-top: 5px;
    margin-left: 35px;
  }

  .mob-avail {
    margin-left: 30px;
  }

  .item-abouttxt.coachAndCounselloer img {
    max-width: fit-content;
    position: absolute;
    display: inline-flex;
    text-align: center;
    padding: 6px 0px;
    left: 13px;
  }

  .item-abouttxt.coachAndCounselloer td {
    font-size: 11px;
    text-align: left;
    display: flow-root;
    padding: 3px;
    margin-left: 22px;
    vertical-align: baseline;
  }

  .uni-item-list .item-abouttxt img {
    max-width: fit-content;
    position: absolute;
    display: inline-flex;
    text-align: center;
    padding: 6px 0px;
    left: 13px;
  }

  .uni-item-list .item-abouttxt td {
    font-size: 11px;
    text-align: left;
    display: flow-root;
    padding: 3px;
    margin-left: 22px;
    vertical-align: baseline;
  }

  .ranking01 {
    width: 50%;
    position: absolute;
    top: 54px;
    right: 0px;
    padding: 3px 32px !important;
  }

  .country01 {
    width: 50%;
    position: absolute;
    top: 108px;
    right: 0px;
    padding: 3px 32px !important;
  }

  .top75 {
    top: 75px !important;
  }

  .top131 {
    top: 131px !important;
  }

  .top54 {
    top: 54px !important;
  }

  .top108 {
    top: 108px !important;
  }

  .main-logo {
    padding-top: 12px;
  }

  button.slick-prev {
    width: 40px;
    height: 40px;
  }

  button.slick-next {
    width: 40px;
    height: 40px;
  }

  .offerslider .slick-prev,
  .offerslider .slick-next {
    width: 40px;
    height: 40px;
  }

  .offerslider .slick-prev:before,
  .offerslider .slick-next:before {
    font-size: 38px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 38px;
  }

  .coachslider .slick-prev,
  .coachslider .slick-next {
    background: #fff;
    border-radius: 50%;
    margin: 0 -60px;
    width: 40px;
    height: 40px;
  }

  .coachslider .slick-prev:before,
  .coachslider .slick-next:before {
    font-size: 38px;
  }

  .coachslider button.slick-arrow.slick-prev,
  .studyslider button.slick-arrow.slick-prev {
    margin: 0px -34px !important;
  }

  .studyslider button.slick-arrow.slick-prev {
    margin: 0px -56px !important;
  }

  .studyslider .slick-next {
    margin: 0 -56px !important;
  }

  .coldir {
    flex-direction: column-reverse;
  }

  .headerProfile img {
    margin-right: 4px;
  }

  .userName {
    width: 80px;
  }

  .topbar-section:after {
    border-top: none !important;
    top: 42px;
  }

  .titlewithline01 {
    z-index: -1;
  }

  .search-sec {
    width: 157px !important;
  }

  .faqtabs .tab-content .card .card-header .btn-header-link {
    font-size: 14px;
  }

  .yellow b {
    font-size: 25px;
  }

  .tranding_slide .slick-next {
    margin-right: -38px !important;
  }

  .offerslider .slick-prev {
    margin: 0 -44px !important;
  }

  .h-servives .slick-prev {
    margin: 0px -45px !important;
  }

  .h-servives .slick-next {
    margin: 0px -59px !important;
  }

  .appli-box .steps li {
    margin-left: 4px;
    width: 20% !important;
  }

  .steps li:first-child.active:after {
    left: 70%;
  }

  .steps li:after {
    left: 34%;
  }

  .steps li:after {
    top: 11px;
  }

  .feat-inn span {
    font-size: 11px;
    padding: 0 5px;
    float: right;
    line-height: 26px;
    width: 100%;
    text-align: center;
  }

  .modal.show .modal-dialog {
    margin-top: 50%;
  }

  .exam-box {
    max-height: max-content;
  }

  .coun-flag1 {
    position: absolute;
    right: 24px;
    top: 16px;
    text-align: right !important;
  }

  .universitein li .imglogo {
    width: 57px;
    text-align: center;
  }

  .unitextwidth1 {
    width: unset;
  }

  .unitextwidth2 {
    width: unset;
  }
}

@media only screen and (max-width: 400px) {
  .social__mob {
    position: absolute;
    bottom: -21px;
    right: 85%;
  }

  .starbtnsite .wishlist {
    font-size: 11px;
  }

  .uni-details .content .btncwrp {
    margin-top: 5px;
  }

  /* .counsellor_details .item-abouttxt {
        margin-top: 20px;
    } */
  .uni-item-list .item-abouttxt span {
    font-size: 10px;
  }

  .coach_slider .slick-list .slick-slide {
    padding: none !important;
  }

  .aco-bg {
    padding: 0px;
  }

  .counsellorlist .item-abouttxt {
    width: 100%;
  }

  /* .uni-item-list .item-abouttxt img {
        max-width: fit-content;
        position: absolute;
        top: 50%;
        margin-left: 10px;
    } */
  .course__ {
    left: 0;
  }

  /* .course__font {
        font-size: 11px !important;
    } */
  .exam-box {
    height: auto !important;
  }

  .uni-item-list .item-title h5 {
    font-size: 18px;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .choachwhat .nav {
    overflow: hidden;
    overflow-x: auto;
    flex-wrap: nowrap;
    padding: 13px 0px;
  }

  .uni-item-list .counsellor01 img {
    max-width: -moz-fit-content;
    max-width: fit-content;
    position: absolute;
    display: inline-flex;
    text-align: center;
    padding: 6px 0px;
    left: 13px;
  }
}

.uniimg {
  border-radius: 16px;
  border: 1px solid #ccc;
  max-width: 113px;
  min-height: 113px;
  padding: 5px;
}

.uniimg img {
  max-width: 100px;
  height: 100px;
  border-radius: 10px;
}

.topuni {
  padding: 20px 0px;
}

.topuni h4 {
  font-size: 26px;
  color: #fff;
}

.firstunisec {
  color: #fff;
  padding: 20px;
  background-color: #167e80;
}

.uninam p {
  font-size: 13px;
  padding-top: 10px;
}

.mt-25 {
  margin-top: 25px;
}

.unilistpage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.gettouchform {
  border-radius: 16px;
}

.border-bt01:before {
  content: "";
  width: 95px;
  height: 2px;
  background: #fcbd30;
  display: block;
  position: absolute;
  right: 0px;
  margin: 0px auto;
  left: 0px;
  top: 66;
}

.postcard__preview-txt01 {
  text-align: center;
  font-size: 1.1rem;
}

.uninam {
  text-align: center;
}

.uninam strong {
  font-size: 16px;
}

.postcard__text01 {
  position: relative;
}

.landing-tag {
  top: 35%;
  position: absolute;
  bottom: 0px;
  color: #fbb415;
  padding: 0px 25px;
  text-align: center;
  width: 100%;
  left: 0;
}

.landing-tag h1 {
  color: #fff;
  margin-bottom: 10px;
}

.landing-tag button {
  border-radius: 6px;
}

.disableApply {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

/* tab-air */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .service-category {
    height: 370px !important;
  }

  .service-category {
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .header {
    padding: 64px 0 20px;
  }

  .main-logo img {
    max-width: 140px;
    width: 175px;
  }

  .tg-navigationarea {
    flex: 0 0 auto;
    order: 2;
    position: absolute;
    width: auto;
    max-width: 100%;
    right: 12px;
  }

  .tesi-wrap .team_member {
    height: 523px;
  }

  .why-choose-ad .content-box h5 {
    font-size: 1.1rem !important;
  }

  .why-choose-ad .content-box p {
    padding-right: 0px !important;
  }

  .why-choose-ad {
    min-height: 325px !important;
  }

  .why-choose-ad .content-box {
    padding: 0px 10px 6px 10px;
    min-height: 41%;
  }

  .uni_list_wrap {
    margin: -77px auto 25px auto;
    max-width: 100%;
  }

  .postcard__img-new {
    width: 100% !important;
  }

  .ad-slider {
    margin: 40px auto;
  }

  .detailstudy table tr td {
    font-size: 11px;
    text-align: left;
    display: flow-root;
    padding: 3px;
    margin-left: 32px;
  }

  .detailstudy table tr td img {
    padding: 6px 0;
    position: absolute;
    left: 13px;
  }

  .add-detail {
    min-height: 270px;
    text-align: left;
    padding: 11px 9px;
  }

  .exc-tab-box {
    height: 228px !important;
  }

  .singleslider .slick-prev,
  .singleslider .slick-next {
    margin-top: 0px;
  }

  .counsellorlist .item-abouttxt {
    width: 100%;
  }

  .uni-item-list .item-abouttxt img {
    max-width: 30px;
  }

  .faqpage .faqleft .heading p {
    margin-right: 0px;
    font-size: 15px;
    line-height: 26px;
  }

  .faqpage .faqleft {
    background-size: 100% 90%;
    padding: 42px;
  }

  .t-banner-img img {
    min-height: 170px;
  }

  .tag-l {
    top: 20%;
  }

  .tag-l br {
    display: none;
  }

  .offer_sholarship .offers_list .offers-item {
    width: 100%;
  }

  .offer_sholarship .offers_list .offers-item .content-box::before {
    top: -17px;
  }

  .offer_sholarship .offers_list .offers-item h3 {
    font-size: 25px;
  }

  .offer_sholarship .offers_list .offers-item h3 span {
    font-size: 36px;
    line-height: 38px;
  }

  .offer_sholarship .offers_list .offers-item .mt_btn_yellow {
    font-size: 14px;
    padding: 6px;
  }

  .offer_sholarship .offers_list .offers-item .content-box a.tnc {
    font-size: 17px;
  }

  .sholarship-wrp .s-item {
    width: 100%;
  }

  .postcard {
    flex-wrap: wrap;
  }

  .studywrp .bgstudy,
  .bggreadent {
    min-height: 40px !important;
  }

  .detailstudy {
    min-height: 350px;
  }

  .coldir {
    flex-direction: column-reverse;
  }

  .mtop-20 {
    margin-top: 20px;
  }

  .c-que details {
    max-height: 400px;
    overflow: auto;
  }

  .c-que h1 {
    font-size: 17px;
  }

  .c-que h2 {
    font-size: 16px;
  }

  .c-que {
    padding: 10px 14px;
  }

  .choose-f input {
    padding-left: 44px !important;
    font-size: 14px !important;
  }

  .fields-wrp form .field input,
  .fields-wrp form .field select {
    padding-left: 40px;
    font-size: 14px;
  }

  .apply-sub-btn {
    padding: 10px 27px;
    min-height: 38px !important;
  }

  .country_uni_ {
    overflow: auto;
    max-height: 120px;
  }

  .yellow b {
    font-size: 25px;
  }

  .singleslider .slick-prev,
  .singleslider .slick-next {
    margin: 0 -48px;
  }

  .counsellor_details .uni-item-list .item-abouttxt > table {
    margin-top: 12px;
  }

  .tesi-wrap .team_member p,
  .videobx {
    min-height: 265px;
  }

  .fields-wrp form .field input:focus ~ label,
  .fields-wrp form .field input:valid ~ label {
    left: 16px;
  }

  .tab-section {
    margin-left: 35px;
  }

  .tab_offer {
    position: absolute;
    right: 0;
    top: -625px;
    min-height: 554px;
  }

  .steps li:after {
    top: 11px;
  }

  .tranding_slide .slick-next,
  .tranding_slide .slick-prev {
    margin: 0 -49px;
  }

  .slick-next {
    right: 32px;
  }

  .offerslider .slick-prev {
    margin: 0 -51px;
  }

  .offerslider .slick-next {
    margin: 0 -23px;
  }

  .coachslider .slick-next,
  .coachslider .slick-prev {
    margin: 0 -60px;
  }

  .coachslider .slick-next {
    margin-right: -63px !important;
  }

  .h-servives .slick-prev {
    margin: 0 -46px !important;
  }

  .h-servives .slick-next {
    margin: 0px -46px !important;
  }

  .studyslider .slick-next,
  .studyslider .slick-prev {
    margin: 0 -56px;
  }

  /* .fieldbtn .disabledClass{
        width:19%;
        } */
  .fields-wrp form .fieldbtn input:nth-child(2) {
    margin-left: 8px;
  }

  .g-0 {
    margin-left: 0px;
  }

  img.uniLogody {
    width: 142px;
    margin-left: 0px;
    margin-top: -14px;
  }

  .about-uni-text p {
    margin-left: 52px;
  }

  .couch-item {
    padding: 10px 16px;
  }

  .couch-item .feat-text {
    padding: 5px 11px;
  }

  .feat-inn span {
    padding: 0px;
  }

  .feat-inn > ul {
    margin-right: 0px;
  }

  .topbar-section:after {
    border-top: none !important;
    top: 44px;
  }

  .modal-login {
    position: absolute;
    top: 17%;
  }

  .modal-tab-offer {
    position: absolute;
    top: 190px;
  }

  .search-sec {
    width: 180px !important;
  }

  .tesi-wrap .inner-heading {
    margin-top: 33%;
  }

  .universitein li a {
    overflow: auto;
  }

  .uni-full-name {
    display: block !important;
  }

  .uni-sort-name {
    display: none !important;
  }

  .offer_model {
    margin-top: 150px !important;
  }

  .mobile_flex {
    text-align: end;
    width: 727px;
  }

  .exam-box {
    /* min-height: 529px; */
  }

  .universitein li .imglogo {
    width: 57px;
    text-align: center;
  }

  .unitextwidth1 {
    width: unset;
  }

  .unitextwidth2 {
    width: unset;
  }

  .about-uni-text {
    padding: 30px 30px 30px 30px;
  }
}
.exam-box {
  /* min-height: 529px; */
}

.universitein li .imglogo {
  width: 57px;
  text-align: center;
}

.unitextwidth1 {
  width: unset;
}

.unitextwidth2 {
  width: unset;
}

.about-uni-text {
  padding: 30px 30px 30px 30px;
}
/* end-tab-air */

.uni-full-name {
  display: none;
}

.uni-sort-name {
  display: block;
}

/** iPad Pro Css **/
/* Landscape*/

@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .topbar-section:after {
    top: 0;
  }

  .header {
    margin-top: 5px;
  }

  .sliderbox {
    margin-top: 120px;
  }

  .testi_slider .slick-prev,
  .testi_slider .slick-next {
    top: 82%;
  }

  .tesi-wrap .inner-heading {
    padding-left: 93px;
    padding-top: 197px;
  }
}

/* Portrait*/
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .topbar-section:after {
    top: 0;
  }

  .header {
    margin-top: 65px;
    padding: 12px 0;
  }

  .pull-left {
    margin-top: 9px;
  }

  .search-sec {
    width: 215px !important;
    margin-top: 20px;
  }

  .top-bar-right {
    padding-right: 0%;
    margin-top: 8px;
  }

  .sliderbox {
    margin-top: 120px;
  }

  .tesi-wrap .inner-heading {
    margin-top: 43%;
  }

  .testi_slider .slick-prev,
  .testi_slider .slick-next {
    top: 82%;
  }

  .intelliSearch .frm_dvhome {
    padding: 12px 12px 12px 12px;
    box-shadow: 0 0 12px #ccc;
  }

  .iPad-mt {
    margin-top: 30px;
  }

  .intelliSearch::before {
    width: 352px;
  }

  .t-banner {
    margin-top: 120px;
  }

  .page-sidebar .widget h5 {
    padding: 8px 6px;
    font-size: 12px;
  }

  .singleslider .slick-next,
  .singleslider .slick-prev {
    margin: 0 -52px;
  }

  .bggreadent,
  .studywrp .bgstudy {
    min-height: 64px !important;
  }

  img.uniLogody {
    margin-left: 0px;
    margin-top: 4px;
  }

  .acception-uni .accept1.accept2 {
    padding: 0px;
  }

  .acception-uni .accept1 .secaccept {
    padding: 15px 6px;
  }

  .singleslider .slick-next,
  .singleslider .slick-prev {
    margin: 0 -22px;
  }

  .uni-item-list .item-btn-more:before {
    left: -59px;
  }

  .counsellorlist .uni-item-list .item-body {
    padding-left: 0px;
  }

  .add-detail {
    min-height: 213px;
  }

  .exclusives {
    height: 353px !important;
  }

  .exc-tab-box {
    height: 415px !important;
  }

  .coldir {
    flex-direction: unset;
  }

  .page-sidebar .star-ratings svg {
    margin-right: 1px;
  }

  .uni-item-list .item-btn-more a {
    display: inline-block !important;
  }

  .uni-item-list .item-btn-more {
    width: 100% !important;
    justify-content: center;
  }

  .uni-item-list {
    flex-wrap: wrap;
  }

  .counsellorlist .uni-item-list .item-body {
    width: 67%;
  }

  .login_sec {
    margin-top: 119px;
  }

  .first-con1 {
    font-size: 12px;
    padding: 10px 0px;
  }

  .detailstudy table tr td img {
    padding: 6px 0;
    position: absolute;
    left: 13px;
  }

  .detailstudy table tr td {
    font-size: 11px;
    text-align: left;
    display: flow-root;
    padding: 3px;
    margin-left: 32px;
  }

  .tab-section {
    margin-left: 35px;
  }

  .apply-sub-btn {
    padding: 13px 27px;
  }

  .why-choose-ad .content-box p {
    padding-right: 0 !important;
  }

  .why-choose-ad .content-box {
    min-height: 40%;
  }

  .why-choose-ad .content-box h5 {
    font-size: 1.1rem !important;
  }

  .why-choose-ad,
  .why-item {
    min-height: 416px;
  }

  .testi_slider .slick-prev {
    left: -39%;
  }

  .wishlisttop {
    top: 32px;
  }

  .react-responsive-modal-modal {
    top: -13%;
  }

  .counsellorlist.counsellor_details .uni-item-list .item-body {
    width: 100%;
  }

  .counsellorlist.counsellor_details .uni-item-list .item-media {
    width: 285px;
    margin: 10px auto;
  }

  .counsellor_details .uni-item-list .item-btn-more .btnbox {
    display: inherit;
  }

  .counsellorlist .uni-item-list .item-btn-more a {
    width: 150px;
    height: 32px;
    margin: 10px 10px 0 0;
    line-height: 31px;
    padding: 0;
  }

  .counsellorlist .item-abouttxt {
    width: 100%;
  }

  .exam-box {
    /* min-height: 308px; */
  }

  .why-choose-ad,
  .why-item {
    min-height: 397px;
  }

  img.uniLogody {
    width: 105px;
  }
}

@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .header {
    margin-top: 51px;
  }

  .intelliSearch .frm_dvhome {
    top: 55px;
  }

  .intelliSearch::before {
    top: 42px;
  }

  .testi_slider .slick-prev,
  .testi_slider .slick-next {
    top: 89%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-height: 1023px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .container {
    max-width: 1120px;
  }

  .header {
    margin-top: 51px;
  }

  .search-sec {
    width: 235px !important;
  }

  .couch-item:before {
    width: 215px;
    height: 215px;
    right: -11px;
    top: -11px;
  }

  .coachslider .slick-slide:nth-child(even) .couch-item:before {
    right: -11px;
    bottom: -11px;
  }

  .appbg .sub-section {
    max-width: 100%;
    box-shadow: 0px 0px 13px #b8e2fe;
    margin: 0px;
  }

  .appbg {
    background: none;
  }

  .service-category {
    height: 335px !important;
  }

  .tesi-wrap .inner-heading {
    padding-left: 84px;
    padding-top: 235px;
  }

  .testi_slider .slick-prev,
  .testi_slider .slick-next {
    margin: 17px -40px;
  }

  .testi_slider .slick-prev {
    left: -40%;
  }

  .why-choose-ad,
  .why-item {
    min-height: 400px;
  }

  .studyslider .slick-prev,
  .studyslider .slick-next {
    margin: 0 -54px;
  }

  .uni-item-list .item-btn-more:before {
    left: -59px;
  }

  .main-logo img {
    width: 231px;
  }

  .uni-item-list .item-btn-more {
    width: 100% !important;
    justify-content: center;
  }

  .uni-item-list {
    flex-wrap: wrap;
  }

  .uni-item-list .item-btn-more a {
    display: inline-block !important;
  }

  .page-sidebar .widget h5 {
    padding: 8px 6px;
    font-size: 13px;
  }

  .singleslider .slick-next,
  .singleslider .slick-prev {
    margin: 0 -56px;
  }

  .detailstudy table tr td {
    font-size: 11px;
    text-align: left;
    display: flow-root;
    padding: 3px;
    margin-left: 32px;
  }

  .detailstudy table tr td img {
    padding: 6px 0;
    position: absolute;
    left: 13px;
  }

  .counsellor_details .uni-item-list .item-btn-more a {
    margin: 10px 10px 0px;
  }

  .counsellorlist.counsellor_details .uni-item-list .item-media {
    width: 285px;
    margin: 10px auto;
  }

  .counsellor_details .uni-item-list .item-abouttxt > table {
    width: calc(105% - 200px);
    margin-top: 16px;
  }

  .counsellorlist.counsellor_details .uni-item-list .item-body {
    width: 100%;
  }

  .scale-m-w {
    max-width: 222px !important;
  }

  .short-list-uni img {
    width: 62% !important;
    margin: 10px !important;
  }

  .link_dis_cat {
    padding: unset !important;
    font-size: unset !important;
    border: unset !important;
    display: unset !important;
    border-radius: unset !important;
    min-width: unset !important;
    text-align: unset !important;
    margin-right: unset !important;
  }

  .link_dis_cat:hover {
    border: unset !important;
    background-color: unset !important;
  }

  .cursorPointer {
    cursor: default !important;
  }

  .turncate1 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .country-details li {
    white-space: unset !important;
  }

  .country-details li p {
    white-space: pre !important;
  }

  img.scale_new_comment_img {
    width: 50px !important;
    height: 50px !important;
  }

  .mobile_css .launcher {
    display: none;
  }

  .feat-img .th-name img {
    height: 30px !important;
  }
}

#mainCont button {
  right: 140px !important;
  bottom: 5px !important;
}

.righttop .university-search-input ::placeholder {
  color: white !important;
}
.welcome-modal {
  display: unset !important;
}
.cust-pop-close {
  position: absolute;
  right: 15px;
  top: 15px;
}
.model-btn-pop {
  background-color: #fcbd31;
  padding: 0 8px;
  color: #000;
  border-radius: 33px;
}
.welcome-modal {
  background-color: #00000094 !important;
  z-index: 9999;
}
.for-modal-top {
  top: 10%;
}
.counsellorBox1 {
  display: flex;
  width: 100%;
  gap: 10px !important;
}
.counsellor-section {
  width: 80%;
  /* border: 1px solid red; */
}
.banner-section {
  /* flex: 1; */
  width: 20%;
  /* border: 1px solid red; */
}
